import { gql, useMutation, useQuery } from '@apollo/client';
import { Partner, PartnerSearch } from '__generated__/graphql';

export const GET_PARTNERS = gql`
  query GetPartners {
    GetPartners {
      id
      firstName
      surname
      businessName
      documents {
        id
        ownerId
        ownerType
        url
        title
        type
        dateCreated
      }
      dbsChecked
      description
      qualifications
      qualificationsChecked
      referencesReceived
      vetted
      isActive
      isTest
      hubspotId
      image {
        id
        url
      }
      email
      dbsExpiryDate
      deactivationReason
      yearsQualified
      prices {
        basePrice
        duration
        bookingPrice
        id
        markup
      }
    }
  }
`;

export const GET_PARTNER = gql`
  query GetPartner($args: PartnerSearch) {
    GetPartner(args: $args) {
      businessName
      dbsChecked
      description
      id
      firstName
      surname
      qualifications
      qualificationsChecked
      referencesReceived
      vetted
      isActive
      email
      phoneNumber
      documents {
        id
        ownerId
        ownerType
        url
        title
        type
        dateCreated
      }
      image {
        id
        url
      }
      prices {
        id
        markup
        partnerId
        productId
        bookingPrice
        basePrice
        duration
      }
      isTest
      dbsExpiryDate
      deactivationReason
      yearsQualified
      address {
        addressLine1
        addressLine2
        city
        region
        postcode
        lat
        long
      }
      workingArea {
        lat
        lng
      }
    }
  }
`;

export const ACTIVATE_PARTNER = gql`
  mutation ActivatePartner($input: PartnerSearch) {
    ActivatePartner(input: $input) {
      date
      message
    }
  }
`;

export const CREATE_PARTNER = gql`
  mutation CreatePartner($input: CreatePartnerInput) {
    CreatePartner(input: $input) {
      date
      message
    }
  }
`;

export const EDIT_PARTNER = gql`
  mutation EditPartner($input: EditPartnerInput) {
    EditPartner(input: $input) {
      date
      message
    }
  }
`;

export const DEACTIVATE_PARTNER = gql`
  mutation DisablePartner($input: PartnerDisableInput) {
    DisablePartner(input: $input) {
      date
      message
    }
  }
`;

export const DELETE_PARTNER = gql`
  mutation DeletePartner($input: PartnerSearch) {
    DeletePartner(input: $input) {
      message
      date
    }
  }
`;

export const GET_PARTNERS_BY_PROXIMITY = gql`
  query GetPartnersByProximity($args: PartnersByProximitySearch) {
    GetPartnersByProximity(args: $args)
  }
`;

interface PartnersResponse {
  GetPartners: Partner[];
}

interface PartnerResponse {
  GetPartner: Partner;
}

export const refetchPartner = (input: PartnerSearch) => ({
  refetchQueries: [
    {
      query: GET_PARTNER,
      variables: {
        args: {
          ...input,
        },
      },
    },
  ],
});

const useCreatePartner = () => {
  const [createPartner, { loading: createPartnerLoading, error: createPartnerError }] = useMutation(CREATE_PARTNER, {
    refetchQueries: [
      {
        query: GET_PARTNERS,
      },
    ],
  });

  return {
    createPartner,
    createPartnerLoading,
    createPartnerError,
  };
};

const useEditPartner = (id?: string) => {
  const [editPartner, mutationEditPartner] = useMutation(EDIT_PARTNER, refetchPartner({ id: id || '' }));

  return {
    editPartner,
    mutationEditPartner,
  };
};

const useEditPartnerProfile = (id?: string) => {
  const [editPartnerProfile, mutationEditPartnerProfile] = useMutation(EDIT_PARTNER, refetchPartner({ id: id || '' }));

  return {
    editPartnerProfile,
    mutationEditPartnerProfile,
  };
};

const useActivatePartner = (id?: string) => {
  const [activatePartner, { loading: activatePartnerLoading, error: activatePartnerError }] = useMutation(ACTIVATE_PARTNER, refetchPartner({ id: id || '' }));

  return {
    activatePartner,
    activatePartnerLoading,
    activatePartnerError,
  };
};

const useDeactivatePartner = (id?: string) => {
  const [deactivatePartner, { loading: deactivatePartnerLoading, error: deactivatePartnerError }] = useMutation(
    DEACTIVATE_PARTNER,
    refetchPartner({ id: id || '' }),
  );

  return {
    deactivatePartner,
    deactivatePartnerLoading,
    deactivatePartnerError,
  };
};

const useDeletePartner = () => {
  const [deletePartner, { loading: deletePartnerLoading, error: deletePartnerError }] = useMutation(DELETE_PARTNER, {
    refetchQueries: [
      {
        query: GET_PARTNERS,
      },
    ],
  });

  return {
    deletePartner,
    deletePartnerLoading,
    deletePartnerError,
  };
};

const useGetPartner = (id?: string, productId?: string) => {
  const {
    loading: partnerLoading,
    data,
    error: partnerError,
    refetch,
  } = useQuery<PartnerResponse>(GET_PARTNER, {
    fetchPolicy: 'network-only',
    skip: !id,
    variables: {
      args: {
        id,
        productId,
      },
    },
  });

  return {
    partner: data?.GetPartner || undefined,
    refetch,
    partnerLoading,
    partnerError,
  };
};
const useGetPartners = () => {
  const { loading: partnersLoading, data, error: partnersError, refetch } = useQuery<PartnersResponse>(GET_PARTNERS);

  return {
    partners: data?.GetPartners || [],
    refetch,
    partnersLoading,
    partnersError,
  };
};

const useGetPartnersByProximity = (productId?: string, postcode?: string) => {
  const {
    loading: partnerLoading,
    data: { GetPartnersByProximity: partnerIds = [] } = {},
    error: partnerError,
    refetch,
  } = useQuery(GET_PARTNERS_BY_PROXIMITY, {
    fetchPolicy: 'network-only',
    skip: !productId || !postcode,

    variables: {
      args: {
        productId,
        postcode,
      },
    },
  });

  return {
    partnerIds,
    refetch,
    partnerLoading,
    partnerError,
  };
};

export {
  useCreatePartner,
  useGetPartnersByProximity,
  useGetPartner,
  useGetPartners,
  useDeletePartner,
  useDeactivatePartner,
  useActivatePartner,
  useEditPartner,
  useEditPartnerProfile,
};
