import { useNavigate } from 'react-router-dom';

export interface DrawerItemProps {
  text: string;
  link: string;
  icon: JSX.Element;
  disabled?: boolean;
  testId?: string;
}

function DrawerItem({ link, text, icon, disabled = false, testId = '' }: DrawerItemProps) {
  const navigate = useNavigate();

  return (
    <button
      type="button"
      data-cy={`link-${testId}`}
      disabled={disabled}
      onClick={() => {
        navigate(link);
      }}
      data-testid={testId}
      className="flex items-start gap-6 px-4 py-1 w-full hover:bg-primary-50"
    >
      <div className="w-5 h-5">{icon}</div>
      <div className="text-md text-gray-800">{text}</div>
    </button>
  );
}

export { DrawerItem };
