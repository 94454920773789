function Unauthorised() {
  return (
    <div className="section offwhitebg">
      <main className="content">
        <p>Sorry you are not authorised to view this page </p>
        <p>some change more additional text</p>
      </main>
    </div>
  );
}

export default Unauthorised;
