import { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { Modal, TabSwitcher } from 'components';
import { Event } from '__generated__/graphql';
import { SelectOption } from '../../../../types';
import { AddExistingAttendeeForm } from './AddExistingAttendeeForm';
import { AddNewAttendeeForm } from './AddNewAttendeeForm';

interface AddAttendeesProps {
  onClose: () => void;
  open: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventSubmit: any;
}

export default function AddAttendees({ onClose, open, eventSubmit }: AddAttendeesProps) {
  const [attendeeType, setAttendeeType] = useState<SelectOption | undefined>({ label: 'Customer', value: 'CUSTOMER' });

  const { control, handleSubmit } = useFormContext<Event>();
  const { fields, append, remove } = useFieldArray<Event>({
    name: 'bookings', // unique name for your Field Array
    control,
  });

  const onClickAttendee = (id: string) => {
    let index;
    if (attendeeType?.value === 'CUSTOMER') {
      index = fields?.findIndex((field) => field.customerId === id);
    } else {
      index = fields?.findIndex((field) => field.attendeeId === id);
    }

    if (index !== -1) {
      remove(index);
    } else {
      if (attendeeType?.value === 'CUSTOMER') append({ id: uuidv4(), customerId: id, paid: false, attended: false });
      if (attendeeType?.value === 'ATTENDEE') append({ id: uuidv4(), attendeeId: id, paid: false, attended: false });
    }
  };
  const addAttendee = (id: string) => {
    append({ id: uuidv4(), attendeeId: id, paid: false, attended: false });
    handleSubmit(eventSubmit)();
  };

  const attendeeIds = fields?.map((field) => field.attendeeId || field.customerId || '');

  const tabs = [
    {
      label: 'Add exisitng attendees',
      component: (
        <AddExistingAttendeeForm
          onClickAttendee={onClickAttendee}
          attendeeIds={attendeeIds}
          attendeeType={attendeeType}
          setAttendeeType={setAttendeeType}
          onClose={onClose}
          eventSubmit={eventSubmit}
        />
      ),
    },
    {
      label: 'Add new event attendee',
      component: <AddNewAttendeeForm addAttendee={addAttendee} onClose={onClose} />,
    },
  ];
  return (
    <Modal onClose={onClose} isDisabled={false} open={open}>
      <TabSwitcher tabs={tabs} />
    </Modal>
  );
}
