import { useState } from 'react';
import { useEnableCustomer, useDisableCustomer } from 'api/hooks/useCustomer';
import { useToastNofication } from 'context/toastContext';
import { ActivationModal } from 'components/modals/ActivationModal';

interface CustomerActivateButtonProp {
  id: string;
  isActive: boolean | null | undefined;
}

function CustomerActivateButton({ id, isActive }: CustomerActivateButtonProp) {
  const [customerEnabled, setCustomerEnabled] = useState(isActive || false);
  const { enableCustomer, enableCustomerLoading } = useEnableCustomer(id);
  const { disableCustomer, disableCustomerLoading } = useDisableCustomer(id);
  const { addToast } = useToastNofication();
  const [modalOpen, setModalOpen] = useState(false);

  const toggleCustomerEnable = () => {
    setModalOpen(true);
  };

  const onClose = () => {
    setModalOpen(false);
  };
  const onConfirm = async () => {
    onClose();
    if (!id) return;
    if (customerEnabled) {
      const response = await disableCustomer({
        variables: {
          input: { id },
        },
      });
      addToast(response, 'DisableCustomer');
    } else {
      const response = await enableCustomer({
        variables: {
          input: { id },
        },
      });
      addToast(response, 'EnableCustomer');
    }
    setCustomerEnabled(!customerEnabled);
  };
  const bgColor = customerEnabled ? 'bg-error-500' : 'bg-success-500';

  return (
    <>
      <div className="flex flex-col md:flex-row items-start gap-3 md:gap-7">
        <button
          data-testid="toggle-customer-enable-button"
          onClick={toggleCustomerEnable}
          type="submit"
          className={`text-white  ${bgColor} rounded-lg px-5 py-2.5 font-semibold text-md leading-md`}
        >
          {customerEnabled ? 'Disable customer' : 'Enable customer'}
        </button>
      </div>
      <ActivationModal
        label="customer"
        isDisabled={enableCustomerLoading || disableCustomerLoading}
        onClose={onClose}
        isActive={customerEnabled}
        onConfirm={onConfirm}
        open={modalOpen}
        alert="Disabling a customer will disable their account, and login"
      />
    </>
  );
}

export { CustomerActivateButton };
