import { useForm, FormProvider } from 'react-hook-form';
import { Typography } from '@mui/material';
import { ApolloError } from '@apollo/client';
import { Location, CreateLocationInput, EditLocationInput } from '__generated__/graphql';
import { InputField } from 'components';

interface LocationFormProps {
  onSubmit: (data: EditLocationInput | CreateLocationInput) => void;
  location?: Location;
  formSubmitting: boolean;
  formSubmitError: ApolloError | undefined;
}

function LocationForm({ location, onSubmit, formSubmitting, formSubmitError }: LocationFormProps) {
  const methods = useForm<EditLocationInput | CreateLocationInput>({
    mode: 'onChange',
    defaultValues: {
      ...location,
    },
  });
  const { handleSubmit } = methods;

  const locationFormSubmit = (data: EditLocationInput | CreateLocationInput) => {
    onSubmit({ ...data });
  };

  return (
    <div className="mt-8 sm:mt-12">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(locationFormSubmit)}>
          <InputField name="name" isRequired label="Name" placeholder="Name" />
          {formSubmitError && (
            <Typography variant="h5" color="red">
              {formSubmitError.message}
            </Typography>
          )}

          <button type="submit" disabled={formSubmitting} className="text-white bg-primary-500 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
            Save
          </button>
        </form>
      </FormProvider>
    </div>
  );
}

export { LocationForm };
