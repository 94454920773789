import MenuIcon from '@mui/icons-material/Menu';
import { HeaderTitle } from './HeaderTile';

function HeaderToolBar({ setDrawerOpen }: { setDrawerOpen: (toggle: boolean) => void }) {
  return (
    <div className="shadow-header py-4 px-6 bg-primary-600">
      <div className="flex  items-center">
        <div>
          <button
            type="button"
            aria-label="open menu"
            data-cy="drawer"
            className="shadow-button rounded-full p-2 text-white hover:bg-white hover:text-primary-500"
            onClick={() => setDrawerOpen(true)}
          >
            <MenuIcon className="w-5 h-5" />
          </button>
        </div>
        <div className="pl-10">
          <HeaderTitle />
        </div>
      </div>
    </div>
  );
}

export { HeaderToolBar };
