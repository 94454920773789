import { gql, useMutation, useQuery } from '@apollo/client';
import { Product } from '__generated__/graphql';

export const CREATE_PRODUCT = gql`
  mutation CreateProduct($input: CreateProductInput) {
    CreateProduct(input: $input) {
      date
      message
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($input: ProductSearch) {
    DeleteProduct(input: $input) {
      message
      date
    }
  }
`;

export const EDIT_PRODUCT = gql`
  mutation EditProduct($input: EditProductInput) {
    EditProduct(input: $input) {
      date
      message
    }
  }
`;

export const GET_PRODUCT = gql`
  query GetProduct($args: ProductSearch) {
    GetProduct(args: $args) {
      id
      categoryId
      name
      description
    }
  }
`;

export const GET_PRODUCTS = gql`
  query GetProducts($args: ProductListSearch) {
    GetProducts(args: $args) {
      id
      categoryId
      name
      description
      categoryName
    }
  }
`;

interface ProductsResponse {
  GetProducts: Product[];
}

interface ProductResponse {
  GetProduct: Product;
}

const useCreateProduct = (categoryId: string) => {
  const [createProduct, { loading: createProductLoading, error: createProductError }] = useMutation(CREATE_PRODUCT, {
    refetchQueries: [
      {
        query: GET_PRODUCTS,
        variables: {
          args: { categoryId },
        },
      },
    ],
  });

  return {
    createProduct,
    createProductLoading,
    createProductError,
  };
};

const useDeleteProduct = () => {
  const [deleteProduct, { loading: deleteProductLoading, error: deleteProductError }] = useMutation(DELETE_PRODUCT, {
    refetchQueries: [
      {
        query: GET_PRODUCTS,
      },
    ],
  });

  return {
    deleteProduct,
    deleteProductLoading,
    deleteProductError,
  };
};

const useEditProduct = (categoryId: string) => {
  const [editProduct, { loading: editProductLoading, error: editProductError }] = useMutation(EDIT_PRODUCT, {
    refetchQueries: [
      {
        query: GET_PRODUCTS,
        variables: {
          args: { categoryId },
        },
      },
    ],
  });

  return {
    editProduct,
    editProductLoading,
    editProductError,
  };
};

const useGetProduct = (id?: string) => {
  const {
    loading: productLoading,
    data,
    error: productError,
    refetch,
  } = useQuery<ProductResponse>(GET_PRODUCT, {
    variables: {
      args: {
        id,
      },
    },
  });

  return {
    product: data?.GetProduct || undefined,
    refetch,
    productLoading,
    productError,
  };
};

const useGetProducts = (categoryId?: string) => {
  const {
    loading: productsLoading,
    data,
    error: productsError,
    refetch,
  } = useQuery<ProductsResponse>(GET_PRODUCTS, {
    variables: {
      args: {
        categoryId,
      },
    },
  });

  return {
    products: data?.GetProducts || [],
    refetch,
    productsLoading,
    productsError,
  };
};

export { useDeleteProduct, useGetProduct, useGetProducts, useCreateProduct, useEditProduct };
