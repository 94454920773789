import { Controller, useFormContext } from 'react-hook-form';
import { SelectOption } from '../../types';
import { FormLabel } from '../FormLabel';
import { ReactSelect } from '../Select';

interface SelectFieldProps {
  label: string;
  name: string;
  options: SelectOption[];
  disabled?: boolean;
  loading?: boolean;
  searchable?: boolean;
  testId?: string;
  isRequired?: boolean;
}

function SelectField({ label, name, options, disabled = false, loading = false, searchable, testId, isRequired }: SelectFieldProps) {
  const { control } = useFormContext(); // retrieve all hook methods

  return (
    <FormLabel label={label}>
      <div className="w-full lg:w-1/2">
        <Controller
          name={name}
          control={control}
          rules={{
            required: isRequired ? 'This is required' : false,
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            const selectedValue = options.find((option) => option.value === value);
            return (
              <>
                <ReactSelect
                  testId={testId}
                  name={name}
                  isLoading={loading}
                  disabled={disabled}
                  options={options}
                  onChange={(e) => {
                    onChange(e.value);
                  }}
                  selectedValue={selectedValue}
                  searchable={searchable}
                />
                <p className="mt-2 text-sm text-error-600 dark:text-green-500">
                  <span className="font-medium">{error?.message}</span>
                </p>
              </>
            );
          }}
        />
      </div>
    </FormLabel>
  );
}

export { SelectField };
