import * as React from 'react';
import RateReviewIcon from '@mui/icons-material/RateReview';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import CategoryIcon from '@mui/icons-material/Category';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import GroupIcon from '@mui/icons-material/Groups';
import HandshakeIcon from '@mui/icons-material/Handshake';
import EventIcon from '@mui/icons-material/Event';
import BadgeIcon from '@mui/icons-material/Badge';
import TableViewIcon from '@mui/icons-material/TableView';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Logout, GridView, GroupsOutlined, Api, Code } from '@mui/icons-material';
import { useIsPartner, useIsUserAdmin } from '../../hooks/authHooks';
import { DrawerItem } from './DrawerItem';
import { DrawerSection, DrawerSectionItemProps } from './DrawerSection';

interface DrawerProps {
  toggleDrawer: (state: boolean) => void;
  drawerOpen: boolean;
  handleLogout: () => void;
}

export const partnerSection: DrawerSectionItemProps[] = [
  {
    text: 'My Bookings',
    link: '/my-bookings',
    icon: <GroupsOutlined className="text-primary-600" />,
    testId: 'my-bookings',
    isHidden: false,
  },
  {
    text: 'My Prices',
    link: '/my-prices',
    icon: <CurrencyPoundIcon className="text-primary-600" />,
    testId: 'my-prices',
    isHidden: false,
  },
];

export const adminSection: DrawerSectionItemProps[] = [
  {
    text: 'Partners',
    link: '/partners',
    icon: <HandshakeIcon className="text-warning-700" />,
    testId: 'partners',
    isHidden: false,
  },
  {
    text: 'Bookings',
    link: '/bookings',
    icon: <EventIcon className="text-warning-700" />,
    testId: 'bookings',
    isHidden: false,
  },
  {
    text: 'Reviews',
    link: '/reviews',
    icon: <RateReviewIcon className="text-warning-700" />,
    testId: 'reviews',
    isHidden: false,
  },
  {
    text: 'Categories',
    link: '/categories',
    icon: <CategoryIcon className="text-warning-700" />,
    testId: 'categories',
    isHidden: false,
  },
  {
    text: 'Products',
    link: '/products',
    icon: <ShoppingCartIcon className="text-warning-700" />,
    testId: 'products',
    isHidden: false,
  },
  {
    text: 'Prices',
    link: '/prices',
    icon: <CurrencyPoundIcon className="text-warning-700" />,
    testId: 'prices',
    isHidden: false,
  },
  {
    text: 'Customers',
    link: '/customers',
    icon: <GroupIcon className="text-warning-700" />,
    testId: 'customers',
    isHidden: false,
  },
  {
    text: 'Users',
    link: '/users',
    icon: <BadgeIcon className="text-warning-700" />,
    testId: 'customers',
    isHidden: false,
  },
  {
    text: 'Events',
    link: '/events',
    icon: <TableViewIcon className="text-warning-700" />,
    testId: 'events',
    isHidden: false,
  },
  {
    text: 'Event Attendees',
    link: 'event-attendees',
    icon: <LocationOnIcon className="text-warning-700" />,
    testId: 'event-attendees',
    isHidden: false,
  },
  {
    text: 'Locations',
    link: '/locations',
    icon: <LocationOnIcon className="text-warning-700" />,
    testId: 'locations',
    isHidden: false,
  },
  {
    text: 'GraphQl Inspector',
    link: '/developer/graphql-inspector',
    icon: <Code className="text-warning-700" />,
    isHidden: false,
  },
  {
    text: 'GraphQl Visualiser',
    link: '/developer/graphql-visualiser',
    icon: <Api className="text-warning-700" />,
    isHidden: false,
  },
];
function Drawer({ toggleDrawer, drawerOpen, handleLogout }: DrawerProps) {
  const { isAuthorised: isAdmin } = useIsUserAdmin();
  const { isAuthorised: isPartner } = useIsPartner();

  return (
    <>
      <div
        className={`overflow-x-hidden fixed top-0 left-0 z-[1100] outline-none focus:outline-none ${
          drawerOpen ? 'w-[280px]' : 'w-0'
        } shadow-drawer delay-0 duration-300 ease-[cubic-bezier(0,0,0.2,1)] transition-all transform`}
      >
        <div className="relative flex flex-col justify-between w-full bg-white h-screen overflow-y-auto" onClick={() => toggleDrawer(false)}>
          {/* Navbar except Sign Out: */}
          <div>
            {/* Logo & Dashboard */}
            <div>
              <div className="px-4 py-3.5 flex justify-between items-center">
                <img width="120" src="/thrive-logo.png" alt="Bellevie Care" data-cy="logo" />

                {isAdmin && <div className="text-warning-700 text-lg leading-none font-semibold"> Admin</div>}
              </div>
              <div className="w-full h-px bg-gray-100 " />
              <DrawerItem
                testId={isAdmin ? 'dashboard' : 'my-profile'}
                link="/"
                text={isAdmin ? 'Dashboard' : 'My Profile'}
                icon={<GridView className="text-primary-600" />}
              />
            </div>
            {/* Partner section: */}
            {isPartner && <DrawerSection sectionItems={partnerSection} />}
            {/* Admin section: */}

            {isAdmin && <DrawerSection sectionItems={adminSection} />}
          </div>
          {/* Sign Out: */}
          <div>
            <div className="w-full h-px bg-gray-100 mt-1.5" />
            <button data-cy="link-sign-out" type="button" onClick={handleLogout} className="flex items-start gap-4 px-4 py-2 w-full hover:bg-primary-50">
              <div className="w-5 h-5">
                <Logout className="text-primary-600" />
              </div>
              <div className="text-md text-gray-800">Sign Out</div>
            </button>
          </div>
        </div>
      </div>
      <div className={`opacity-30 fixed inset-0 z-40 bg-gray-900 ${!drawerOpen && 'hidden'}`} onClick={() => toggleDrawer(false)} />
    </>
  );
}

export { Drawer };
