import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, useNavigate } from 'react-router-dom';
import ArticleIcon from '@mui/icons-material/Article';
import { ArrowLeftIcon, ClockIcon, PlusIcon } from '@heroicons/react/24/outline';
import { format, isAfter } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { InputAdornment } from '@mui/material';
import { useEditEvent, useGetEvent } from 'api/hooks/useEvent';
import { Event } from '__generated__/graphql';
import { LocationBar, Loading, InputField } from 'components';
import { LocationSelectField } from 'components/fields/LocationSelectField';
import { useToastNofication } from 'context/toastContext';
import { useUser } from 'context/userContext';
import { EventStatus } from './types';
import EventBookingList from './EventBookingList';
import Status from './components/Status';
import AddAttendees from './components/AddEventBookingModal';

export default function EventDetail() {
  const navigate = useNavigate();
  const { eventId, id } = useParams<{ eventId?: string; id?: string }>();
  const { event, eventLoading } = useGetEvent(eventId);
  const { editEvent } = useEditEvent();
  const { partner } = useUser();
  const [openEditAttendeesModal, setOpenEditAttendeesModal] = useState<boolean>(false);
  const { addToast } = useToastNofication();

  const methods = useForm<Event>({
    mode: 'onChange',
    defaultValues: {
      ...event,
      bookings: event?.bookings || [],
    },
  });

  const { handleSubmit, reset, watch } = methods;

  const eventSubmit = async (input: Event) => {
    const response = await editEvent({
      variables: {
        input,
      },
    });
    addToast(response, 'EditEvent');
  };

  useEffect(() => {
    reset(
      {
        ...event,
        bookings: event?.bookings || [],
      },
      { keepDirty: true },
    );
  }, [reset, event]);

  const onClose = () => {
    if (partner?.id) {
      navigate('/');
    } else {
      navigate(id ? `/partners/${id}/update` : '/events');
    }
  };

  const onEditAttendeeClose = () => setOpenEditAttendeesModal(false);

  if (eventLoading) {
    return <Loading />;
  }

  const priceAdorment = <InputAdornment position="start">£</InputAdornment>;
  const date = watch('date') ? watch('date') : new Date();
  const duration = watch('duration');

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(eventSubmit)}>
        <Helmet>
          <title>Event Detail</title>
        </Helmet>
        <LocationBar section="Events" page="Event Detail" Icon={ArticleIcon} />
        <div className="my-10 px-4 md:px-[5%]">
          <button type="button" className="text-gray-500 font-semibold text-md leading-md flex items-center" data-cy="event-detail-back" onClick={onClose}>
            <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
          </button>
          <div className="flex justify-start	gap-4 flex-col mt-14">
            <div className="flex items-center gap-6">
              <div className="bg-[#DBEAF0] rounded-xl p-1.5">
                <div className="bg-gray-500 rounded-md text-white px-7 py-2">
                  <div className="text-xl leading-xl font-medium text-center">{format(date, 'ccc')}</div>
                  <div className="text-xl leading-xl font-medium text-center">{format(date, 'do')}</div>
                  <div className="text-xl leading-xl font-medium text-center mt-1">{format(date, 'MMM')}</div>
                </div>
              </div>
              <div className="">
                <Status status={isAfter(new Date(), date) ? EventStatus.Complete : EventStatus.Scheduled} />
                <div className="text-black text-display-sm leading-display-sm font-semibold mt-3">{event?.name}</div>
                <div className="flex flex-col gap-3 md:flex-row">
                  <div className="text-gray-700 text-md leading-md mt-1 flex items-center">
                    <ClockIcon className="w-6 h-6 text-gray-400 mr-1" />
                    {format(date, 'kk:mm a')}
                  </div>
                  {duration && <div className="text-gray-700 text-md leading-md mt-1 flex items-center">{duration} mins</div>}
                </div>
              </div>
            </div>
            <div className="flex items-center gap-6 mb-4  mt-4">
              <form onSubmit={handleSubmit(eventSubmit)}>
                <div className="flex flex-col ">
                  <LocationSelectField label="Location" name="locationId" />
                  <InputField label="Name" placeholder="Name" name="name" />
                  <InputField label="Price" startAdornment={priceAdorment} type="number" min="0" placeholder="Price" name="price" />
                </div>
              </form>
            </div>
            <div className="flex items-center gap-3 md:gap-5">
              <button
                type="button"
                data-cy="event-add-attendee-button"
                className="text-white bg-primary-500 rounded-lg p-2 md:px-5 md:py-2.5 font-semibold text-md leading-md flex items-center"
                onClick={() => setOpenEditAttendeesModal(true)}
              >
                <PlusIcon className="w-5 h-5 md:mr-2" /> <div className="hidden md:block">Add Attendee</div>
              </button>
              <button
                type="submit"
                data-cy="event-save-button"
                className="text-white bg-primary-500 rounded-lg p-2 md:px-5 md:py-2.5 font-semibold text-md leading-md flex items-center"
              >
                Update Event
              </button>
            </div>
          </div>
          <div className="mt-5 md:mt-6">
            <EventBookingList eventSubmit={eventSubmit} />
          </div>
        </div>
        <AddAttendees open={openEditAttendeesModal} onClose={onEditAttendeeClose} eventSubmit={eventSubmit} />
      </form>
    </FormProvider>
  );
}
