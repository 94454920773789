import { useState } from 'react';
import { useDisableUser, useEnableUser } from 'api/hooks/useUser';
import { useToastNofication } from 'context/toastContext';
import { ActivationModal } from 'components/modals/ActivationModal';

interface UserActivateButtonProp {
  id: string;
  isActive: boolean | null | undefined;
}

function UserActivateButton({ id, isActive }: UserActivateButtonProp) {
  const [userEnabled, setUserEnabled] = useState(isActive || false);
  const { enableUser, enableUserLoading } = useEnableUser(id);
  const { disableUser, disableUserLoading } = useDisableUser(id);
  const { addToast } = useToastNofication();
  const [modalOpen, setModalOpen] = useState(false);

  const toggleUserEnable = () => {
    setModalOpen(true);
  };

  const onClose = () => {
    setModalOpen(false);
  };
  const onConfirm = async () => {
    onClose();

    if (!id) return;
    if (userEnabled) {
      const response = await disableUser({
        variables: {
          input: { id },
        },
      });
      addToast(response, 'DisableUser');
    } else {
      const response = await enableUser({
        variables: {
          input: { id },
        },
      });
      addToast(response, 'EnableUser');
    }
    setUserEnabled(!userEnabled);
  };
  const bgColor = userEnabled ? 'bg-error-500' : 'bg-success-500';

  return (
    <>
      <div className="flex flex-col md:flex-row items-start gap-3 md:gap-7">
        <button
          data-testid="toggle-user-enable-button"
          onClick={toggleUserEnable}
          type="button"
          className={`text-white  ${bgColor} rounded-lg px-5 py-2.5 font-semibold text-md leading-md`}
        >
          {userEnabled ? 'Disable user' : 'Enable user'}
        </button>
      </div>
      <ActivationModal
        label="user"
        isDisabled={enableUserLoading || disableUserLoading}
        onClose={onClose}
        isActive={userEnabled}
        onConfirm={onConfirm}
        open={modalOpen}
        alert="Disabling a user will disable their account, and login"
      />
    </>
  );
}

export { UserActivateButton };
