import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import HandshakeIcon from '@mui/icons-material/Handshake';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useToastNofication } from 'context/toastContext';
import { CreatePartnerInput, EditPartnerInput } from '__generated__/graphql';
import { useCreatePartner } from 'api/hooks/usePartner';
import { LocationBar } from 'components';
import { PartnerForm } from './PartnerForm';

function PartnerCreateForm() {
  const { createPartner, createPartnerLoading, createPartnerError } = useCreatePartner();
  const navigate = useNavigate();
  const { addToast } = useToastNofication();
  const onBack = () => {
    navigate('/partners');
  };
  const onSubmit = async (input: CreatePartnerInput | EditPartnerInput) => {
    const response = await createPartner({
      variables: {
        input,
      },
    });
    addToast(response, 'CreatePartner');
    if (!createPartnerLoading && !createPartnerError) {
      navigate('/partners');
    }
  };

  return (
    <>
      <Helmet>
        <title>Partner Update</title>
      </Helmet>
      <LocationBar section="Partner" page="Partner Create Form" Icon={HandshakeIcon} />
      <div className="my-10 px-4 md:px-[5%]">
        <button data-cy="form-detail-back" type="button" className="text-gray-500 font-semibold text-md leading-md flex items-center" onClick={onBack}>
          <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
        </button>
        <PartnerForm onSubmit={onSubmit} formSubmitting={createPartnerLoading} />
      </div>
    </>
  );
}

export default PartnerCreateForm;
