import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline';
import { Row, Table as TableCoreType, flexRender } from '@tanstack/react-table';

export function TableContent<TableType>({ table, noResults }: { table: TableCoreType<TableType>; noResults: boolean }) {
  return (
    <div className="overflow-hidden border-b border-gray-200">
      <table className="min-w-full divide-y divide-gray-200 table-auto	">
        <thead className="">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} className="px-4 text-left text-sm font-semibold text-gray-800">
                  <div
                    {...{
                      className: header.column.getCanSort() ? 'cursor-pointer  select-none flex items-center' : '',
                      onClick: header.column.getToggleSortingHandler(),
                    }}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: (
                        <div className="ml-2">
                          <ArrowUpIcon className="w-3 h-3 text-gray-900" />
                        </div>
                      ),
                      desc: (
                        <div className="ml-2">
                          <ArrowDownIcon className="w-3 h-3 text-gray-900" />
                        </div>
                      ),
                    }[header.column.getIsSorted() as string] ?? null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {!noResults ? (
          <tbody>
            {table.getRowModel().rows.map((row: Row<TableType>, index) => {
              return (
                <tr key={`row-${index}`}>
                  {row.getVisibleCells().map((cell, cellIndex) => {
                    return (
                      <td key={`row-cell-${cellIndex}`} className="px-2 sm:px-4 md:px-6 py-4  text-gray-700 text-md leading-md">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        ) : (
          <div className="text-center px-20">No Results</div>
        )}
      </table>
    </div>
  );
}
