import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';

function TablePagination({
  isNextDisabled,
  isPreviousDisabled,
  onClickPrevious,
  pageCount,
  pageIndex,
  onClickNext,
  setIndex,
}: {
  isNextDisabled: boolean;
  isPreviousDisabled: boolean;
  onClickPrevious: () => void;
  pageCount: number;
  pageIndex: number;
  onClickNext: () => void;
  setIndex: (index: number) => void;
}) {
  return (
    <div className="mt-10 flex justify-between items-center">
      <button
        type="button"
        className="flex items-center rounded-lg shadow-xs border border-gray-300 p-2 sm:px-3.5 sm:py-2 text-sm leading-sm font-medium text-gray-700 disabled:text-gray-400"
        disabled={isPreviousDisabled}
        onClick={() => onClickPrevious()}
      >
        <ArrowLeftIcon className="w-4 h-4" />
        <div className="hidden sm:block sm:ml-2">Previous</div>
      </button>
      <div className="sm:hidden text-gray-700 text-sm leading-sm">
        Page <span className="font-medium">{pageIndex + 1}</span> of {pageCount}
      </div>
      <div className="hidden sm:block">
        {pageCount < 7 ? (
          <div className="flex items-center ">
            {[...Array(pageCount)].map((_, index: number) => (
              <button
                type="button"
                key={index}
                className={
                  pageIndex === index
                    ? 'w-10 h-10 rounded-lg bg-primary-50 text-primary-600 text-sm leading-sm font-medium'
                    : 'w-10 h-10 text-gray-500 text-sm leading-sm font-medium'
                }
                onClick={() => setIndex(index)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        ) : (
          <div className="flex items-center ">
            {[...Array(3)].map((_, index: number) => (
              <button
                type="button"
                key={index}
                className={
                  pageIndex === index
                    ? 'w-10 h-10 rounded-lg bg-primary-50 text-primary-600 text-sm leading-sm font-medium'
                    : 'w-10 h-10 text-gray-500 text-sm leading-sm font-medium'
                }
                onClick={() => setIndex(index)}
              >
                {index + 1}
              </button>
            ))}
            <div className="p-3 text-gray-500 text-sm leading-sm font-medium">...</div>
            {pageIndex > 2 && pageIndex < pageCount - 3 && (
              <>
                <button
                  type="button"
                  className="w-10 h-10 rounded-lg bg-primary-50 text-primary-600 text-sm leading-sm font-medium"
                  onClick={() => setIndex(pageIndex)}
                >
                  {pageIndex + 1}
                </button>
                <div className="p-3 text-gray-500 text-sm leading-sm font-medium">...</div>
              </>
            )}
            {[...Array(3)].map((_, index: number) => (
              <button
                type="button"
                key={index}
                className={
                  pageIndex === pageCount - (3 - index)
                    ? 'w-10 h-10 rounded-lg bg-primary-50 text-primary-600 text-sm leading-sm font-medium'
                    : 'w-10 h-10 text-gray-500 text-sm leading-sm font-medium'
                }
                onClick={() => setIndex(pageCount - (3 - index))}
              >
                {pageCount - (3 - index) + 1}
              </button>
            ))}
          </div>
        )}
      </div>
      <button
        type="button"
        className="flex items-center rounded-lg shadow-xs border border-gray-300 p-2 sm:px-3.5 sm:py-2 text-sm leading-sm font-medium text-gray-700 disabled:text-gray-400"
        disabled={isNextDisabled}
        onClick={() => onClickNext()}
      >
        <div className="hidden sm:block sm:mr-2">Next</div>
        <ArrowRightIcon className="w-4 h-4" />
      </button>
    </div>
  );
}

export { TablePagination };
