import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ReviewsIcon from '@mui/icons-material/Reviews';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { Loading, LocationBar } from 'components';
import { useToastNofication } from 'context/toastContext';
import { useGetEventAttendee, useEditEventAttendee } from 'api/hooks/useEventAttendee';
import { EditEventAttendeeInput, CreateEventAttendeeInput } from '__generated__/graphql';
import { EventAttendeeForm } from './EventAttendeeForm';

function EventAttendeeUpdateForm() {
  const { eventAttendeeId } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToastNofication();

  const { editEventAttendee, editEventAttendeeLoading, editEventAttendeeError } = useEditEventAttendee();
  const onSubmit = async (input: EditEventAttendeeInput | CreateEventAttendeeInput) => {
    const response = await editEventAttendee({
      variables: {
        input,
      },
    });
    addToast(response, 'EditEventAttendee');
    if (!editEventAttendeeLoading && !editEventAttendeeError) {
      navigate('/event-attendees');
    }
  };

  const { eventAttendee, eventAttendeeLoading } = useGetEventAttendee(eventAttendeeId || '');

  if (eventAttendeeLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Event Attendee Update</title>
      </Helmet>
      <LocationBar section="Event Attendee" page="Event Attendee Update Form" Icon={ReviewsIcon} />
      <div className="my-10 px-4 md:px-[5%]">
        <button
          data-cy="event-attendees-detail-back"
          type="button"
          className="text-gray-500 font-semibold text-md leading-md flex items-center"
          onClick={() => navigate('/event-attendees')}
        >
          <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
        </button>
        <EventAttendeeForm eventAttendee={eventAttendee} onSubmit={onSubmit} formSubmitting={false} formSubmitError={undefined} />
      </div>
    </>
  );
}

export default EventAttendeeUpdateForm;
