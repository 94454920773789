import { FormProvider, useForm } from 'react-hook-form';
import { InputAdornment } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { InputField } from 'components';
import { CreateEventInput } from '__generated__/graphql';
import { LocationSelectField } from 'components/fields/LocationSelectField';
import { useCreateEvent } from 'api/hooks/useEvent';
import { useToastNofication } from 'context/toastContext';
import { DateTimeField } from 'components/fields/DateTimeField';

interface CreateEventProps {
  onClose: () => void;
  partnerId: string;
}

export default function CreateEvent({ onClose, partnerId }: CreateEventProps) {
  const { id } = useParams<{ id?: string }>();

  const methods = useForm<CreateEventInput>({
    mode: 'onChange',
    defaultValues: {
      partnerId,
      date: new Date().getTime(),
    },
  });
  const { addToast } = useToastNofication();
  const navigate = useNavigate();
  const { handleSubmit } = methods;
  const { createEvent, createEventLoading, createEventError } = useCreateEvent();

  const eventSubmit = async (data: CreateEventInput) => {
    const response = await createEvent({
      variables: {
        input: {
          ...data,
          bookings: [],
        },
      },
    });
    addToast(response, 'CreateEvent');
    if (!createEventLoading && !createEventError) {
      onClose();
      if (response?.data?.CreateEvent) navigate(id ? `/partners/${id}/events/${response.data.CreateEvent?.id}` : `/events/${response.data.CreateEvent?.id}`);
    }
  };

  const priceAdorment = <InputAdornment position="start">£</InputAdornment>;
  const minsAdorment = <InputAdornment position="end">mins</InputAdornment>;

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-[1200] outline-none focus:outline-none">
        <div className="relative w-auto mx-auto ">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none p-6 max-h-screen md:max-h-[70vh] overflow-y-auto">
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(eventSubmit)}>
                <div className="text-gray-900 text-lg leading-lg font-semibold mb-5">Create Event</div>
                <InputField label="Name" placeholder="Name" name="name" />
                <DateTimeField label="Date" name="date" />
                <InputField label="Price" startAdornment={priceAdorment} type="number" min="0" isRequired placeholder="Price" name="price" />
                <LocationSelectField label="Location" name="locationId" />
                <InputField label="Duration" placeholder="Duration" name="duration" endAdornment={minsAdorment} type="number" min="30" step="15" isRequired />
                <div className="flex justify-between items-center mt-5">
                  <button type="button" className="text-gray-500 font-semibold text-md leading-md" onClick={onClose}>
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={createEventLoading}
                    data-cy="save-event-button"
                    className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
                  >
                    Create
                  </button>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-gray-900" />
    </>
  );
}
