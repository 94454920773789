import { Outlet } from 'react-router-dom';
import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { HeaderToolBar } from './HeaderToolBar';
import { Drawer } from '../Drawer';

function Header() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleLogout = async () => {
    await Auth.signOut();
    window.location.assign('/');
  };
  return (
    <>
      <Drawer toggleDrawer={setDrawerOpen} drawerOpen={drawerOpen} handleLogout={handleLogout} />
      <HeaderToolBar setDrawerOpen={setDrawerOpen} />
    </>
  );
}

function Layout() {
  return (
    <div>
      <Header />
      <Outlet />
    </div>
  );
}

export { Layout };
