import React, { ReactNode } from 'react';
import { useIsUserAdmin } from '../hooks/authHooks';
import Unauthorised from '../pages/Unauthorised';

function ProtectedRoute({ children }: { children: ReactNode }) {
  const isAdmin = useIsUserAdmin();
  return isAdmin ? <> {children} </> : <Unauthorised />;
}

export default ProtectedRoute;
