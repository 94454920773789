import { useForm, FormProvider } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import EmojiPeople from '@mui/icons-material/EmojiPeople';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { DateTimeField, Loading, LocationBar, SelectField } from 'components';
import { Category, ClientAddress, Customer, PartnerPrice, Product } from '__generated__/graphql';
import { useGetProducts } from 'api/hooks/useProduct';
import { useGetCategories } from 'api/hooks/useCategory';
import { useCreateBooking } from 'api/hooks/useBooking';
import { useToastNofication } from 'context/toastContext';
import { useGetPartnerPrices } from 'api/hooks/usePartnerPrice';
import { useGetCustomers } from 'api/hooks/useCustomer';
import { useUser } from 'context/userContext';

function MyBookingCreateForm() {
  const { partner } = useUser();

  const navigate = useNavigate();
  const methods = useForm<{ customerId?: string; categoryId?: string; productId?: string; postcode: string; partnerPriceId?: string }>({
    mode: 'onChange',
    defaultValues: {
      categoryId: '',
      customerId: '',
      productId: '',
      postcode: '',
      partnerPriceId: '',
    },
  });
  const { handleSubmit, watch, setValue } = methods;
  const categoryId = watch('categoryId');
  const productId = watch('productId');
  const { products, productsLoading } = useGetProducts(categoryId);
  const customerId = watch('customerId');
  const postcode = watch('postcode');

  useEffect(() => {
    setValue('productId', '');
    setValue('partnerPriceId', '');
  }, [categoryId, setValue]);

  useEffect(() => {
    setValue('partnerPriceId', '');
  }, [productId, setValue]);

  useEffect(() => {
    setValue('postcode', '');
  }, [customerId, setValue]);

  const { partnerPrices, partnerPricesLoading } = useGetPartnerPrices(partner?.id, productId);
  const { createBooking, createBookingError, createBookingLoading } = useCreateBooking();
  const { addToast } = useToastNofication();
  const { customers, customersLoading } = useGetCustomers();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const bookingFormSubmit = async (data: any) => {
    const customer = customers.find((c) => c.id === customerId);

    const address = customer?.address?.find((a) => a.postcode === postcode);
    const response = await createBooking({
      variables: {
        input: {
          customerId: data.customerId,
          partnerId: partner?.id,
          productId: data.productId,
          partnerPriceId: data.partnerPriceId,
          startDateTime: data.startDateTime,
          endDateTime: data.endDateTime,
          isRecurring: false,
          address,
          preQualifyingQuestions: [],
          amITheSupportedPerson: true, // TODO update when doing ticket about supported person
        },
      },
    });
    addToast(response, 'CreateBooking');
    if (!createBookingLoading && !createBookingError) {
      navigate('/my-bookings');
    }
  };

  const { categories, categoriesLoading } = useGetCategories();

  const productOptions = products.map((product: Product) => {
    return { label: product?.name, value: product?.id };
  });

  const partnerPriceOptions = partnerPrices.map((partnerPrice: PartnerPrice) => {
    return { label: partnerPrice?.bookingPrice?.toString() || '', value: partnerPrice?.id || '' };
  });

  const customersOptions = customers
    ? customers?.map((a: Customer) => {
        return { label: `${a.firstName} ${a.surname}` || '', value: a?.id };
      })
    : [];

  const categoriesOptions = categories
    ? categories?.map((a: Category) => {
        return { label: a?.name || '', value: a?.id };
      })
    : [];

  const customerAddressesOptions = useMemo(() => {
    const customer = customers.find((c) => c.id === customerId);
    return customer?.address
      ? customer.address?.map((a: ClientAddress) => {
          return { label: a?.postcode, value: a?.postcode };
        })
      : [];
  }, [customers, customerId]);

  if (partnerPricesLoading) {
    <Loading />;
  }
  return (
    <>
      <Helmet>
        <title>Booking create</title>
      </Helmet>
      <LocationBar section="Booking" page="Booking create Form" Icon={EmojiPeople} />
      <div className="my-10 px-4 md:px-[5%]">
        <button
          data-cy="form-detail-back"
          type="button"
          className="text-gray-500 font-semibold text-md leading-md flex items-center"
          onClick={() => {
            navigate('/my-bookings');
          }}
        >
          <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
        </button>
        <div className="mt-10 sm:mt-16">
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(bookingFormSubmit)}>
              <SelectField name="customerId" isRequired label="Customer" options={customersOptions} loading={customersLoading} />
              {customerId && <SelectField name="postcode" label="Select a postcode" isRequired options={customerAddressesOptions} />}
              <DateTimeField name="startDateTime" label="Start Date" />
              <DateTimeField name="endDateTime" label="End Date" />
              {postcode && <SelectField name="categoryId" label="Category" isRequired loading={categoriesLoading} options={categoriesOptions} />}

              {categoryId && <SelectField name="productId" label="Select a product" isRequired loading={productsLoading} options={productOptions} />}
              {productId && (
                <SelectField loading={partnerPricesLoading} name="partnerPriceId" isRequired={true} label="Partner Price" options={partnerPriceOptions} />
              )}

              {partnerPrices.length === 0 && !partnerPricesLoading && (
                <div className="text-error-500">You has no partner prices for this product, please add some partner prices </div>
              )}

              {createBookingError && <div className="text-error-500"> {createBookingError.message}</div>}
              <button
                type="submit"
                disabled={createBookingLoading}
                className="text-white bg-primary-500 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
              >
                Save
              </button>
            </form>
          </FormProvider>
        </div>
      </div>
    </>
  );
}

export { MyBookingCreateForm };
