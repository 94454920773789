import { gql, useMutation, useQuery } from '@apollo/client';
import { PartnerReview } from '__generated__/graphql';

export const CREATE_PARTNER_REVIEW = gql`
  mutation CreatePartnerReview($input: CreatePartnerReviewInput) {
    CreatePartnerReview(input: $input) {
      date
      message
    }
  }
`;

export const DELETE_PARTNER_REVIEW = gql`
  mutation DeletePartnerReview($input: PartnerReviewDelete) {
    DeletePartnerReview(input: $input) {
      message
      date
    }
  }
`;

export const EDIT_PARTNER_REVIEW = gql`
  mutation EditPartnerReview($input: EditPartnerReviewInput) {
    EditPartnerReview(input: $input) {
      date
      message
    }
  }
`;

export const GET_PARTNER_REVIEW = gql`
  query GetPartnerReview($args: PartnerReviewSearch) {
    GetPartnerReview(args: $args) {
      customerLocation
      customerName
      description
      id
      partnerId
      rating
      isVisable
    }
  }
`;

export const GET_PARTNER_REVIEWS = gql`
  query GetPartnerReviews($args: PartnerReviewListSearch) {
    GetPartnerReviews(args: $args) {
      id
      customerName
      customerLocation
      rating
      description
      partnerId
      partnerName
      partnerBusinessName
    }
  }
`;

interface PartnerReviewsResponse {
  GetPartnerReviews: PartnerReview[];
}

interface PartnerReviewResponse {
  GetPartnerReview: PartnerReview;
}

const useDeletePartnerReview = (partnerId: string) => {
  const [deletePartnerReview, { loading: deletePartnerReviewLoading, error: deletePartnerReviewError }] = useMutation(DELETE_PARTNER_REVIEW, {
    refetchQueries: [
      {
        query: GET_PARTNER_REVIEWS,
        variables: {
          args: { partnerId },
        },
      },
    ],
  });

  return {
    deletePartnerReview,
    deletePartnerReviewLoading,
    deletePartnerReviewError,
  };
};

const useCreatePartnerReview = (partnerId: string) => {
  const [createPartnerReview, { loading: createPartnerReviewLoading, error: createPartnerReviewError }] = useMutation(CREATE_PARTNER_REVIEW, {
    refetchQueries: [
      {
        query: GET_PARTNER_REVIEWS,
        variables: {
          args: { partnerId },
        },
      },
    ],
  });

  return {
    createPartnerReview,
    createPartnerReviewLoading,
    createPartnerReviewError,
  };
};

const useEditPartnerReview = (partnerId: string) => {
  const [editPartnerReview, { loading: editPartnerReviewLoading, error: editPartnerReviewError }] = useMutation(EDIT_PARTNER_REVIEW, {
    refetchQueries: [
      {
        query: GET_PARTNER_REVIEWS,
        variables: {
          args: { partnerId },
        },
      },
    ],
  });

  return {
    editPartnerReview,
    editPartnerReviewLoading,
    editPartnerReviewError,
  };
};

const useGetPartnerReview = (id?: string) => {
  const {
    loading: partnerReviewLoading,
    data,
    error: partnerReviewError,
    refetch,
  } = useQuery<PartnerReviewResponse>(GET_PARTNER_REVIEW, {
    variables: {
      args: {
        id,
      },
    },
  });

  return {
    partnerReview: data?.GetPartnerReview || undefined,
    refetch,
    partnerReviewLoading,
    partnerReviewError,
  };
};

const useGetPartnerReviews = (partnerId: string) => {
  const {
    loading: partnerReviewsLoading,
    data,
    error: partnerReviewsError,
    refetch,
  } = useQuery<PartnerReviewsResponse>(GET_PARTNER_REVIEWS, {
    variables: {
      args: {
        partnerId,
      },
    },
  });

  return {
    partnerReviews: data?.GetPartnerReviews || [],
    refetch,
    partnerReviewsLoading,
    partnerReviewsError,
  };
};

export { useGetPartnerReview, useGetPartnerReviews, useCreatePartnerReview, useDeletePartnerReview, useEditPartnerReview };
