import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { CurrencyPound } from '@mui/icons-material';
import { useEditPartnerPrice, useGetPartnerPrice } from 'api/hooks/usePartnerPrice';
import { EditPartnerPriceInput, CreatePartnerPriceInput } from '__generated__/graphql';
import { Loading, LocationBar } from 'components';
import { useToastNofication } from 'context/toastContext';
import { PartnerPriceForm } from './PartnerPriceForm';

function PartnerPriceUpdateForm() {
  const { id, priceId } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToastNofication();

  const { editPartnerPrice, editPartnerPriceLoading, editPartnerPriceError } = useEditPartnerPrice(id || '');
  const onSubmit = async (input: CreatePartnerPriceInput | EditPartnerPriceInput) => {
    const response = await editPartnerPrice({
      variables: {
        input,
      },
    });
    addToast(response, 'EditPartnerPrice');

    if (!editPartnerPriceLoading && !editPartnerPriceError) {
      navigate(id ? `/partners/${id}/prices` : '/prices');
    }
  };
  const onBack = () => {
    navigate(id ? `/partners/${id}/prices` : '/prices');
  };
  const { partnerPrice, partnerPriceLoading } = useGetPartnerPrice(priceId);

  if (partnerPriceLoading) {
    return <Loading />;
  }
  return (
    <>
      <Helmet>
        <title>Price update</title>
      </Helmet>
      <LocationBar section="Partner" page="Price Update Form" Icon={CurrencyPound} />
      <div className="my-10 px-4 md:px-[5%]">
        <button data-cy="form-detail-back" type="button" className="text-gray-500 font-semibold text-md leading-md flex items-center" onClick={onBack}>
          <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
        </button>
        <PartnerPriceForm partnerPrice={partnerPrice} onSubmit={onSubmit} formSubmitting={editPartnerPriceLoading} formSubmitError={editPartnerPriceError} />
      </div>
    </>
  );
}

export default PartnerPriceUpdateForm;
