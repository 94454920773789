import { FormProvider, useForm } from 'react-hook-form';
import { ApolloError } from '@apollo/client';
import { CheckboxField, Modal } from 'components';

function ConfirmationMessageModal({
  open,
  onClose,
  onResendBookingConfirmation,
  formSubmitting,
  formSubmitError,
}: {
  open: boolean;
  formSubmitting: boolean;
  formSubmitError: ApolloError | undefined;
  onClose: () => void;
  onResendBookingConfirmation: (input: { isToCustomer: { email: boolean; sms: boolean }; isToPartner: { email: boolean; sms: boolean } }) => void;
}) {
  const methods = useForm<{ isToCustomer: { email: boolean; sms: boolean }; isToPartner: { email: boolean; sms: boolean } }>({
    mode: 'onChange',
  });
  const { handleSubmit, watch } = methods;

  const isToCustomer = watch('isToCustomer');
  const isToPartner = watch('isToPartner');

  return (
    <Modal
      onConfirm={handleSubmit(onResendBookingConfirmation)}
      open={open}
      onClose={onClose}
      isDisabled={formSubmitting || !isToPartner || !isToCustomer}
      title="Resend Confirmation"
      confirmLabel="Send"
    >
      <FormProvider {...methods}>
        <form className="p-2">
          <div className="w-full text-md font-semibold"> To Customer </div>
          <div className="flex flex-row p-4">
            <CheckboxField name="isToCustomer.email" label="Email" />
            <CheckboxField name="isToCustomer.sms" label="SMS" />
          </div>

          <div className="w-full text-md font-semibold"> To Partner </div>
          <div className="flex flex-row p-4">
            <CheckboxField name="isToPartner.email" label="Email" />
            <CheckboxField name="isToPartner.sms" label="SMS" />
          </div>
          {formSubmitError && <div className="text-error-500">{formSubmitError.message}</div>}
        </form>
      </FormProvider>
    </Modal>
  );
}

export { ConfirmationMessageModal };
