/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useMemo, useCallback, Suspense } from 'react';
import { format, isSameDay, isValid } from 'date-fns';
import EventIcon from '@mui/icons-material/Event';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { createColumnHelper, ColumnDef } from '@tanstack/react-table';
import { Booking } from '__generated__/graphql';
import { useGetBookings, useCancelBooking, useCompleteBooking, useResendBookingConfirmation } from 'api/hooks/useBooking';
import { useToastNofication } from 'context/toastContext';
import { DeleteModal, LocationBar, Table, Loading } from 'components';
import { ConfirmationMessageModal } from './ConfirmationMessageModal';

function TableCheckBox({ row }: { row: any }) {
  return (
    <div className="flex items-center">
      <input
        type="checkbox"
        checked={row.original.isActive || false}
        disabled={true}
        className="w-4 h-4 text-primary-300 bg-gray-100 border-gray-300 rounded cursor-pointer"
      />
    </div>
  );
}

function Status({ row }: { row: any }) {
  const { status } = row.original;
  switch (true) {
    case status === 'ACTIVE':
      return (
        <div className="bg-success-50 border border-success-700 rounded-2xl text-success-700 text-sm leading-sm font-medium px-2.5 py-0.5 w-fit">{status}</div>
      );

    case status === 'CANCELLED':
      return <div className="bg-error-50 border border-error-700 rounded-2xl text-error-700 text-sm leading-sm font-medium px-2.5 py-0.5 w-fit">{status}</div>;
    default:
      return <div className="bg-gray-50 border border-gray-700 rounded-2xl text-gray-700 text-sm leading-sm font-medium px-2.5 py-0.5 w-fit">{status}</div>;
  }
}

function CompletedBookingCheckBox({ row }: { row: any }) {
  const { completeBooking, completeBookingLoading } = useCompleteBooking();
  const { addToast } = useToastNofication();

  const onComplete = useCallback(
    async (id: string) => {
      const response = await completeBooking({
        variables: {
          input: { id },
        },
      });
      addToast(response, 'CompleteBooking');
    },
    [completeBooking, addToast],
  );
  if (row.original.status !== 'CANCELLED') {
    return (
      <div className="flex items-center">
        <input
          type="checkbox"
          checked={row.original.isComplete || false}
          disabled={completeBookingLoading}
          onChange={() => onComplete(row.original.id || '')}
          className="w-4 h-4 text-primary-300 bg-gray-100 border-gray-300 rounded cursor-pointer"
        />
      </div>
    );
  }
}

function BookingList() {
  const navigate = useNavigate();
  const { addToast } = useToastNofication();

  const [selectedBookingId, setSelectedBookingId] = useState<string | undefined>();
  const { resendBookingConfirmation, resendBookingConfirmationLoading, resendBookingConfirmationError } = useResendBookingConfirmation();

  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const onModalOpen = (id: string) => {
    setModalOpen(true);
    setSelectedBookingId(id);
  };

  const onClose = () => {
    setModalOpen(false);
  };

  const onDeleteModalOpen = (id: string) => {
    setDeleteModalOpen(true);
    setSelectedBookingId(id);
  };

  const onDeleteClose = () => {
    setDeleteModalOpen(false);
  };

  const { bookings, bookingsLoading, refetch } = useGetBookings();
  const { cancelBooking, cancelBookingLoading } = useCancelBooking();
  const [search, setSearch] = useState('');
  const [appointmentDate, setAppointment] = useState<Date | undefined | null>();

  const onCancel = useCallback(
    async (id: string) => {
      const response = await cancelBooking({
        variables: {
          input: { id },
        },
      });
      addToast(response, 'CancelBooking');
      refetch();
      onDeleteClose();
    },
    [addToast, refetch, cancelBooking],
  );

  const onResendBookingConfirmation = async (input: { isToCustomer: { email: boolean; sms: boolean }; isToPartner: { email: boolean; sms: boolean } }) => {
    onClose();

    const response = await resendBookingConfirmation({
      variables: {
        input: {
          bookingId: selectedBookingId,
          ...input,
        },
      },
    });
    addToast(response, 'ResendBookingConfirmation');
  };
  const columnHelper = createColumnHelper<Booking>();

  const columns = useMemo(() => {
    return [
      columnHelper.group({
        header: 'Customer',
        footer: (info: { column: { id: any } }) => info.column.id,
        columns: [
          columnHelper.accessor('customer', {
            header: () => 'Name',
            cell: ({ row }) => `${row.original.customer?.firstName} ${row.original.customer?.surname}`,
            footer: (info: { column: { id: any } }) => info.column.id,
          }),
          columnHelper.accessor('customer', {
            header: () => 'Email Address',
            cell: ({ row }) => row.original.customer?.email,
            footer: (info: { column: { id: any } }) => info.column.id,
          }),
        ],
      }),
      columnHelper.accessor('partner', {
        header: () => 'Partner',
        cell: ({ row }) => row.original.partner?.businessName,
        footer: (info: { column: { id: any } }) => info.column.id,
      }),

      columnHelper.accessor('startDateTime', {
        header: () => 'Requested Booking Date',
        cell: (info) => {
          return <span>{isValid(info.renderValue()) ? format(info.renderValue() || 0, 'eee dd  MMM yyyy') : 'No Booking date'}</span>;
        },
        footer: (info: { column: { id: any } }) => info.column.id,
      }),

      columnHelper.accessor('startDateTime', {
        header: () => 'Requested Booking Time',
        cell: (info) => {
          return <span>{isValid(info.renderValue()) ? format(info.renderValue() || 0, 'p') : 'No Booking time'}</span>;
        },
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.accessor('status', {
        header: () => 'Booking Status',
        cell: Status,
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.accessor('isComplete', {
        header: () => 'Is Completed',
        cell: CompletedBookingCheckBox,
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.accessor('isFirstBooking', {
        header: () => 'New Customer',
        cell: TableCheckBox,
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.display({
        id: 'confirmation',
        cell: (props) => {
          if (props.row.original.status !== 'CANCELLED') {
            return (
              <button
                type="button"
                onClick={() => onModalOpen(props.row.original.id || '')}
                className="text-white bg-primary-500 font-medium rounded-lg text-sm p-2 lg:px-5 lg:py-2.5 text-center inline-flex items-center"
              >
                <span className="ml-2  block"> Resend Confirmation</span>
              </button>
            );
          }
        },
      }),
      columnHelper.display({
        id: 'status',
        cell: ({ row }) => {
          return (
            row.original.status === 'ACTIVE' && (
              <button
                type="button"
                onClick={() => onDeleteModalOpen(row.original.id || '')}
                className="text-white bg-error-600 font-medium rounded-lg text-sm p-2 lg:px-5 lg:py-2.5 text-center inline-flex items-center"
              >
                <span className="ml-2 block">Cancel Booking</span>
              </button>
            )
          );
        },
      }),
    ] as Array<ColumnDef<Booking, unknown>>;
  }, [columnHelper]);

  const filterByName = useCallback(
    (booking: Booking) => {
      if (booking && booking.partnerName?.toLowerCase().includes(search.toLowerCase())) {
        return true;
      }
      if (booking.customer && booking.customer.firstName?.toLowerCase().includes(search.toLowerCase())) return true;
      if (booking.customer && booking.customer.surname?.toLowerCase().includes(search.toLowerCase())) return true;
      if (booking.customer && `${booking.customer.firstName} ${booking.customer.surname}`?.toLowerCase().includes(search.toLowerCase())) return true;

      return false;
    },
    [search],
  );
  const filterByAppointmetDate = useCallback(
    (booking: Booking) => {
      const filterDate = new Date(booking.startDateTime);
      if (!appointmentDate) {
        return true;
      } else if (appointmentDate && isSameDay(appointmentDate, filterDate)) {
        return true;
      }
      return false;
    },
    [appointmentDate],
  );

  const filteredBookings = useMemo(() => {
    return bookings
      ?.filter((booking: Booking) => filterByName(booking))
      .filter((booking: Booking) => filterByAppointmetDate(booking))
      .sort((x, y) => (y.startDateTime || 0) - (x.startDateTime || 0));
  }, [bookings, filterByName, filterByAppointmetDate]);

  const csv = useMemo(() => {
    return filteredBookings?.map((b) => {
      return {
        ...b,
        customer: JSON.stringify(b.customer).replace(/"/g, "'"),
        address: JSON.stringify(b.address).replace(/"/g, "'"),
        partner: JSON.stringify(b.partner).replace(/"/g, "'"),
        supportedPerson: JSON.stringify(b.supportedPerson).replace(/"/g, "'"),
        startDateTime: format(b.startDateTime ?? 0, 'dd/MM/yy'),
        endDateTime: format(b.endDateTime ?? 0, 'dd/MM/yy'),
      };
    });
  }, [filteredBookings]);

  const onConfirm = () => {
    onCancel(selectedBookingId || '');
    onDeleteClose();
  };

  if (bookingsLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Booking List</title>
      </Helmet>
      <LocationBar section="Booking" page="Booking List" Icon={EventIcon} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-display-lg leading-display-lg tracking-display-lg text-black font-medium">Booking List</div>

          {/* <button
            type="button"
            className="text-white bg-primary-500 rounded-lg px-5 py-2.5 font-semibold text-md leading-md hidden md:block"
            onClick={() => navigate('create')}
          >
            Add Booking
          </button> */}
          <button
            type="button"
            className="text-white bg-primary-500 rounded-lg px-5 py-2.5 font-semibold text-md leading-md  md:block"
            onClick={() => navigate('create')}
          >
            Add Booking
          </button>
          <button
            type="button"
            aria-label="Add Booking"
            className="text-white bg-primary-500 rounded-lg p-2 font-semibold text-md leading-md md:hidden"
            onClick={() => navigate('create')}
          >
            <PlusIcon className="w-5 h-5 text-white" />
          </button>
        </div>
        <Suspense fallback={<Loading />}>
          <Table<Booking>
            search={search}
            setSearch={setSearch}
            dateFilter={appointmentDate}
            setDateFilter={setAppointment}
            csv={{ data: csv || [], fileName: 'bookingList' }}
            data={filteredBookings || []}
            columns={columns}
          />
        </Suspense>
      </div>
      <ConfirmationMessageModal
        open={modalOpen}
        onClose={onClose}
        onResendBookingConfirmation={onResendBookingConfirmation}
        formSubmitting={resendBookingConfirmationLoading}
        formSubmitError={resendBookingConfirmationError}
      />
      <DeleteModal onClose={onDeleteClose} open={deleteModalOpen} onConfirm={onConfirm} isDisabled={cancelBookingLoading} isCancel />
    </>
  );
}

export default BookingList;
