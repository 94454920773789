/* eslint-disable @typescript-eslint/no-explicit-any */
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { Suspense, useMemo, useState } from 'react';
import { PlusIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { CellContext, ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useDeletePartnerReview, useGetPartnerReviews } from 'api/hooks/usePartnerReview';
import { PartnerReview } from '__generated__/graphql';
import { Loading, LocationBar, Table, DeleteModal } from 'components';
import { useToastNofication } from 'context/toastContext';

interface RowActionProps extends CellContext<PartnerReview, unknown> {
  onDeleteModalOpen: (id: string) => void;
}

function RowActions(props: RowActionProps) {
  const navigate = useNavigate();

  return (
    <div className="flex items-center gap-3">
      <button type="button" onClick={() => navigate(`${props.row.original.id}/update`)} aria-label="Edit">
        <PencilIcon className="w-6 h-6 text-gray-500 hover:text-primary-600" />
      </button>
      <button type="button" onClick={() => props.onDeleteModalOpen(props.row.original.id)} aria-label="Delete">
        <TrashIcon className="w-6 h-6 text-gray-500 hover:text-primary-600" />
      </button>
    </div>
  );
}

function PartnerReviewList() {
  const { id: partnerId } = useParams();
  const navigate = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedReviewId, setSelectedReviewId] = useState<string | undefined>();
  const { addToast } = useToastNofication();

  const { deletePartnerReview, deletePartnerReviewLoading } = useDeletePartnerReview(partnerId || '');
  const onDeleteClose = () => {
    setDeleteModalOpen(false);
  };

  const onDelete = async (id: string) => {
    const response = await deletePartnerReview({
      variables: {
        input: { id },
      },
    });
    addToast(response, 'DeletePartnerReview');
    refetch();
    onDeleteClose();
  };

  const onDeleteModalOpen = (id: string) => {
    setDeleteModalOpen(true);
    setSelectedReviewId(id);
  };

  const columnHelper = createColumnHelper<PartnerReview>();

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('customerName', {
        header: () => 'Customer Name',
        cell: (info: { renderValue: () => any }) => info.renderValue(),
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.accessor('customerLocation', {
        header: () => 'Customer Location',
        cell: (info: { renderValue: () => any }) => info.renderValue(),
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.accessor('rating', {
        header: () => 'Rating',
        cell: (info: { renderValue: () => any }) => info.renderValue(),
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.accessor('description', {
        header: () => 'Description',
        cell: (info: { renderValue: () => any }) => info.renderValue(),
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.accessor('partnerBusinessName', {
        header: () => 'Partner',
        cell: (info: { renderValue: () => any }) => info.renderValue(),
        footer: (info: { column: { id: any } }) => info.column.id,
      }),

      columnHelper.display({
        id: 'actions',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (props) => <RowActions {...props} onDeleteModalOpen={onDeleteModalOpen} />,
      }),
    ] as Array<ColumnDef<PartnerReview, unknown>>;
  }, [columnHelper]);

  const { partnerReviews, partnerReviewsLoading, refetch } = useGetPartnerReviews(partnerId || '');
  const onConfirm = () => {
    onDelete(selectedReviewId || '');
    onDeleteClose();
  };

  if (partnerReviewsLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Partner Review List</title>
      </Helmet>
      <LocationBar section="Partner" page="Review List" Icon={RateReviewIcon} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-display-lg leading-display-lg tracking-display-lg text-black font-medium">Review List</div>
          <button
            type="button"
            className="text-white bg-primary-500 rounded-lg px-5 py-2.5 font-semibold text-md leading-md hidden md:block"
            onClick={() => navigate('create')}
          >
            Add Review
          </button>
          <button
            type="button"
            aria-label="Add review"
            className="text-white bg-primary-500 rounded-lg p-2 font-semibold text-md leading-md md:hidden"
            onClick={() => navigate('create')}
          >
            <PlusIcon className="w-5 h-5 text-white" />
          </button>
        </div>
        <Suspense fallback={<Loading />}>
          <Table<PartnerReview> csv={{ data: partnerReviews, fileName: 'partnerReviewList' }} data={partnerReviews} columns={columns} />
        </Suspense>
      </div>
      <DeleteModal onClose={onDeleteClose} open={deleteModalOpen} onConfirm={onConfirm} isDisabled={deletePartnerReviewLoading} />
    </>
  );
}

export default PartnerReviewList;
