import { gql, useMutation, useQuery } from '@apollo/client';
import { Booking } from '../../__generated__/graphql';

export const CANCEL_BOOKING = gql`
  mutation CancelBooking($input: BookingSearch) {
    CancelBooking(input: $input) {
      date
      message
    }
  }
`;

export const COMPLETE_BOOKING = gql`
  mutation CompleteBooking($input: BookingSearch) {
    CompleteBooking(input: $input) {
      date
      message
    }
  }
`;

export const CREATE_BOOKING = gql`
  mutation CreateBooking($input: CreateBookingInput) {
    CreateBooking(input: $input) {
      date
      message
    }
  }
`;

export const RESEND_BOOKING_CONFIRMATION = gql`
  mutation ResendBookingConfirmation($input: ResendBookingConfirmationInput) {
    ResendBookingConfirmation(input: $input) {
      date
      message
    }
  }
`;
export const GET_BOOKINGS = gql`
  query GetBookings($args: BookingListSearch) {
    GetBookings(args: $args) {
      amITheSupportedPerson
      customer {
        email
        firstName
        phoneNumber
        id
        surname
        address {
          addressLine1
          addressLine2
          city
          postcode
          region
          addressNotes
        }
      }
      address {
        addressLine1
        addressLine2
        city
        postcode
        region
        addressNotes
      }
      isComplete
      isFirstBooking
      status
      endDateTime
      isRecurring
      id
      partner {
        id
        businessName
      }
      partnerName
      startDateTime
      supportedPersonId
      supportedPerson {
        surname
        firstName
      }
    }
  }
`;

interface BookingsResponse {
  GetBookings: Booking[];
}

const useCancelBooking = () => {
  const [cancelBooking, { loading: cancelBookingLoading, error: cancelBookingError }] = useMutation(CANCEL_BOOKING, {
    refetchQueries: [
      {
        query: GET_BOOKINGS,
      },
    ],
  });

  return {
    cancelBooking,
    cancelBookingLoading,
    cancelBookingError,
  };
};

const useCompleteBooking = () => {
  const [completeBooking, { loading: completeBookingLoading, error: completeBookingError }] = useMutation(COMPLETE_BOOKING, {
    refetchQueries: [
      {
        query: GET_BOOKINGS,
      },
    ],
  });

  return {
    completeBooking,
    completeBookingLoading,
    completeBookingError,
  };
};

const useCreateBooking = () => {
  const [createBooking, { loading: createBookingLoading, error: createBookingError }] = useMutation(CREATE_BOOKING, {
    refetchQueries: [
      {
        query: GET_BOOKINGS,
      },
    ],
  });

  return {
    createBooking,
    createBookingLoading,
    createBookingError,
  };
};

const useResendBookingConfirmation = () => {
  const [resendBookingConfirmation, { loading: resendBookingConfirmationLoading, error: resendBookingConfirmationError }] =
    useMutation(RESEND_BOOKING_CONFIRMATION);

  return {
    resendBookingConfirmation,
    resendBookingConfirmationLoading,
    resendBookingConfirmationError,
  };
};

const useGetBookings = () => {
  const { loading: bookingsLoading, data, error: bookingsError, refetch } = useQuery<BookingsResponse>(GET_BOOKINGS);
  const mappedBookings = data?.GetBookings?.map((booking: Booking) => {
    return {
      ...booking,
      customerId: booking.customer.id,
      customerName: `${booking.customer?.firstName} ${booking.customer?.surname}`,
      customerEmail: booking.customer?.email,
      partnerName: booking.partner?.businessName,
      bookingDate: new Date(booking.startDateTime).toDateString(),
      bookingTime: new Date(booking.startDateTime).toLocaleTimeString('en-GB'),
    };
  });
  return {
    bookings: mappedBookings,
    refetch,
    bookingsLoading,
    bookingsError,
  };
};

export { useCompleteBooking, useGetBookings, useCancelBooking, useCreateBooking, useResendBookingConfirmation };
