import { CheckCircleIcon, XCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useCallback, useEffect } from 'react';

const toastTypes: Record<string, { icon: JSX.Element; progressBarClass: string; color: string }> = {
  success: {
    icon: <CheckCircleIcon className="w-8 h-8 text-success-500" />,
    progressBarClass: 'bg-success-500',
    color: 'success',
  },
  error: {
    icon: <XCircleIcon className="w-8 h-8 text-error-600" />,
    progressBarClass: 'bg-error-500',
    color: 'error',
  },
};

export interface ToastProps {
  message: string;
  type: string;
  id: string;
  removeToast: (id: string) => void;
}
function Toast({ message, type, id, removeToast }: ToastProps) {
  const { icon } = toastTypes[type] || {};
  const handleDismiss = useCallback(() => {
    removeToast(id);
  }, [id, removeToast]);

  useEffect(() => {
    setTimeout(() => {
      handleDismiss();
    }, 4000);
  }, [handleDismiss]);

  if (type === 'error') {
    return (
      <div className="relative text-center justify-center w-1/2  z-50	">
        <div
          id="toast-default"
          className="flex items-center justify-center w-full  p-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
          role="alert"
        >
          <div className="inline-flex items-center justify-center flex-shrink-0 w-12 h-12 text-error-500 bg-error-100 rounded-lg dark:bg-blue-800 dark:text-blue-200">
            {icon}
          </div>
          <div className="ms-3 text-md font-semibold">{message}</div>
          <button
            type="button"
            // eslint-disable-next-line max-len
            className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-15 w-15 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
            aria-label="delete toast"
            onClick={handleDismiss}
          >
            <XMarkIcon className="w-5 h-5 " />
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="relative text-center justify-center w-1/2  z-50	">
        <div
          id="toast-default"
          className="flex items-center justify-center w-full  p-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
          role="alert"
        >
          <div className="inline-flex items-center justify-center flex-shrink-0 w-12 h-12 text-success-500 bg-success-100 rounded-lg dark:bg-blue-800 dark:text-blue-200">
            {icon}
          </div>
          <div className="ms-3 text-md font-semibold">{message}</div>
          <button
            type="button"
            // eslint-disable-next-line max-len
            className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-15 w-15 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
            aria-label="delete toast"
            onClick={handleDismiss}
          >
            <XMarkIcon className="w-5 h-5 " />
          </button>
        </div>
      </div>
    );
  }
}

export default Toast;
