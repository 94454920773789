/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-big-calendar';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';

export default function MobileWeek({
  date,
  localizer,
  max = localizer.endOf(new Date(), 'day'),
  min = localizer.startOf(new Date(), 'day'),
  scrollToTime = localizer.startOf(new Date(), 'day'),
  ...props
}: any) {
  const currRange = useMemo(() => MobileWeek.range(date, { localizer }), [date, localizer]);

  return <TimeGrid date={date} eventOffset={15} localizer={localizer} max={max} min={min} range={currRange} scrollToTime={scrollToTime} {...props} />;
}

MobileWeek.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  localizer: PropTypes.object,
  max: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  scrollToTime: PropTypes.instanceOf(Date),
};

MobileWeek.range = (date: any, { localizer }: any) => {
  const start: any = date;
  const end = localizer.add(start, 2, 'day');

  let current: any = start;
  const range: Date[] = [];

  while (localizer.lte(current, end, 'day')) {
    range.push(current);
    current = localizer.add(current, 1, 'day');
  }

  return range;
};

MobileWeek.navigate = (date: any, action: any, { localizer }: any) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return localizer.add(date, -3, 'day');

    case Navigate.NEXT:
      return localizer.add(date, 3, 'day');

    default:
      return date;
  }
};

MobileWeek.title = (date: any, { localizer }: any) => {
  const [start, ...rest] = MobileWeek.range(date, { localizer });
  return localizer.format({ start, end: rest.pop() }, 'dayRangeHeaderFormat');
};
