import { gql, useMutation, useQuery } from '@apollo/client';
import { PartnerPrice } from '__generated__/graphql';

export const CREATE_PARTNER_PRICE = gql`
  mutation CreatePartnerPrice($input: CreatePartnerPriceInput) {
    CreatePartnerPrice(input: $input) {
      date
      message
    }
  }
`;

export const EDIT_PARTNER_PRICE = gql`
  mutation EditPartnerPrice($input: EditPartnerPriceInput) {
    EditPartnerPrice(input: $input) {
      date
      message
    }
  }
`;

export const GET_PARTNER_PRICE = gql`
  query GetPartnerPrice($args: PartnerPriceSearch) {
    GetPartnerPrice(args: $args) {
      id
      partnerId
      productId
      basePrice
      duration
      bookingPrice
      markup
      duration
    }
  }
`;

export const GET_PARTNER_PRICES = gql`
  query GetPartnerPrices($args: PartnerPriceListSearch) {
    GetPartnerPrices(args: $args) {
      id
      bookingPrice
      partnerId
      productId
      basePrice
      markup
      duration
      partnerName
      productName
      partnerBusinessName
    }
  }
`;

interface PartnerPricesResponse {
  GetPartnerPrices: PartnerPrice[];
}

interface PartnerPriceResponse {
  GetPartnerPrice: PartnerPrice;
}

const useCreatePartnerPrice = (partnerId: string) => {
  const [createPartnerPrice, { loading: createPartnerPriceLoading, error: createPartnerPriceError }] = useMutation(CREATE_PARTNER_PRICE, {
    refetchQueries: [
      {
        query: GET_PARTNER_PRICES,
        variables: {
          args: { partnerId },
        },
      },
    ],
  });

  return {
    createPartnerPrice,
    createPartnerPriceLoading,
    createPartnerPriceError,
  };
};

const useEditPartnerPrice = (partnerId: string) => {
  const [editPartnerPrice, { loading: editPartnerPriceLoading, error: editPartnerPriceError }] = useMutation(EDIT_PARTNER_PRICE, {
    refetchQueries: [
      {
        query: GET_PARTNER_PRICES,
        variables: {
          args: { partnerId },
        },
      },
    ],
  });

  return {
    editPartnerPrice,
    editPartnerPriceLoading,
    editPartnerPriceError,
  };
};

const useGetPartnerPrices = (partnerId?: string, productId?: string) => {
  const {
    loading: partnerPricesLoading,
    data,
    error: partnerPricesError,
    refetch,
  } = useQuery<PartnerPricesResponse>(GET_PARTNER_PRICES, {
    variables: {
      args: {
        partnerId,
        productId,
      },
    },
  });

  return {
    partnerPrices: data?.GetPartnerPrices || [],
    refetch,
    partnerPricesLoading,
    partnerPricesError,
  };
};

const useGetPartnerPrice = (id?: string) => {
  const {
    loading: partnerPriceLoading,
    data,
    error: partnerPriceError,
    refetch,
  } = useQuery<PartnerPriceResponse>(GET_PARTNER_PRICE, {
    fetchPolicy: 'network-only',
    variables: {
      args: {
        id,
      },
    },
  });

  return {
    partnerPrice: data?.GetPartnerPrice || undefined,
    refetch,
    partnerPriceLoading,
    partnerPriceError,
  };
};

export { useCreatePartnerPrice, useGetPartnerPrices, useEditPartnerPrice, useGetPartnerPrice };
