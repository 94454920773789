import { DrawerItem } from './DrawerItem';

export interface DrawerSectionItemProps {
  text: string;
  link: string;
  icon: JSX.Element;
  disabled?: boolean;
  testId?: string;
  isHidden?: boolean;
}

interface DrawerSectionProps {
  sectionItems: DrawerSectionItemProps[];
}

function DrawerSection({ sectionItems }: DrawerSectionProps) {
  const shownSectionItem = sectionItems.filter((sectionItem) => sectionItem.isHidden === false);
  return (
    <>
      {shownSectionItem.map(({ link, text, icon, disabled, testId }) => {
        return <DrawerItem key={text} link={link} text={text} icon={icon} disabled={disabled} testId={testId} />;
      })}
    </>
  );
}

export { DrawerSection };
