import { Amplify } from 'aws-amplify';
import { Authenticator, useAuthenticator, View, Image, Text, Heading, Button } from '@aws-amplify/ui-react';
import { ApolloProvider } from '@apollo/client';
import { storyblokInit, apiPlugin } from '@storyblok/react';
import { HelmetProvider } from 'react-helmet-async';
import config from './config';
import Routes from './routes';
import { client } from './api';
import { UserProvider, UserWithAttributes } from './context/userContext';
import { ToastNoficationProvider } from './context/toastContext';

Amplify.configure(config);
storyblokInit({
  accessToken: process.env.REACT_APP_STORYBLOK_API_KEY,
  use: [apiPlugin],
});

storyblokInit({
  accessToken: 'p3icvUnBHKPzZa9KYXxQeQtt',
  use: [apiPlugin],
});

const components = {
  Header() {
    return (
      <View textAlign="center">
        <View style={{ padding: '2em' }}>
          <Image width="50%" height="50%" alt="BelleVie" src="logo-t.png" />
          <Heading data-cy="welcome" level={4}>
            Welcome to the Thrive Partner Portal
          </Heading>
        </View>
      </View>
    );
  },
  SignIn: {
    Header() {
      return (
        <View
          style={{
            paddingTop: '1.4em',
            paddingLeft: '2em',
            paddingRight: '2em',
          }}
        >
          <Heading level={4}>Enter your email address</Heading>
          <View style={{ paddingTop: '1.4em' }}>If this is your first time logging in, please reset your password below to create your account</View>
        </View>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center" style={{ paddingBottom: '1em' }}>
          <Button fontWeight="normal" onClick={toResetPassword} size="small" variation="link">
            Reset Password
          </Button>
        </View>
      );
    },
  },
  ResetPassword: {
    Header() {
      return <Heading level={4}>Reset your Password:</Heading>;
    },
    Footer() {
      return <Text>Please check your email for a a confirmation code</Text>;
    },
  },
  ConfirmResetPassword: {
    Header() {
      return <Heading level={4}>Confirmation Code</Heading>;
    },
    Footer() {
      const { toSignIn } = useAuthenticator();

      return (
        <View textAlign="center" style={{ paddingBottom: '1em' }}>
          <Button fontWeight="normal" onClick={toSignIn} size="small" variation="link">
            Back to Sign In
          </Button>
        </View>
      );
    },
  },
};

const formFields = {
  signIn: {
    username: {
      labelHidden: false,
      label: 'Email',
      placeholder: 'Enter your email',
    },
    password: {
      labelHidden: false,
      label: 'Password (12 characters with one lower/upper case)',
      placeholder: 'Enter your Password',
    },
  },
  resetPassword: {
    username: {
      labelHidden: false,
      label: 'Email',
      placeholder: 'Enter your email',
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      labelHidden: false,
      placeholder: 'Enter your Code:',
      label: 'Enter your confirmation code, (please check you email)',
      isRequired: false,
    },
    password: {
      labelHidden: false,
      label: 'Password (12 characters with one lower/upper case)',
      placeholder: 'Enter your Password',
    },
    confirm_password: {
      labelHidden: false,
      placeholder: 'Confirm your Password',
    },
  },
};

function App() {
  return (
    <HelmetProvider context={{}}>
      <Authenticator formFields={formFields} components={components} hideSignUp>
        {({ user }) => (
          <ApolloProvider client={client}>
            <UserProvider cognitoUser={user as UserWithAttributes}>
              <ToastNoficationProvider>
                <Routes />
              </ToastNoficationProvider>
            </UserProvider>
          </ApolloProvider>
        )}
      </Authenticator>
    </HelmetProvider>
  );
}

export default App;
