import { useForm, Controller } from 'react-hook-form';
import { ApolloError } from '@apollo/client';
import { PolygonMap } from 'components';
import { CreatePartnerInput, EditPartnerInput, Partner } from '__generated__/graphql';

interface PartnerLocationMapProps {
  partner: Partner | undefined;
  onSubmit: (data: CreatePartnerInput | EditPartnerInput) => void;
  formSubmitting: boolean;
  formSubmitError: ApolloError | undefined;
}
function PartnerLocationMap({ partner, onSubmit, formSubmitting, formSubmitError }: PartnerLocationMapProps) {
  const { handleSubmit, control } = useForm<EditPartnerInput>({
    mode: 'onChange',
    defaultValues: {
      id: partner?.id,
      workingArea: partner?.workingArea,
    },
  });

  const formSubmit = (data: EditPartnerInput) => {
    onSubmit({ ...data });
  };

  return (
    <div className="mt-8 sm:mt-12">
      <div className="flex flex-col">
        <form onSubmit={handleSubmit(formSubmit)}>
          <div className="flex flex-col md:flex-row justify-between items-start gap-3 md:gap-7 mb-8 sm:mb-12">
            <div className="flex flex-col md:flex-col items-start gap-3 md:gap-7">
              <div className="md:w-40 lg:w-full text-gray-700 text-md leading-sm font-medium flex flex-row items-center">
                Click <img className="w-12 h-12 rounded-full" src="/hexegan.png" alt="create icon" />
                icon to set the area you accept jobs
              </div>

              <div className="md:w-40 lg:w-full text-gray-700 text-md leading-sm font-medium flex flex-row items-center">
                Click <img className="w-12 h-12 rounded-full" src="/edit.png" alt="edit icon" /> to change the area by dragging the shape into a new position -
                click 'save' next to the icon when you've finished
              </div>

              <div className="md:w-40 lg:w-full text-gray-700 text-md leading-sm font-medium flex flex-row items-center">
                Click <img className="w-12 h-12 rounded-full" src="/delete.png" alt="delete icon" /> to delete a working area and start again
              </div>
            </div>
            <button type="submit" disabled={formSubmitting} className="text-white bg-primary-500 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
              Save Partner Area
            </button>
          </div>

          {formSubmitError && <div className="text-error-500">{formSubmitError.message}</div>}
          <Controller
            name="workingArea"
            control={control}
            rules={{ required: 'This is required' }}
            render={({ field: { onChange, value } }) => <PolygonMap setArea={onChange} workingArea={value} />}
          />
        </form>
      </div>
    </div>
  );
}

export default PartnerLocationMap;
