import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import EmojiPeople from '@mui/icons-material/EmojiPeople';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { EditCustomerInput, RegisteredCustomerInput } from '__generated__/graphql';
import { LocationBar } from 'components';
import { useCreateCustomer } from 'api/hooks/useCustomer';
import { useToastNofication } from 'context/toastContext';
import { CustomerForm } from './CustomerForm';

function CustomerCreateForm() {
  const navigate = useNavigate();
  const { addToast } = useToastNofication();

  const { createCustomer, createCustomerLoading, createCustomerError } = useCreateCustomer();
  const onSubmit = async (input: RegisteredCustomerInput | EditCustomerInput) => {
    const address = input.address || [];
    const response = await createCustomer({
      variables: {
        input: {
          ...input,
          password: 'Testpassword@1234',
          address: {
            addressLine1: address[0]?.addressLine1 || '',
            addressLine2: address[0]?.addressLine2 || '',
            city: address[0]?.city || '',
            postcode: address[0]?.postcode || '',
            region: address[0]?.region || '',
            addressNotes: address[0]?.addressNotes,
          },
        },
      },
    });
    addToast(response, 'CreateCustomer');

    if (!createCustomerLoading && !createCustomerError) {
      navigate('/customers');
    }
  };

  return (
    <>
      <Helmet>
        <title>Customer Create</title>
      </Helmet>
      <LocationBar section="Customer" page="Customer Create Form" Icon={EmojiPeople} />
      <div className="my-10 px-4 md:px-[5%]">
        <button
          data-cy="form-detail-back"
          type="button"
          className="text-gray-500 font-semibold text-md leading-md flex items-center"
          onClick={() => {
            navigate('/customers');
          }}
        >
          <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
        </button>
        <div className="mt-10 sm:mt-16">
          <CustomerForm onSubmit={onSubmit} formSubmitting={createCustomerLoading} formSubmitError={createCustomerError} />
        </div>
      </div>
    </>
  );
}

export default CustomerCreateForm;
