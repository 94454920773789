import Toast from './Toast';

interface ToastsContainerProps {
  toasts: { message: string; type: string; id: string }[];
  removeToast: (id: string) => void;
}

function ToastsContainer({ toasts, removeToast }: ToastsContainerProps) {
  return (
    <div className="fixed top-20 start-0 z-50 gap-2 items-center justify-center py-2 px-4 min-w-fit flex flex-col-reverse w-screen">
      {toasts?.map((toast) => <Toast key={toast.id} {...toast} removeToast={removeToast} />)}
    </div>
  );
}

export { ToastsContainer };
