import { useState } from 'react';
import { Autocomplete, useLoadScript } from '@react-google-maps/api';
import type { LoadScriptProps } from '@react-google-maps/api';
import { AddressInput, ClientAddress } from '__generated__/graphql';
import { Loading } from '../Loading';
import { InputNoLabelField } from './InputField';
import { isPostcodeValid } from './validators';

interface AddressFieldProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue?: any;
  index?: number;
  showAddressNotes?: boolean;
}

function AddressField({ setValue, index = -1, showAddressNotes = false }: AddressFieldProps) {
  const [libraries] = useState<LoadScriptProps['libraries']>(['places', 'drawing', 'maps']);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    libraries,
  });

  const [searchResults, setSearchResult] = useState<google.maps.places.Autocomplete>();

  if (!isLoaded) return <Loading />;

  function onLoad(autocomplete: google.maps.places.Autocomplete) {
    setSearchResult(autocomplete);
  }

  const addressTypeAssignment = (addressComponents: google.maps.GeocoderAddressComponent[] | undefined) => {
    const generatedAddress: AddressInput | ClientAddress =
      index !== -1
        ? { addressLine1: '', addressLine2: '', city: '', region: '', postcode: '' }
        : { addressLine1: '', addressLine2: '', city: '', region: '', postcode: '', lat: 0, long: 0 };
    addressComponents?.forEach((component) => {
      const componentType = component.types[0];
      if (componentType === 'street_number' || componentType === 'premise') {
        generatedAddress.addressLine1 = component.long_name;
      } else if (componentType === 'route') {
        generatedAddress.addressLine2 = component.long_name;
      } else if (componentType === 'postal_code') {
        generatedAddress.postcode = component.short_name;
      } else if (componentType === 'postal_town') {
        generatedAddress.city = component.long_name;
      } else if (componentType === 'administrative_area_level_2') {
        generatedAddress.region = component.long_name;
      }
    });
    return generatedAddress;
  };

  const onPlaceChanged = () => {
    if (searchResults != null) {
      const addressObject = searchResults.getPlace();
      const googleAddress = addressObject.address_components;
      const generatedAddress: AddressInput | ClientAddress = addressTypeAssignment(googleAddress);

      const lat = addressObject.geometry?.location?.lat();
      const long = addressObject.geometry?.location?.lng();

      if (generatedAddress) {
        setValue(index !== -1 ? `address.${index}` : 'address', index !== -1 ? generatedAddress : { ...generatedAddress, lat, long }); // set a nested value input
      }
    }
  };

  return (
    <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad} types={['street_address', 'premise']} restrictions={{ country: 'gb' }}>
      <>
        <div className="flex flex-col md:flex-row items-start gap-3 md:gap-7">
          <div className="md:w-40 lg:w-72 text-gray-700 text-sm leading-sm font-medium">Address {index !== -1 && `${index + 1}`}</div>
          <div className="w-full lg:w-1/2">
            <InputNoLabelField name={index !== -1 ? `address.${index}.addressLine1` : 'address.addressLine1'} isRequired placeholder="Address Line 1" />
            <InputNoLabelField name={index !== -1 ? `address.${index}.addressLine2` : 'address.addressLine2'} placeholder="Address Line 2" />
            <InputNoLabelField name={index !== -1 ? `address.${index}.city` : 'address.city'} isRequired placeholder="City" />
            <InputNoLabelField name={index !== -1 ? `address.${index}.region` : 'address.region'} isRequired placeholder="Region" />
            <InputNoLabelField
              name={index !== -1 ? `address.${index}.postcode` : 'address.postcode'}
              isRequired
              placeholder="Postcode"
              validate={isPostcodeValid}
            />
            {showAddressNotes && (
              <InputNoLabelField name={index !== -1 ? `address.${index}.addressNotes` : 'address.addressNotes'} placeholder="Address Notes" />
            )}
          </div>
        </div>

        <div className="h-px w-full bg-gray-100 my-6" />
      </>
    </Autocomplete>
  );
}
export { AddressField };
