import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { CreatePartnerReviewInput } from '__generated__/graphql';
import { useCreatePartnerReview } from 'api/hooks/usePartnerReview';
import { LocationBar } from 'components';
import { useToastNofication } from 'context/toastContext';
import { PartnerReviewForm } from './PartnerReviewForm';

function PartnerReviewCreateForm() {
  const { id: partnerId } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToastNofication();

  const { createPartnerReview, createPartnerReviewLoading, createPartnerReviewError } = useCreatePartnerReview(partnerId || '');

  const onSubmit = async (input: CreatePartnerReviewInput) => {
    const response = await createPartnerReview({
      variables: {
        input,
      },
    });
    addToast(response, 'CreatePartnerReview');

    if (!createPartnerReviewLoading && !createPartnerReviewError) {
      navigate(partnerId ? `/partners/${partnerId}/reviews` : '/reviews');
    }
  };
  const onBack = () => {
    navigate(partnerId ? `/partners/${partnerId}/reviews` : '/reviews');
  };
  return (
    <>
      <Helmet>
        <title>Review Create</title>
      </Helmet>
      <LocationBar section="Partner" page="Review Create Form" Icon={RateReviewIcon} />
      <div className="my-10 px-4 md:px-[5%]">
        <button data-cy="form-detail-back" type="button" className="text-gray-500 font-semibold text-md leading-md flex items-center" onClick={onBack}>
          <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
        </button>
        <PartnerReviewForm onSubmit={onSubmit} formSubmitting={createPartnerReviewLoading} formSubmitError={createPartnerReviewError} />
      </div>
    </>
  );
}

export default PartnerReviewCreateForm;
