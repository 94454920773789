import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Calendar, Views, dateFnsLocalizer, SlotInfo } from 'react-big-calendar';
import locale from 'date-fns/locale/en-GB';
import { Add } from '@mui/icons-material';
import { format, getDay, startOfWeek, parse, getTime } from 'date-fns';
import { useCallback, useState, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { Loading } from 'components';
import { useGetAvailabilities } from 'api/hooks/useAvailability';
import { Availability } from '__generated__/graphql';
import { AddModal } from './AvailabilityDetail/modals/addModal';
import { EditModal } from './AvailabilityDetail/modals/editModal';
import { EventType } from './types';
import MobileWeek from './MobileWeek';

const localizer = dateFnsLocalizer({
  week: {
    format: 'EEEEE',
  },
  format,
  parse,
  startOfWeek,
  getDay,
  locales: { 'en-GB': locale },
});

function CustomEvent({ event }) {
  return <div data-cy={`availability-${event.testId}`}>{!isMobile && <p> {event.title} </p>}</div>;
}

function AvailabilityCalendar({ partnerId }: { partnerId: string }) {
  const { availabilities, availabilitiesLoading, refetch } = useGetAvailabilities(partnerId);

  const events: EventType[] = useMemo(() => {
    return availabilities.map(({ id: availabilityId, startDateTime: visitStartDateTime, endDateTime, recurringId, frequency }: Availability) => {
      return {
        id: availabilityId,
        title: 'Availability',
        start: new Date(visitStartDateTime),
        end: new Date(endDateTime),
        isRecurring: !!recurringId,
        recurringId,
        frequency,
        testId: `${visitStartDateTime}-${endDateTime}`,
      };
    });
  }, [availabilities]);
  const [selectedEvent, setSelectedEvent] = useState<EventType | undefined>();
  const [editModalOpen, setEditModalOpen] = useState(false);

  const [addModalOpen, setAddModalOpen] = useState(false);
  const formats = {
    dateFormat: 'dd',
    weekdayFormat: (date) => (isMobile ? format(date, 'EEEEE') : format(date, 'eee')),
    dayFormat: (date) => format(date, 'eee d'),
    monthHeaderFormat: (date) => format(date, 'MMMM yyyy'),
    dayHeaderFormat: (date) => format(date, 'd MMMM yyyy'),
  };

  const handleSelectEvent = useCallback((info: EventType) => {
    const event = {
      id: info.id,
      start: info.start,
      end: info.end,
      ...info,
    };
    setSelectedEvent(event);
    onEditModalOpen();
  }, []);

  const onSelectSlot = (slotInfo: SlotInfo) => {
    const event = {
      title: 'Availability',
      start: slotInfo.start,
      end: slotInfo.end,
    };
    setSelectedEvent(event);
    onAddModalOpen();
  };

  const onEditModalOpen = () => {
    setEditModalOpen(true);
  };
  const onAddModalOpen = () => {
    setSelectedEvent(undefined);
    setAddModalOpen(true);
  };

  const onClose = () => {
    setEditModalOpen(false);
    refetch();
  };
  const onCloseAdd = () => {
    setAddModalOpen(false);
    refetch();
  };

  const [startDateTime, setStartDate] = useState(Date.now());

  const eventPropGetter = () => {
    return { style: { color: 'white' } };
  };

  const onSelectDateRange = (date: Date | null) => {
    if (date) {
      setStartDate(getTime(date));
    }
  };
  const { views } = useMemo(
    () => ({
      views: {
        month: true,
        week: isMobile ? MobileWeek : true,
        day: true,
      },
      // ... other props
    }),
    [],
  );

  if (availabilitiesLoading) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-col md:flex-row items-start gap-3 md:gap-7">
        <div className="md:w-40 lg:w-full text-gray-700 text-md leading-sm font-medium">Set the times and days you are able to take bookings below</div>

        <button
          data-cy="availability-calendar-add-button"
          onClick={onAddModalOpen}
          type="button"
          className="text-white bg-primary-500 rounded-lg px-5 py-2 mb-8 font-semibold text-md leading-md"
        >
          <Add />
          Add Availability
        </button>
      </div>

      <Calendar
        formats={formats}
        date={new Date(startDateTime)}
        defaultDate={new Date(startDateTime)}
        min={new Date(1667973600000)}
        max={new Date(1641765600000)}
        views={views}
        components={{ event: CustomEvent }}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={onSelectSlot}
        defaultView={isMobile ? Views.DAY : Views.WEEK}
        culture="en-GB"
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ minHeight: '400px' }}
        selectable
        scrollToTime={new Date(startDateTime)}
        showAllEvents
        showMultiDayTimes
        eventPropGetter={eventPropGetter}
        onNavigate={onSelectDateRange}
      />
      {editModalOpen && selectedEvent && <EditModal partnerId={partnerId} open={editModalOpen} onClose={onClose} event={selectedEvent} />}
      {addModalOpen && <AddModal partnerId={partnerId} open={addModalOpen} event={selectedEvent} onClose={onCloseAdd} />}
    </div>
  );
}

export default AvailabilityCalendar;
