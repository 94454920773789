/* eslint-disable @typescript-eslint/no-explicit-any */
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Suspense, useMemo, useState } from 'react';
import { CellContext, createColumnHelper, ColumnDef } from '@tanstack/react-table';
import { Product } from '__generated__/graphql';
import { Loading, LocationBar, DeleteModal, Table } from 'components';
import { useDeleteProduct, useGetProducts } from 'api/hooks/useProduct';
import { useToastNofication } from 'context/toastContext';

interface RowActionProps extends CellContext<Product, unknown> {
  onDeleteModalOpen: (id: string) => void;
}

function RowActions(props: RowActionProps) {
  const navigate = useNavigate();

  return (
    <div className="flex items-center gap-3">
      <button type="button" onClick={() => navigate(`${props.row.original.id}/update`)} aria-label="Edit">
        <PencilIcon className="w-6 h-6 text-gray-500 hover:text-primary-600" />
      </button>
      <button type="button" onClick={() => props.onDeleteModalOpen(props.row.original.id)} aria-label="Delete">
        <TrashIcon className="w-6 h-6 text-gray-500 hover:text-primary-600" />
      </button>
    </div>
  );
}

function ProductList() {
  const { categoryId } = useParams();
  const navigate = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState<string | undefined>();
  const { addToast } = useToastNofication();
  const { deleteProduct, deleteProductLoading } = useDeleteProduct();

  const onDeleteClose = () => {
    setDeleteModalOpen(false);
  };
  const onDeleteModalOpen = (id: string) => {
    setDeleteModalOpen(true);
    setSelectedBookingId(id);
  };

  const onDelete = async (id: string) => {
    const response = await deleteProduct({
      variables: {
        input: { id },
      },
    });
    addToast(response, 'DeleteProduct');
    onDeleteClose();
    refetch();
  };
  const columnHelper = createColumnHelper<Product>();

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('name', {
        header: () => 'Name',
        cell: (info: { renderValue: () => any }) => info.renderValue(),
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.accessor('description', {
        header: () => 'Description',
        cell: (info: { renderValue: () => any }) => info.renderValue(),
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.accessor('categoryName', {
        header: () => 'Category Name',
        cell: (info: { renderValue: () => any }) => info.renderValue(),
        footer: (info: { column: { id: any } }) => info.column.id,
      }),

      columnHelper.display({
        id: 'actions',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (props) => <RowActions {...props} onDeleteModalOpen={onDeleteModalOpen} />,
      }),
    ] as Array<ColumnDef<Product, unknown>>;
  }, [columnHelper]);

  const { products, productsLoading, refetch } = useGetProducts(categoryId || '');
  const onConfirm = () => {
    onDelete(selectedBookingId || '');
  };
  if (productsLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Product List</title>
      </Helmet>
      <LocationBar section="Product" page="Product List" Icon={ShoppingCartIcon} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-display-lg leading-display-lg tracking-display-lg text-black font-medium">Product List</div>
          <button
            type="button"
            className="text-white bg-primary-500 rounded-lg px-5 py-2.5 font-semibold text-md leading-md hidden md:block"
            onClick={() => navigate('create')}
          >
            Add Product
          </button>
          <button
            type="button"
            aria-label="Add Product"
            className="text-white bg-primary-500 rounded-lg p-2 font-semibold text-md leading-md md:hidden"
            onClick={() => navigate('create')}
          >
            <PlusIcon className="w-5 h-5 text-white" />
          </button>
        </div>
        <Suspense fallback={<Loading />}>
          <Table<Product> csv={{ data: products, fileName: 'productList' }} data={products} columns={columns} />
        </Suspense>
      </div>
      <DeleteModal onClose={onDeleteClose} open={deleteModalOpen} onConfirm={onConfirm} isDisabled={deleteProductLoading} />
    </>
  );
}

export default ProductList;
