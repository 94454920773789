import React, { ReactNode } from 'react';
import { SizeType } from '../types';

interface ToggleButtonsProps {
  buttons: string[];
  icons?: ReactNode[];
  size?: SizeType;
  selectedButtons: string[];
  onSelectButton: (button: string) => void;
  disabled?: boolean;
  testId?: string;
}

function ToggleButtons({ buttons, icons, size, selectedButtons, onSelectButton, disabled = false, testId }: ToggleButtonsProps) {
  return (
    <div className={`flex rounded-lg border border-gray-300 ${size === SizeType.Full ? 'w-full' : size === SizeType.Half ? 'lg:w-1/2 w-full' : 'w-fit'}`}>
      {buttons.map((button, index) => {
        return (
          <button
            data-cy={`${testId}-${index}`}
            key={index}
            type="button"
            disabled={disabled}
            className={`${index !== buttons.length - 1 && 'border-r'} border-gray-300 px-4 py-2.5 text-sm leading-sm font-medium ${
              selectedButtons?.includes(button) ? 'bg-primary-500  text-white' : 'text-gray-700'
            } flex items-center w-full justify-center ${selectedButtons?.includes(button) && index === 0 && 'rounded-l-md'} ${
              selectedButtons?.includes(button) && index === buttons.length - 1 && 'rounded-r-md'
            }`}
            onClick={() => onSelectButton(button)}
          >
            {icons && icons[index]}
            {button}
          </button>
        );
      })}
    </div>
  );
}

export default ToggleButtons;
