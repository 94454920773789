import { gql, useMutation, useQuery } from '@apollo/client';
import { Category } from '__generated__/graphql';

export const CREATE_CATEGORY = gql`
  mutation CreateCategory($input: CreateCategoryInput) {
    CreateCategory(input: $input) {
      date
      message
    }
  }
`;

export const EDIT_CATEGORY = gql`
  mutation EditCategory($input: EditCategoryInput) {
    EditCategory(input: $input) {
      date
      message
    }
  }
`;

export const GET_CATEGORIES = gql`
  query GetCategories {
    GetCategories {
      id
      name
      storyblokSlug
    }
  }
`;

export const GET_CATEGORIES_BY_POSTCODE = gql`
  query GetCategoriesByPostcode($args: CategoryByPostcodeSearch) {
    GetCategoriesByPostcode(args: $args) {
      id
      name
      storyblokSlug
    }
  }
`;

export const GET_CATEGORY = gql`
  query GetCategory($args: CategorySearch) {
    GetCategory(args: $args) {
      id
      name
      storyblokSlug
    }
  }
`;

interface CategoriesResponse {
  GetCategories: Category[];
}

interface CategoriesByPostcodeResponse {
  GetCategoriesByPostcode: Category[];
}

interface CategoryResponse {
  GetCategory: Category;
}

const useEditCategory = () => {
  const [editCategory, { loading: editCategoryLoading, error: editCategoryError }] = useMutation(EDIT_CATEGORY, {
    refetchQueries: [
      {
        query: GET_CATEGORIES,
      },
    ],
  });

  return {
    editCategory,
    editCategoryLoading,
    editCategoryError,
  };
};

const useCreateCategory = () => {
  const [createCategory, { loading: createCategoryLoading, error: createCategoryError }] = useMutation(CREATE_CATEGORY, {
    refetchQueries: [
      {
        query: GET_CATEGORIES,
      },
    ],
  });

  return {
    createCategory,
    createCategoryLoading,
    createCategoryError,
  };
};

const useGetCategories = () => {
  const { loading: categoriesLoading, data, error: categoriesError, refetch } = useQuery<CategoriesResponse>(GET_CATEGORIES);

  return {
    categories: data?.GetCategories || [],
    refetch,
    categoriesLoading,
    categoriesError,
  };
};

const useGetCategoriesByPostcode = (postcode?: string) => {
  const {
    loading: categoriesLoading,
    data,
    error: categoriesError,
    refetch,
  } = useQuery<CategoriesByPostcodeResponse>(GET_CATEGORIES_BY_POSTCODE, {
    skip: !postcode,
    variables: {
      args: {
        postcode,
      },
    },
  });

  return {
    categories: data?.GetCategoriesByPostcode || [],
    refetch,
    categoriesLoading,
    categoriesError,
  };
};

const useGetCategory = (id?: string) => {
  const {
    loading: categoryLoading,
    data,
    error: categoryError,
    refetch,
  } = useQuery<CategoryResponse>(GET_CATEGORY, {
    fetchPolicy: 'network-only',
    skip: !id,
    variables: {
      args: {
        id,
      },
    },
  });

  return {
    category: data?.GetCategory || undefined,
    refetch,
    categoryLoading,
    categoryError,
  };
};

export { useCreateCategory, useEditCategory, useGetCategories, useGetCategoriesByPostcode, useGetCategory };
