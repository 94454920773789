import { gql, useQuery, useMutation } from '@apollo/client';
import { User } from '__generated__/graphql';

export const ENABLE_USER = gql`
  mutation EnableUser($input: ManageUserInput) {
    EnableUser(input: $input) {
      date
      message
    }
  }
`;

export const DEACTIVATE_USER = gql`
  mutation DisableUser($input: ManageUserInput) {
    DisableUser(input: $input) {
      date
      message
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserInput) {
    CreateUser(input: $input) {
      date
      message
    }
  }
`;

export const GET_USER = gql`
  query GetUser($args: UserSearch) {
    GetUser(args: $args) {
      id
      name
      email
      active
    }
  }
`;

export const GET_USERS = gql`
  query GetUsers($args: UserListSearch) {
    GetUsers(args: $args) {
      id
      name
      email
      active
    }
  }
`;

export const EDIT_USER = gql`
  mutation EditUser($input: EditUserInput) {
    EditUser(input: $input) {
      date
      message
    }
  }
`;

interface UsersResponse {
  GetUsers: User[];
}

interface UserResponse {
  GetUser: User;
}

const useGetUser = (id: string) => {
  const {
    loading: userLoading,
    data,
    error: userError,
    refetch,
  } = useQuery<UserResponse>(GET_USER, {
    fetchPolicy: 'network-only',
    skip: !id,
    variables: {
      args: {
        id,
      },
    },
  });

  return {
    user: data?.GetUser || undefined,
    refetch,
    userLoading,
    userError,
  };
};

const useGetUsers = (active: boolean | undefined = undefined) => {
  const {
    loading: usersLoading,
    data,
    error: usersError,
    refetch,
  } = useQuery<UsersResponse>(GET_USERS, {
    variables: {
      args: {
        active,
      },
    },
  });

  return {
    users: data?.GetUsers || [],
    refetch,
    usersLoading,
    usersError,
  };
};

const useEditUser = () => {
  const [editUser, { loading: editUserLoading, error: editUserError }] = useMutation(EDIT_USER, {
    refetchQueries: [
      {
        query: GET_USERS,
      },
    ],
  });

  return {
    editUser,
    editUserLoading,
    editUserError,
  };
};

const useDisableUser = (id?: string) => {
  const [disableUser, { loading: disableUserLoading, error: disableUserError }] = useMutation(DEACTIVATE_USER, {
    refetchQueries: [
      {
        query: GET_USER,
        variables: {
          args: {
            id,
          },
        },
      },
    ],
  });

  return {
    disableUser,
    disableUserLoading,
    disableUserError,
  };
};

const useEnableUser = (id?: string) => {
  const [enableUser, { loading: enableUserLoading, error: enableUserError }] = useMutation(ENABLE_USER, {
    refetchQueries: [
      {
        query: GET_USER,
        variables: {
          args: {
            id,
          },
        },
      },
    ],
  });

  return {
    enableUser,
    enableUserLoading,
    enableUserError,
  };
};

const useCreateUser = () => {
  const [createUser, { loading: createUserLoading, error: createUserError }] = useMutation(CREATE_USER, {
    refetchQueries: [
      {
        query: GET_USERS,
      },
    ],
  });

  return {
    createUser,
    createUserLoading,
    createUserError,
  };
};

export { useCreateUser, useEditUser, useEnableUser, useDisableUser, useGetUser, useGetUsers };
