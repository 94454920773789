/* eslint-disable @typescript-eslint/no-explicit-any */
import { Controller, useFormContext } from 'react-hook-form';
import { FormLabel } from '../FormLabel';
import { DatePicker } from '../DatePicker';

interface DateFieldProps {
  label: string;
  name: string;
  triggerName?: string;
  disabled?: boolean;
  testId?: string;
  isRequired?: boolean;
  validate?: (value: Date) => boolean | string;
}

function DateField({ label, name, triggerName, disabled = false, isRequired = false, testId, validate }: DateFieldProps) {
  const { control, trigger } = useFormContext(); // retrieve all hook methods

  return (
    <FormLabel label={label}>
      <div className="w-full lg:w-1/2">
        <Controller
          name={name}
          control={control}
          rules={{
            required: isRequired ? 'This is required ' : false,
            validate,
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <DatePicker
                data-cy={testId}
                value={value}
                onChange={(e) => {
                  onChange(e?.getTime());
                  if (triggerName) trigger(triggerName);
                }}
                disabled={disabled}
                error={error?.message}
              />
            );
          }}
        />
      </div>
    </FormLabel>
  );
}
export { DateField };
