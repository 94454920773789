import React, { useState } from 'react';
import { ImageSizeType } from '../types';
import { Tooltip } from './Tooltip';

interface AvatarProps {
  avatar: string;
  alt: string;
  size?: ImageSizeType;
  tooltip?: string;
  border?: boolean;
}

function Avatar({ avatar, alt, size, tooltip, border }: AvatarProps) {
  const [imgSrc, setImgSrc] = useState(avatar);
  return (
    <div>
      {tooltip ? (
        <Tooltip tooltip={tooltip} right>
          <img
            src={imgSrc}
            alt={alt}
            className={`${border ? 'border border-primary-500' : ''} relative rounded-full hover:z-10 ${
              size === ImageSizeType.SM
                ? 'w-6 h-6'
                : size === ImageSizeType.MD
                ? 'w-10 h-10'
                : size === ImageSizeType.LG
                ? 'w-14 h-14'
                : size === ImageSizeType.XL
                ? 'w-40 h-40'
                : 'w-8 h-8'
            }`}
            onError={() => setImgSrc('/defaultProfile.jpg')}
          />
        </Tooltip>
      ) : (
        <img
          src={imgSrc}
          alt={alt}
          className={`${border ? 'border border-primary-500' : ''} rounded-full hover:z-10 ${
            size === ImageSizeType.SM
              ? 'w-6 h-6'
              : size === ImageSizeType.MD
              ? 'w-10 h-10'
              : size === ImageSizeType.LG
              ? 'w-14 h-14'
              : size === ImageSizeType.XL
              ? 'w-40 h-40'
              : 'w-8 h-8'
          }`}
          onError={() => setImgSrc('/defaultProfile.jpg')}
        />
      )}
    </div>
  );
}

export { Avatar };
