import { XCircleIcon } from '@heroicons/react/24/outline';
import { ReactSelect } from '../Select';

export const camelCaseToNormalString = (camelCase: string) => {
  return camelCase && camelCase.length > 3 ? camelCase.replace(/([A-Z])/g, ' $1').trim() : camelCase;
};

export type SelectType = {
  selectedValue: { value: string; label: string };
  name: string;
  values: { value: string; label: string }[];
  isHidden?: boolean;
  isDisabled?: boolean;
};

interface FiltersProps {
  filters: SelectType[];
  showClearFilters?: boolean;
  onFilterChange: (name: string, value: { value: string; label: string; isDisabled?: boolean }) => void;
  onCloseModal?: () => void;
  onSaveFilters: () => void;
}

function FilterModal({ filters, showClearFilters = true, onFilterChange, onCloseModal, onSaveFilters }: FiltersProps) {
  const clearFilters = () => {
    filters.forEach((filter) => {
      onFilterChange(filter.name, { value: 'all', label: 'All' });
    });
  };
  const visibleFilter = filters.filter((filter) => !filter.isHidden);
  return (
    <>
      <div data-cy="filters-dialog" className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto mx-auto max-w-5xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6 max-h-screen md:max-h-[90vh] overflow-y-auto">
            <div className="text-gray-900 text-lg leading-lg font-semibold mb-5">Filter</div>
            {visibleFilter.map((filter, index) => {
              return (
                <div key={index} className="mb-5">
                  <div className="text-sm font-medium text-gray-700 capitalize w-auto">{camelCaseToNormalString(filter.name)}</div>
                  <div className="w-60 sm:w-96 mt-2">
                    <ReactSelect
                      testId={`filters-${index}`}
                      options={filter.values}
                      disabled={filter.isDisabled}
                      onChange={(option) => onFilterChange(filter.name, option)}
                      selectedValue={filter.selectedValue}
                      searchable={false}
                    />
                  </div>
                </div>
              );
            })}
            {showClearFilters && (
              <button
                data-cy="filters-clear"
                type="button"
                className="text-primary-700 text-sm leading-sm font-semibold flex items-center"
                onClick={clearFilters}
              >
                <XCircleIcon className="w-6 h-6 mr-2" />
                Clear all filters
              </button>
            )}
            <div className="flex justify-between items-center mt-7">
              <button data-cy="filters-cancel" type="button" className="text-gray-500 font-semibold text-md leading-md" onClick={onCloseModal}>
                Cancel
              </button>
              <button
                data-cy="filters-apply"
                type="button"
                className="text-white bg-primary-500 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
                onClick={onSaveFilters}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-gray-900" />
    </>
  );
}

export default FilterModal;
