import { useState } from 'react';
import { CreateAvailabilityInput, EditAvailabilityInput, EditAvailabilityRecurrenceInput } from '__generated__/graphql';
import { useEditAvailability, useDeleteAvailabilityRecurrence, useEditAvailabilityRecurrence, useDeleteAvailability } from 'api/hooks/useAvailability';
import { DeleteModal } from 'components/modals/DeleteModal';
import { useToastNofication } from 'context/toastContext';
import { DeleteRecurrenceModal } from './deleteModal';
import { AvailabilityForm } from '../../AvailabilityForm';
import { EventType } from '../../types';

interface EditModalProps {
  open: boolean;
  onClose: () => void;
  event: EventType;
  partnerId: string;
}
export function EditModal({ open, onClose, event, partnerId }: EditModalProps) {
  const { editAvailability, mutationEditAvailability } = useEditAvailability(partnerId || '');
  const { editAvailabilityRecurrence, mutationEditAvailabilityRecurrance } = useEditAvailabilityRecurrence(partnerId || '');
  const { deleteAvailability, mutationDeleteAvailability } = useDeleteAvailability(partnerId || '');
  const { deleteAvailabilityRecurrence, mutationDeleteAvailabilityRecurrence } = useDeleteAvailabilityRecurrence(partnerId || '');
  const { addToast } = useToastNofication();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [deleteValue, setDeleteValue] = useState('event');

  const onDeleteModalOpen = () => {
    setDeleteModalOpen(true);
  };
  const onDeleteConfirm = async () => {
    setDeleteModalOpen(false);
    onClose();
    if (deleteValue === 'event') {
      const response = await deleteAvailability({
        variables: {
          input: { id: event?.id },
        },
      });
      addToast(response, 'DeleteAvailability');
    }

    if (deleteValue === 'recurring') {
      const response = await deleteAvailabilityRecurrence({
        variables: {
          input: { recurringId: event?.recurringId },
        },
      });
      addToast(response, 'DeleteAvailabilityRecurrence');
    }
  };

  const onDeleteClose = () => {
    setDeleteModalOpen(false);
  };

  const onSubmit = async (input: EditAvailabilityRecurrenceInput | EditAvailabilityInput | CreateAvailabilityInput, isRecurring?: boolean) => {
    if (isRecurring) {
      const response = await editAvailabilityRecurrence({
        variables: {
          input,
        },
      });
      addToast(response, 'EditAvailabilityRecurrence');
      onClose();
    } else {
      const response = await editAvailability({
        variables: {
          input,
        },
      });
      addToast(response, 'EditAvailability');
      onClose();
    }
  };

  return (
    <>
      <AvailabilityForm
        onDelete={onDeleteModalOpen}
        partnerId={partnerId}
        formSubmitError={mutationEditAvailability.error || mutationEditAvailabilityRecurrance.error}
        onSubmit={onSubmit}
        formSubmitting={mutationEditAvailabilityRecurrance.loading || mutationEditAvailability.loading}
        title="Edit availability"
        onClose={onClose}
        open={open}
        availability={event}
      />
      {event.isRecurring === false && (
        <DeleteModal open={deleteModalOpen} isDisabled={mutationDeleteAvailability.loading} onClose={onDeleteClose} onConfirm={onDeleteConfirm} />
      )}
      {event.isRecurring === true && (
        <DeleteRecurrenceModal
          setDeleteValue={setDeleteValue}
          deleteValue={deleteValue}
          isDisabled={mutationDeleteAvailability.loading || mutationDeleteAvailabilityRecurrence.loading}
          open={deleteModalOpen}
          onClose={onDeleteClose}
          onConfirm={onDeleteConfirm}
        />
      )}
    </>
  );
}
