import Select from 'react-select';
import { SelectOption } from '../types';

interface ReactSelectProps {
  options: SelectOption[];
  name?: string;
  onChange: (option: SelectOption, name?: string) => void;
  selectedValue?: SelectOption;
  disabled?: boolean;
  searchable?: boolean;
  placeholder?: string;
  isContained?: boolean;
  isLoading?: boolean;
  testId?: string;
}

function NoOptionMessage() {
  return <p> No Options available </p>;
}
function ReactSelect({
  options,
  onChange,
  name,
  isContained = false,
  isLoading = false,
  selectedValue,
  disabled = false,
  searchable = true,
  placeholder,
  testId = 'react-select',
}: ReactSelectProps) {
  return (
    <Select
      options={options}
      name={name}
      placeholder={!searchable ? placeholder || 'Please select...' : ''}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#737E8C',
        },
      })}
      className={testId}
      classNamePrefix="select"
      isLoading={isLoading}
      noOptionsMessage={NoOptionMessage}
      isDisabled={disabled}
      onChange={(option, metaData) => onChange({ value: option?.value ?? '', label: option?.label ?? '', id: option?.id ?? '' }, metaData.name || '')}
      value={selectedValue || { value: '', label: '' }}
      isSearchable={searchable}
      menuPortalTarget={document.body}
      menuPosition="fixed"
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          padding: '5px 10px',
          fontSize: '16px',
          borderRadius: '8px',
          background: isContained ? '#000B77' : '',

          // Removes weird border around container
          boxShadow: isContained ? '' : '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
          hover: {
            borderColor: '#737E8C',
          },
          minWidth: 150,
        }),
        container: (base) => ({
          ...base,
          width: '100%',
        }),
        singleValue: (baseStyles) => ({
          ...baseStyles,
          color: isContained ? '#FFF' : '#454C54',
          fontWeight: 500,
          position: 'static',
          transform: 'none',
        }),
        placeholder: (baseStyles) => ({
          ...baseStyles,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          color: isContained ? '#FFF' : '#737E8C',
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        dropdownIndicator: (baseStyles) => ({
          ...baseStyles,
          color: isContained ? '#FFF' : '#737E8C',
          marginLeft: window.innerWidth > 1536 ? '50px' : '10px',
        }),
        indicatorSeparator: (baseStyles) => ({
          ...baseStyles,
          display: 'none',
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          backgroundColor: '#FFF',
          minWidth: 150,
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          backgroundColor: state.isSelected ? '#000B77' : '#FFF',
          cursor: 'pointer',
          ':hover': {
            backgroundColor: state.isSelected ? '#000B77' : '#EDF5F7',
          },
          color: state.isSelected ? '#FFF' : '#000B77',
          fontWeight: 'bold',
        }),
      }}
    />
  );
}

export { ReactSelect };
