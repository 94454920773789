import { useGetProducts } from 'api/hooks/useProduct';
import { Product } from '__generated__/graphql';
import { SelectField } from './SelectField';
import { Loading } from '../Loading';

interface ProductSelectFieldProps {
  name: string;
  isRequired: boolean;
  label: string;
  categoryId?: string;
}

function ProductSelectField({ name, isRequired, label, categoryId }: ProductSelectFieldProps) {
  const { products, productsLoading } = useGetProducts(categoryId);
  if (productsLoading) {
    return <Loading />;
  }
  const productOptions = products?.map((product: Product) => {
    return { label: product?.name, value: product?.id };
  });
  return <SelectField name={name} loading={productsLoading} isRequired={isRequired} label={label} options={productOptions} />;
}

export { ProductSelectField };
