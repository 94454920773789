import { useForm, FormProvider } from 'react-hook-form';
import { ApolloError } from '@apollo/client';
import { RRule, Frequency } from 'rrule';
import { setHours, setMinutes } from 'date-fns';
import { CreateAvailabilityInput, EditAvailabilityInput, EditAvailabilityRecurrenceInput } from '__generated__/graphql';
import { DateField, TimeField, CheckboxField, Modal, SelectField } from 'components';

interface AvailabilityFormProps {
  onSubmit: (data: CreateAvailabilityInput | EditAvailabilityInput | EditAvailabilityRecurrenceInput, isRecurring?: boolean) => void;
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  availability?: any;
  formSubmitting: boolean;
  formSubmitError: ApolloError | undefined;
  partnerId: string;
  open: boolean;
  onClose: () => void;
  onDelete?: () => void;
}

function formatFeedingFrequencyRule(rule: string) {
  const freq = rule.split(';')[0].split('=')[1].trim();
  switch (freq) {
    case 'DAILY':
      return RRule.DAILY;

    case 'WEEKLY':
      return RRule.WEEKLY;

    default:
      return RRule.MONTHLY;
  }
}

function formatUnitRule(rule: string) {
  const date = rule.split(';')[1].split('=')[1].trim();

  const year = Number(date.slice(0, 4));
  const month = Number(date.slice(4, 6)) - 1;

  const day = Number(date.slice(6, 8));

  return new Date(year, month, day);
}

function AvailabilityForm({ partnerId, onDelete, availability, title, onSubmit, open, formSubmitting, formSubmitError, onClose }: AvailabilityFormProps) {
  const frequency = availability?.frequency ? formatFeedingFrequencyRule(availability.frequency) : undefined;
  const until = availability?.frequency ? formatUnitRule(availability.frequency) : undefined;
  const methods = useForm<{ id?: string; date?: Date; endTime?: Date; startTime?: Date; until?: Date; repeat?: Frequency; isRecurring: boolean }>({
    mode: 'onBlur',
    defaultValues: {
      id: availability?.id,
      date: availability?.start ? new Date(availability?.start) : undefined,
      startTime: availability?.start ? new Date(availability?.start) : undefined,
      endTime: availability?.end ? new Date(availability?.end) : undefined,
      repeat: frequency,
      until,
      isRecurring: !!availability?.recurringId,
    },
  });
  const { handleSubmit, getValues, watch } = methods;
  const isRecurring = watch('isRecurring');

  const startTimeIsLess = (value: Date): string | boolean => {
    const endTime = getValues('endTime');
    if (!isRecurring) {
      return true;
    } else if (!value) {
      return true;
    } else if (endTime && new Date(value).getTime() < new Date(endTime)?.getTime()) {
      return true;
    } else {
      return 'Start time needs to be less than end time';
    }
  };

  const endTimeIsMore = (value: Date): string | boolean => {
    const startTime = getValues('startTime');

    if (startTime && new Date(value).getTime() > new Date(startTime)?.getTime()) {
      return true;
    } else if (!value) {
      return true;
    } else {
      return 'End time needs to be more than start time';
    }
  };

  const endDateIsMore = (value: Date): string | boolean => {
    const date = getValues('date');

    if (!isRecurring) {
      return true;
    } else if (!value) {
      return true;
    } else if (isRecurring && date && new Date(value).getTime() > new Date(date)?.getTime()) {
      return true;
    } else {
      return 'Until date needs to be more than start date';
    }
  };

  const startDateIsLess = (value: Date): string | boolean => {
    const untilValue = getValues('until');
    if (!isRecurring) {
      return true;
    } else if (!value) {
      return true;
    } else if (untilValue && new Date(value).getTime() < new Date(untilValue)?.getTime()) {
      return true;
    } else {
      return 'Start date needs to be less than until date';
    }
  };

  const availabilityFormSubmit = (data: { id?: string; date?: Date; endTime?: Date; startTime?: Date; until?: Date; repeat?: Frequency }) => {
    const newDate = data?.date ? new Date(data?.date) : undefined;
    if (newDate && data?.startTime && data?.endTime) {
      const startDateWithHours = setHours(newDate, new Date(data?.startTime)?.getHours());
      const startDateTime = setMinutes(startDateWithHours, new Date(data?.startTime)?.getMinutes()).getTime();

      const endDateWithHours = setHours(newDate, new Date(data?.endTime)?.getHours());
      const endDateTime = setMinutes(endDateWithHours, new Date(data?.endTime)?.getMinutes()).getTime();

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let newAvailability: any = {
        partnerId: partnerId || '',
        startDateTime,
        endDateTime,
      };

      if (data.id) {
        newAvailability = {
          id: data.id,
          partnerId: partnerId || '',
          startDateTime,
          endDateTime,
        };
      }
      if (isRecurring) {
        const untilDate = data.until;

        const rrule = new RRule({
          freq: data.repeat,
          dtstart: newDate,
          until: untilDate ? new Date(untilDate) : undefined,
        }).toString();

        newAvailability = {
          endDateTime,
          frequency: rrule,
          partnerId: partnerId || '',
          recurringId: availability?.recurringId,
          startDateTime,
        };
      }

      onSubmit(newAvailability, isRecurring);
      if (!formSubmitError && !formSubmitting) {
        onClose();
      }
    }
  };

  return (
    <Modal onDelete={onDelete} isDisabled={false} title={title} onConfirm={handleSubmit(availabilityFormSubmit)} open={open} onClose={onClose}>
      <FormProvider {...methods}>
        <form>
          {(!availability?.id || availability?.recurringId) && (
            <CheckboxField label={title === 'Edit availability' ? 'Edit the Recurrence' : 'Set Recurrence'} name="isRecurring" />
          )}
          {isRecurring && (
            <>
              <SelectField
                label="Repeat"
                name="repeat"
                isRequired={!!isRecurring}
                options={[
                  { label: 'Monthly', value: RRule.MONTHLY },
                  { label: 'Weekly', value: RRule.WEEKLY },
                  { label: 'Daily', value: RRule.DAILY },
                ]}
              />
              <DateField label="Date" name="date" triggerName="until" isRequired validate={startDateIsLess} />
              <DateField label="Until" name="until" triggerName="date" isRequired={!!isRecurring} validate={endDateIsMore} />
              <TimeField label="Start time" triggerName="endTime" name="startTime" isRequired validate={startTimeIsLess} />
              <TimeField label="End time" triggerName="startTime" name="endTime" isRequired validate={endTimeIsMore} />
            </>
          )}
          {!isRecurring && (
            <>
              <DateField label="Date" name="date" isRequired validate={startDateIsLess} />
              <TimeField label="Start time" testId="availability-startTime" triggerName="endTime" name="startTime" isRequired validate={startTimeIsLess} />
              <TimeField label="End time" testId="availability-endTime" triggerName="startTime" name="endTime" isRequired validate={endTimeIsMore} />
            </>
          )}
        </form>
      </FormProvider>
    </Modal>
  );
}

export { AvailabilityForm };
