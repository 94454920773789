import { useForm, FormProvider } from 'react-hook-form';
import { ApolloError } from '@apollo/client';
import { InputField, isValidPhone, CheckboxField, isValidEmail, Loading } from 'components';
import { EditEventAttendeeInput, CreateEventAttendeeInput, EventAttendee, Maybe } from '__generated__/graphql';
import { LocationMultiSelectField } from 'components/fields/LocationMultiSelect';
import { useGetCustomers } from 'api/hooks/useCustomer';

export type EventAttendeeFormInput = {
  id: string;
  firstName?: Maybe<string> | undefined;
  surname?: Maybe<string> | undefined;
  customerId?: Maybe<string> | undefined;
  locationIds?: { id: Maybe<string> }[] | undefined;
  phoneNumber?: Maybe<string> | undefined;
  email?: Maybe<string> | undefined;
};

interface EventAttendeeFormProps {
  onSubmit: (data: CreateEventAttendeeInput | EditEventAttendeeInput) => void;
  eventAttendee?: EventAttendee;
  formSubmitting: boolean;
  formSubmitError: ApolloError | undefined;
}

function EventAttendeeForm({ eventAttendee, onSubmit, formSubmitting, formSubmitError }: EventAttendeeFormProps) {
  const { customers, customersLoading } = useGetCustomers();
  const methods = useForm<EventAttendeeFormInput>({
    mode: 'onChange',
    defaultValues: {
      ...eventAttendee,
      locationIds: eventAttendee
        ? eventAttendee?.locationIds?.map((att) => {
            return { id: att };
          })
        : [],
    },
  });
  const { handleSubmit } = methods;

  function emailValidation(value: string) {
    const found = customers.find((c) => c.email === value);
    if (eventAttendee) {
      return true;
    } else if (isValidEmail(value) && !found) {
      return true;
    } else {
      return 'Customer with this email already exisits';
    }
  }
  function phoneValidation(value: string) {
    const found = customers.find((c) => c.phoneNumber === value);

    if (eventAttendee) {
      return true;
    } else if (isValidPhone(value) && !found) {
      return true;
    } else {
      return 'Customer with this phone number already exisits';
    }
  }
  const eventAttendeeFormSubmit = (data: EventAttendeeFormInput) => {
    const input = {
      ...data,
      locationIds: data ? data?.locationIds?.map((att) => att.id) : [],
    };
    onSubmit({ ...input });
  };

  if (customersLoading) return <Loading />;
  return (
    <div className="mt-8 sm:mt-12">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(eventAttendeeFormSubmit)}>
          <CheckboxField name="isTest" label="Is Test account" />
          <InputField name="firstName" isRequired label="First Name" placeholder="First Name" />
          <InputField name="surname" label="Surname" placeholder="Surname" />
          <InputField name="phoneNumber" validate={phoneValidation} label="Phone Number" placeholder="Phone Number" />
          <InputField name="email" validate={emailValidation} label="Email" placeholder="Email" />
          <LocationMultiSelectField name="locationIds" label="Locations" />
          {formSubmitError && <div className="text-error-500"> {formSubmitError.message}</div>}

          <button type="submit" disabled={formSubmitting} className="text-white bg-primary-500 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
            Save
          </button>
        </form>
      </FormProvider>
    </div>
  );
}

export { EventAttendeeForm };
