import { useGetCategories } from 'api/hooks/useCategory';
import { Category } from '__generated__/graphql';
import { SelectField } from './SelectField';
import { Loading } from '../Loading';

interface CategorySelectFieldProps {
  name: string;
  isRequired: boolean;
  label: string;
}

function CategorySelectField({ name, isRequired, label }: CategorySelectFieldProps) {
  const { categories, categoriesLoading } = useGetCategories();
  if (categoriesLoading) {
    return <Loading />;
  }
  const categoryOptions = categories.map((category: Category) => {
    return { label: category?.name || '', value: category?.id || '' };
  });
  return <SelectField name={name} isRequired={isRequired} label={label} options={categoryOptions} />;
}

export { CategorySelectField };
