import { useForm, FormProvider } from 'react-hook-form';
import { ApolloError } from '@apollo/client';
import { AddressField } from '../../components';
import { EditPartnerInput, Partner } from '../../__generated__/graphql';
import { InputField } from '../../components/fields/InputField';
import { TextAreaField } from '../../components/fields/TextAreaField';
import { isValidPhone } from '../../components/fields/validators';

interface PartnerProfileFormProps {
  onSubmit: (data: EditPartnerInput) => void;
  partner?: Partner;
  formSubmitting: boolean;
  formSubmitError: ApolloError | undefined;
}

function PartnerProfileForm({ partner, onSubmit, formSubmitting }: PartnerProfileFormProps) {
  const methods = useForm<EditPartnerInput>({
    mode: 'onChange',
    defaultValues: {
      id: partner?.id,
      image: partner?.image,
      businessName: partner?.businessName,
      firstName: partner?.firstName,
      surname: partner?.surname,
      description: partner?.description,
      phoneNumber: partner?.phoneNumber,
      address: partner?.address,
      email: partner?.email,
    },
  });

  const { handleSubmit, setValue } = methods;

  const getPostcodeLookup = async (postcode: string) => {
    const res = await fetch(`https://api.postcodes.io/postcodes/${postcode}`);
    return res.json();
  };

  const partnerFormSubmit = async (data: EditPartnerInput) => {
    const { result } = await getPostcodeLookup(data.address?.postcode || '');
    const lat = result.latitude || 0;
    const long = result.longitude || 0;

    const inputData = data.address ? { ...data, address: { ...data.address, lat, long } } : data;

    onSubmit({ ...inputData });
  };

  return (
    <div className="mt-8 sm:mt-12">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(partnerFormSubmit)}>
          <InputField name="businessName" isRequired label="Business name" placeholder="Business name" />

          <InputField name="firstName" isRequired label="Your first name" placeholder="Your first name" />
          <InputField name="surname" isRequired label="Your surname" placeholder="Your surname" />
          <TextAreaField name="description" isRequired label="Description of service" placeholder="Description of service" />
          <InputField name="phoneNumber" label="Your phone number" validate={isValidPhone} placeholder="Your phone number" isRequired type="tel" />

          <AddressField setValue={setValue} />

          <button type="submit" disabled={formSubmitting} className="text-white bg-primary-500 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
            Save
          </button>
        </form>
      </FormProvider>
    </div>
  );
}

export { PartnerProfileForm };
