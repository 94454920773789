import { Alert } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useState } from 'react';
import { useActivatePartner, useDeactivatePartner } from 'api/hooks/usePartner';
import { Maybe } from '__generated__/graphql';
import { InputNoLabelField, Modal } from 'components';
import { useToastNofication } from 'context/toastContext';

interface PartnerActivateButtonProp {
  partnerEnabled: boolean | null | undefined;
  togglePartnerEnable?: () => void;
}

interface PartnerDisableModalProp {
  id: string;
  isActive: boolean | null | undefined;
  deactivationReason?: Maybe<string> | undefined;
  open: boolean;
  onClose: () => void;
}

export function PartnerDisableModal({ open, id, onClose, isActive, deactivationReason }: PartnerDisableModalProp) {
  const [partnerEnabled, setPartnerEnabled] = useState(isActive);

  const { addToast } = useToastNofication();
  const { activatePartner, activatePartnerLoading } = useActivatePartner(id);
  const { deactivatePartner, deactivatePartnerLoading } = useDeactivatePartner(id);
  const methods = useForm<{ deactivationReason: Maybe<string> | undefined }>({
    mode: 'onSubmit',
    defaultValues: {
      deactivationReason,
    },
  });
  const { handleSubmit, setValue, watch } = methods;
  const togglePartnerEnable = async (input: { deactivationReason: Maybe<string> | undefined }) => {
    const reason = watch('deactivationReason');

    if (!id) return;

    if (isActive) {
      if (!reason) return;

      const response = await deactivatePartner({
        variables: {
          input: { id, ...input },
        },
      });
      addToast(response, 'DisablePartner');
    } else {
      const response = await activatePartner({
        variables: {
          input: { id },
        },
      });
      addToast(response, 'ActivatePartner');

      setValue('deactivationReason', null);
    }
    setPartnerEnabled(!partnerEnabled);
    onClose();
  };

  return (
    <Modal
      confirmLabel="Confirm"
      onConfirm={handleSubmit(togglePartnerEnable)}
      open={open}
      onClose={onClose}
      isDisabled={activatePartnerLoading || deactivatePartnerLoading}
    >
      <FormProvider {...methods}>
        <form>
          <svg
            className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{`Are you sure you want to ${
            partnerEnabled ? 'disable' : 'enable'
          } this partner?`}</h3>
          <div className="flex flex-col md:flex-row items-start gap-3 md:gap-7">
            {partnerEnabled && <InputNoLabelField type="text" placeholder="Deactivation Reason" name="deactivationReason" isRequired={isActive || false} />}
          </div>
          {!partnerEnabled && <Alert severity="error">Partner should only be enabled once onboarding has been completed</Alert>}
        </form>
      </FormProvider>
    </Modal>
  );
}
function PartnerActivateButton({ partnerEnabled, togglePartnerEnable }: PartnerActivateButtonProp) {
  const bgColor = partnerEnabled ? 'bg-error-500' : 'bg-success-500';

  return (
    <div className="flex flex-col md:flex-row items-start gap-3 md:gap-7 py-4">
      <button
        data-testid="toggle-partner-enable-button"
        onClick={togglePartnerEnable}
        type="button"
        className={`text-white  ${bgColor} rounded-lg px-5 py-2.5 font-semibold text-md leading-md`}
      >
        {partnerEnabled ? 'Disable partner' : 'Enable partner'}
      </button>
    </div>
  );
}

export { PartnerActivateButton };
