/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, Suspense, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { ListAlt } from '@mui/icons-material';
import { PlusIcon } from '@heroicons/react/24/outline';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { Loading, LocationBar, Table } from 'components';
import { useCompleteBooking, useGetBookings } from 'api/hooks/useBooking';
import { Booking } from '__generated__/graphql';
import { useUser } from 'context/userContext';
import { useToastNofication } from 'context/toastContext';

function TableCheckBox({ row }: { row: any }) {
  return (
    <div className="flex items-center">
      <input
        type="checkbox"
        checked={row.original.isFirstBooking || false}
        disabled={true}
        data-cy="list-new-customer"
        className="w-4 h-4 text-primary-300 bg-gray-100 border-gray-300 rounded cursor-pointer"
      />
    </div>
  );
}

function CompletedBookingCheckBox({ row }: { row: any }) {
  const { completeBooking, completeBookingLoading } = useCompleteBooking();
  const { addToast } = useToastNofication();

  const onComplete = useCallback(
    async (id: string) => {
      const response = await completeBooking({
        variables: {
          input: { id },
        },
      });
      addToast(response, 'CompleteBooking');
    },
    [completeBooking, addToast],
  );
  if (row.original.status !== 'CANCELLED') {
    return (
      <div className="flex items-center">
        <input
          type="checkbox"
          checked={row.original.isComplete || false}
          disabled={completeBookingLoading}
          onChange={() => onComplete(row.original.id || '')}
          className="w-4 h-4 text-primary-300 bg-gray-100 border-gray-300 rounded cursor-pointer"
        />
      </div>
    );
  }
}

function MyBooking() {
  const { partner } = useUser();
  const navigate = useNavigate();

  const { bookings, bookingsLoading } = useGetBookings();
  const columnHelper = createColumnHelper<Booking>();

  const columns = useMemo(() => {
    return [
      columnHelper.group({
        header: 'Customer',
        footer: (info: { column: { id: any } }) => info.column.id,
        columns: [
          columnHelper.accessor('customer', {
            header: () => ' Name',
            cell: (props) => <span data-cy="list-customer-name"> {`${props.row.original.customer.firstName} ${props.row.original.customer.surname}`}</span>,
            footer: (info: { column: { id: any } }) => info.column.id,
          }),
          columnHelper.accessor('customer', {
            header: 'Address',
            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (props) => {
              return (
                <>
                  <p>
                    <span data-cy="list-customer-addressLine1"> {props.row.original.address?.addressLine1}</span>
                  </p>
                  <p>
                    <span data-cy="list-customer-addressLine2">{props.row.original.address?.addressLine2}</span>
                  </p>
                  <p>
                    <span data-cy="list-customer-city">{props.row.original.address?.city}</span>
                  </p>
                  <p>
                    <span data-cy="list-customer-postcode">{props.row.original.address?.postcode}</span>
                  </p>
                  <p>
                    <span data-cy="list-customer-region">{props.row.original.address?.region}</span>
                  </p>
                </>
              );
            },
            footer: (info: { column: { id: any } }) => info.column.id,
          }),
          columnHelper.accessor('customer', {
            header: () => ' Email Address',
            cell: (props) => <span data-cy="list-customer-email"> {props.row.original.customer.email} </span>,
            footer: (info: { column: { id: any } }) => info.column.id,
          }),
        ],
      }),

      columnHelper.accessor('startDateTime', {
        header: () => 'Requested Booking Date',
        cell: (info) => <span data-cy="list-booking-date"> {format(info.renderValue() ?? 0, 'dd/MM/yy')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('startDateTime', {
        header: () => 'Requested Booking Time',
        cell: (props) => <span data-cy="list-booking-time"> {new Date(props.row.original.startDateTime).toLocaleTimeString('en-GB')}</span>,
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.accessor('status', {
        header: () => 'Booking Status',
        cell: (info: { renderValue: () => any }) => <span data-cy="list-booking-status"> {info.renderValue()} </span>,
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.accessor('isFirstBooking', {
        header: () => 'New Customer',
        cell: TableCheckBox,
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.accessor('isComplete', {
        header: () => 'Is Completed',
        cell: CompletedBookingCheckBox,
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
    ] as Array<ColumnDef<Booking, unknown>>;
  }, [columnHelper]);

  const rows = useMemo(() => {
    return bookings?.filter((booking: Booking) => booking.partner?.id === partner?.id);
  }, [bookings, partner?.id]);

  if (bookingsLoading) return <Loading />;

  return (
    <>
      <Helmet>
        <title>My Bookings</title>
      </Helmet>
      <LocationBar section="Partner" page="MyBookings" Icon={ListAlt} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-display-lg leading-display-lg tracking-display-lg text-black font-medium">My Bookings</div>
          <button
            type="button"
            className="text-white bg-primary-500 rounded-lg px-5 py-2.5 font-semibold text-md leading-md  md:block"
            onClick={() => navigate('create')}
          >
            Add Booking
          </button>
          <button
            type="button"
            aria-label="Add Booking"
            className="text-white bg-primary-500 rounded-lg p-2 font-semibold text-md leading-md md:hidden"
            onClick={() => navigate('create')}
          >
            <PlusIcon className="w-5 h-5 text-white" />
          </button>
        </div>
        <Suspense fallback={<Loading />}>
          <Table<Booking> csv={{ data: rows || [], fileName: 'myBookingList' }} data={rows || []} columns={columns} />
        </Suspense>
      </div>
    </>
  );
}

export default MyBooking;
