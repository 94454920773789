import { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { Image, Maybe } from '__generated__/graphql';
import { useAddImage } from 'api/hooks/useImage';
import { getFilename } from 'services/file';
import { FileInput } from '../FileInput';
import { Avatar } from '../Avatar';
import { ImageSizeType } from '../../types';

const validFileTypes = ['image/jpg', 'image/jpeg', 'image/png'];

function ErrorText({ error }: { error?: string }) {
  return <div className="text-error-500 text-left font-semibold">{error}</div>;
}

function ImageField({ onUpload, image }: { onUpload: (image: { url: string; id: string }) => void; image: Maybe<Image> | undefined }) {
  const { addImage, addImageError } = useAddImage();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(addImageError?.message);
  const [filename, setFilename] = useState<string | null>(null);

  const handleImageUpload = async (file: File) => {
    const id = uuidv4();
    const fileId = getFilename(id, 'images/', file);
    setFilename(fileId);
    setLoading(true);
    const convertToBase64 = (): Promise<string | ArrayBuffer | null> => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    };

    if (!validFileTypes.find((type) => type === file?.type)) {
      setError('File must be in JPG/PNG format');
      return;
    } else {
      setError(undefined);
    }

    const convertedFile: string | ArrayBuffer | null = await convertToBase64();

    const { data } = await addImage({
      variables: {
        input: { file: convertedFile, filename: fileId, contentType: file.type, id },
      },
    });
    await onUpload(data?.AddImage);

    setLoading(false);
  };
  const avatar = filename ? `${process.env.REACT_APP_THRIVE_CLOUDFRONT}/${filename}` : image?.url;

  return (
    <div className="flex items-center space-x-6 py-4">
      <div className="shrink-0">
        {loading ? <CircularProgress color="inherit" /> : <Avatar size={ImageSizeType.XL} avatar={avatar || '/defaultProfile.jpg'} alt="Current profile" />}
      </div>
      <FileInput handleUpload={handleImageUpload} placeholder="Choose profile photo" accept="image/*" />
      <ErrorText error={error} />
    </div>
  );
}

export { ImageField };
