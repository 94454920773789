import { useForm, FormProvider } from 'react-hook-form';
import { ApolloError } from '@apollo/client';
import { AddressField, CheckboxField, InputField, TextAreaField, isValidPhone } from 'components';
import { EditCustomerInput, Customer } from '__generated__/graphql';

interface CustmerFormProps {
  onSubmit: (data: EditCustomerInput) => void;
  customer?: Customer;
  formSubmitting: boolean;
  formSubmitError: ApolloError | undefined;
}

function CustomerForm({ customer, onSubmit, formSubmitting, formSubmitError }: CustmerFormProps) {
  const methods = useForm<EditCustomerInput>({
    mode: 'onChange',
    defaultValues: {
      id: customer?.id,
      address:
        customer?.address?.length && customer?.address?.length > 0
          ? customer?.address?.map((a) => ({
              addressLine1: a.addressLine1 || '',
              addressLine2: a.addressLine2 || '',
              city: a.city || '',
              postcode: a.postcode || '',
              region: a.region || '',
              addressNotes: a.addressNotes,
            }))
          : [
              {
                addressLine1: '',
                addressLine2: '',
                city: '',
                postcode: '',
                region: '',
                addressNotes: '',
              },
            ],
      otherInformations: customer?.otherInformations,
      firstName: customer?.firstName,
      phoneNumber: customer?.phoneNumber,
      surname: customer?.surname,
      email: customer?.email,
      isTest: customer?.isTest ? customer?.isTest : false,
    },
  });
  const { handleSubmit, setValue, watch } = methods;
  const address = watch('address');

  const customerFormSubmit = (data: EditCustomerInput) => {
    onSubmit({
      ...data,
    });
  };

  return (
    <div className="mt-8 sm:mt-12">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(customerFormSubmit)}>
          <InputField name="firstName" isRequired label="First Name" placeholder="First Name" />

          <InputField name="surname" isRequired label="Surname" placeholder="Surname" />
          <InputField name="phoneNumber" validate={isValidPhone} isRequired label="Phone Number" placeholder="Phone Number" />
          <InputField name="email" label="Email" isRequired placeholder="Email" />

          <TextAreaField name="otherInformations" label="Other information" placeholder="Other information" />
          <CheckboxField name="isTest" label="Is Test account" />

          {address?.map((_, index) => <AddressField setValue={setValue} index={index} showAddressNotes />)}
          {formSubmitError && <div className="text-error-500"> {formSubmitError.message}</div>}
          <button type="submit" disabled={formSubmitting} className="text-white bg-primary-500 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
            Save
          </button>
        </form>
      </FormProvider>
    </div>
  );
}

export { CustomerForm };
