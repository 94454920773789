import { useForm, FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import { CreateProductInput, EditProductInput, Product } from '__generated__/graphql';
import { CategorySelectField, TextAreaField, InputField } from 'components';

interface ProductFormProps {
  onSubmit: (data: CreateProductInput | EditProductInput) => void;
  product?: Product;
  formSubmitting: boolean;
  formSubmitError: ApolloError | undefined;
}

function ProductForm({ product, onSubmit, formSubmitting, formSubmitError }: ProductFormProps) {
  const { categoryId } = useParams();

  const methods = useForm<CreateProductInput | EditProductInput>({
    mode: 'onChange',
    defaultValues: {
      categoryId,
      ...product,
    },
  });
  const { handleSubmit } = methods;
  const productFormSubmit = (data: CreateProductInput | EditProductInput) => {
    onSubmit({ ...data });
  };

  return (
    <div className="mt-8 sm:mt-12">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(productFormSubmit)}>
          <CategorySelectField name="categoryId" isRequired label="Category" />
          <InputField name="name" isRequired label="Name" placeholder="Name" />
          <TextAreaField name="description" isRequired label="description" placeholder="description" />
          {formSubmitError && <div className="text-error-500"> {formSubmitError.message}</div>}

          <button type="submit" disabled={formSubmitting} className="text-white bg-primary-500 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
            Save
          </button>
        </form>
      </FormProvider>
    </div>
  );
}

export { ProductForm };
