import { Location } from '__generated__/graphql';
import { useGetLocations } from 'api/hooks/useLocation';
import { SelectField } from './SelectField';
import { Loading } from '../Loading';

interface LocationSelectFieldProps {
  name: string;
  isRequired?: boolean;
  label: string;
}

function LocationSelectField({ name, isRequired = false, label }: LocationSelectFieldProps) {
  const { locations, locationsLoading } = useGetLocations();
  if (locationsLoading) {
    return <Loading />;
  }
  const locationOptions = locations?.map((location: Location) => {
    return { label: location?.name || '', value: location?.id || '' };
  });
  return <SelectField name={name} testId={name} loading={locationsLoading} isRequired={isRequired} label={label} options={locationOptions} />;
}

export { LocationSelectField };
