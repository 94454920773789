import React, { createContext, useContext, useMemo } from 'react';
import { CognitoUser } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';
import { useGetUser } from 'api/hooks/useUser';
import { Loading, Error } from 'components';
import { client } from 'api';
import { useGetPartner } from 'api/hooks/usePartner';
import { Partner, User } from '__generated__/graphql';

export type UserWithAttributes = CognitoUser & {
  attributes: { [key: string]: string };
  signInUserSession?: {
    accessToken: {
      payload: { [key: string]: string[] };
    };
  };
};

type UserProviderProps = {
  children: React.ReactNode;
  cognitoUser: UserWithAttributes;
};

export interface UserContextProps {
  user: User | undefined;
  partner: Partner | undefined;
}

const UserContext = createContext<UserContextProps>({ user: undefined, partner: undefined });

function UserProvider({ children, cognitoUser }: UserProviderProps) {
  const userId = cognitoUser.attributes.sub;
  const { user, userLoading, userError } = useGetUser(userId);
  const { partner, partnerLoading, partnerError } = useGetPartner(userId);

  const value = useMemo(() => {
    return {
      user,
      partner,
    };
  }, [user, partner]);

  const groups = cognitoUser?.signInUserSession?.accessToken?.payload ? cognitoUser?.signInUserSession?.accessToken?.payload['cognito:groups'] : [];

  const signout = async () => {
    await Auth.signOut();
    client.resetStore();
    localStorage.clear();
    window.location.assign('/');
  };

  if (!groups || groups.length === 0 || !groups.some((group) => ['admin', 'partners'].includes(group))) {
    signout();
  }

  if (userLoading || partnerLoading) return <Loading />;
  if (userError || partnerError) return <Error />;

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

const useUser = () => {
  const context = useContext(UserContext);

  return context;
};

export { UserProvider, useUser };
