import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { CreateProductInput, EditProductInput } from '__generated__/graphql';
import { useCreateProduct } from 'api/hooks/useProduct';
import { LocationBar } from 'components/LocationBar';
import { useToastNofication } from 'context/toastContext';
import { ProductForm } from './ProductForm';

function ProductCreateForm() {
  const { id: categoryId } = useParams();
  const navigate = useNavigate();

  const { createProduct, createProductLoading, createProductError } = useCreateProduct(categoryId || '');
  const { addToast } = useToastNofication();

  const onSubmit = async (input: CreateProductInput | EditProductInput) => {
    const response = await createProduct({
      variables: {
        input,
      },
    });
    addToast(response, 'CreateProduct');

    if (!createProductLoading && !createProductError) {
      navigate(categoryId ? `/categories/${categoryId}/products` : '/products');
    }
  };

  return (
    <>
      <Helmet>
        <title>Product Create</title>
      </Helmet>
      <LocationBar section="Product" page="Product Create Form" Icon={ShoppingCartIcon} />
      <div className="my-10 px-4 md:px-[5%]">
        <button
          data-cy="form-detail-back"
          type="button"
          className="text-gray-500 font-semibold text-md leading-md flex items-center"
          onClick={() => navigate(categoryId ? `/categories/${categoryId}/products` : '/products')}
        >
          <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
        </button>
        <ProductForm onSubmit={onSubmit} formSubmitting={createProductLoading} formSubmitError={createProductError} />
      </div>
    </>
  );
}

export default ProductCreateForm;
