import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import BadgeIcon from '@mui/icons-material/Badge';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { CreateUserInput, EditUserInput } from '__generated__/graphql';
import { useCreateUser } from 'api/hooks/useUser';
import { LocationBar } from 'components';
import { useToastNofication } from 'context/toastContext';
import { UserForm } from './UserForm';

function UserCreateForm() {
  const { createUser, createUserLoading, createUserError } = useCreateUser();
  const navigate = useNavigate();
  const { addToast } = useToastNofication();

  const onSubmit = async (input: CreateUserInput | EditUserInput) => {
    const response = await createUser({
      variables: {
        input,
      },
    });
    addToast(response, 'CreateUser');

    if (!createUserLoading && !createUserError) {
      navigate('/users');
    }
  };

  return (
    <>
      <Helmet>
        <title>User Create</title>
      </Helmet>
      <LocationBar section="User" page="User Create Form" Icon={BadgeIcon} />
      <div className="my-10 px-4 md:px-[5%]">
        <button
          data-cy="form-detail-back"
          type="button"
          className="text-gray-500 font-semibold text-md leading-md flex items-center"
          onClick={() => {
            navigate('/users');
          }}
        >
          <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
        </button>
        <UserForm onSubmit={onSubmit} formSubmitting={createUserLoading} />
      </div>
    </>
  );
}

export default UserCreateForm;
