import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ReviewsIcon from '@mui/icons-material/Reviews';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { Loading, LocationBar } from 'components';
import { useEditCategory, useGetCategory } from 'api/hooks/useCategory';
import { EditCategoryInput, CreateCategoryInput } from '__generated__/graphql';
import { useToastNofication } from 'context/toastContext';
import { CategoryForm } from './CategoryForm';

function CategoryUpdateForm() {
  const { categoryId } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToastNofication();

  const { editCategory, editCategoryLoading, editCategoryError } = useEditCategory();
  const onSubmit = async (input: CreateCategoryInput | EditCategoryInput) => {
    const response = await editCategory({
      variables: {
        input,
      },
    });
    addToast(response, 'EditCategory');

    if (!editCategoryLoading && !editCategoryError) {
      navigate('/categories');
    }
  };

  const { category, categoryLoading } = useGetCategory(categoryId);

  if (categoryLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Category Update</title>
      </Helmet>
      <LocationBar section="Category" page="Category Update Form" Icon={ReviewsIcon} />
      <div className="my-10 px-4 md:px-[5%]">
        <button
          data-cy="form-detail-back"
          type="button"
          className="text-gray-500 font-semibold text-md leading-md flex items-center"
          onClick={() => navigate('/categories')}
        >
          <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
        </button>
        <CategoryForm category={category} onSubmit={onSubmit} formSubmitting={editCategoryLoading} formSubmitError={editCategoryError} />
      </div>
    </>
  );
}

export default CategoryUpdateForm;
