import { useForm, FormProvider } from 'react-hook-form';
import { CreateUserInput, EditUserInput, User } from '__generated__/graphql';
import { InputField } from 'components';

interface UserFormProps {
  onSubmit: (data: CreateUserInput | EditUserInput) => void;
  user?: User;
  formSubmitting: boolean;
}

function UserForm({ user, onSubmit, formSubmitting }: UserFormProps) {
  const methods = useForm<CreateUserInput | EditUserInput>({
    mode: 'onChange',
    defaultValues: {
      id: user?.id,
      email: user?.email,
      name: user?.name,
    },
  });
  const { handleSubmit } = methods;
  const userFormSubmit = (data: CreateUserInput | EditUserInput) => {
    onSubmit({ ...data });
  };

  return (
    <div className="mt-8 sm:mt-12">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(userFormSubmit)}>
          <InputField name="name" isRequired label="name" placeholder="name" />
          {!user?.id && <InputField name="email" isRequired label="email" placeholder="email" />}

          <button type="submit" disabled={formSubmitting} className="text-white bg-primary-500 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
            Save
          </button>
        </form>
      </FormProvider>
    </div>
  );
}

export { UserForm };
