import { gql, useMutation, useQuery } from '@apollo/client';
import { Event } from '__generated__/graphql';

export const CREATE_EVENT = gql`
  mutation CreateEvent($input: CreateEventInput) {
    CreateEvent(input: $input) {
      id
      date
      message
    }
  }
`;

export const EDIT_EVENT = gql`
  mutation EditEvent($input: EditEventInput) {
    EditEvent(input: $input) {
      date
      message
    }
  }
`;

export const GET_EVENT = gql`
  query GetEvent($args: EventSearch) {
    GetEvent(args: $args) {
      id
      name
      partnerId
      date
      locationId
      price
      duration
      bookings {
        id
        attendeeId
        customerId
        dealId
        paid
        paymentType
        attended
      }
    }
  }
`;

export const GET_EVENTS = gql`
  query GetEvents($args: EventListSearch) {
    GetEvents(args: $args) {
      id
      name
      partnerId
      date
      locationId
      price
      duration
      bookings {
        id
        attendeeId
        customerId
        dealId
        paid
        paymentType
        attended
      }
    }
  }
`;

interface EventsResponse {
  GetEvents: Event[];
}

interface EventResponse {
  GetEvent: Event;
}

export const useGetEvents = (partnerId: string | undefined = undefined) => {
  const {
    loading: eventsLoading,
    data: { GetEvents: events = [] } = {},
    error: eventsError,
    refetch,
  } = useQuery<EventsResponse>(GET_EVENTS, {
    variables: {
      args: {
        partnerId,
      },
    },
  });

  return { events, eventsLoading, eventsError, refetch };
};

export const useGetEvent = (eventId?: string) => {
  const {
    loading: eventLoading,
    data,
    error: eventError,
    refetch,
  } = useQuery<EventResponse>(GET_EVENT, {
    fetchPolicy: 'network-only',
    skip: !eventId,
    variables: {
      args: {
        id: eventId,
      },
    },
  });
  return { event: data?.GetEvent || undefined, eventLoading, eventError, refetch };
};

export const useCreateEvent = () => {
  const [createEvent, { loading: createEventLoading, error: createEventError }] = useMutation(CREATE_EVENT, {
    refetchQueries: [
      {
        query: GET_EVENTS,
      },
    ],
  });

  return {
    createEvent,
    createEventLoading,
    createEventError,
  };
};

export const useEditEvent = () => {
  const [editEvent, { loading: editEventLoading, error: editEventError }] = useMutation(EDIT_EVENT, {
    refetchQueries: [
      {
        query: GET_EVENTS,
      },
    ],
  });

  return {
    editEvent,
    editEventLoading,
    editEventError,
  };
};
