import { useForm, FormProvider } from 'react-hook-form';
import { ApolloError } from '@apollo/client';
import { Loading, SelectField, InputField } from 'components';
import { CreateCategoryInput, EditCategoryInput, Category } from '__generated__/graphql';
import { useGetCategorySlugs } from 'api/hooks/useStoryBlok';

interface CategoryFormProps {
  onSubmit: (data: CreateCategoryInput | EditCategoryInput) => void;
  category?: Category;
  formSubmitting: boolean;
  formSubmitError: ApolloError | undefined;
}

function CategoryForm({ category, onSubmit, formSubmitting, formSubmitError }: CategoryFormProps) {
  const methods = useForm<CreateCategoryInput | EditCategoryInput>({
    mode: 'onChange',
    defaultValues: {
      ...category,
    },
  });
  const { handleSubmit } = methods;
  const { categorySlugs, categorySlugsLoading } = useGetCategorySlugs();
  const categoryFormSubmit = (data: CreateCategoryInput | EditCategoryInput) => {
    onSubmit({ ...data });
  };

  if (categorySlugsLoading) {
    return <Loading />;
  }
  return (
    <div className="mt-8 sm:mt-12">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(categoryFormSubmit)}>
          <InputField name="name" isRequired label="Name" placeholder="Name" />
          <SelectField name="storyblokSlug" options={categorySlugs || []} isRequired label="Storyblok Slug" />
          {formSubmitError && <div className="text-error-500"> {formSubmitError.message}</div>}

          <button type="submit" disabled={formSubmitting} className="text-white bg-primary-500 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
            Save
          </button>
        </form>
      </FormProvider>
    </div>
  );
}

export { CategoryForm };
