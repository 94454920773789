/* eslint-disable @typescript-eslint/no-explicit-any */
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Helmet } from 'react-helmet-async';
import { PencilIcon, PlusIcon } from '@heroicons/react/24/outline';
import { Suspense, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Reviews } from '@mui/icons-material';
import { CellContext, createColumnHelper, ColumnDef } from '@tanstack/react-table';
import { Loading, LocationBar, Table } from 'components';
import { useGetCategories } from 'api/hooks/useCategory';
import { Category } from '__generated__/graphql';

function RowActions(props: CellContext<Category, unknown>) {
  const navigate = useNavigate();

  return (
    <div className="flex items-center gap-3">
      <button type="button" onClick={() => navigate(`${props.row.original.id}/products`)} aria-label="Edit">
        <ShoppingCartIcon className="w-6 h-6 text-gray-500 hover:text-primary-600" />
      </button>
      <button type="button" onClick={() => navigate(`${props.row.original.id}/update`)} aria-label="Edit">
        <PencilIcon className="w-6 h-6 text-gray-500 hover:text-primary-600" />
      </button>
    </div>
  );
}

function CategoryList() {
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<Category>();

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('id', {
        header: () => 'Id',
        cell: (info: { renderValue: () => any }) => info.renderValue(),
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.accessor('name', {
        header: () => 'Name',
        cell: (info: { renderValue: () => any }) => info.renderValue(),
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.accessor('storyblokSlug', {
        header: () => 'Storyblok slug',
        cell: (info: { renderValue: () => any }) => info.renderValue(),
        footer: (info: { column: { id: any } }) => info.column.id,
      }),

      columnHelper.display({
        id: 'actions',
        cell: RowActions,
      }),
    ] as Array<ColumnDef<Category, unknown>>;
  }, [columnHelper]);

  const { categories, categoriesLoading } = useGetCategories();

  if (categoriesLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Category List</title>
      </Helmet>
      <LocationBar section="Category" page="Category List" Icon={Reviews} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-display-lg leading-display-lg tracking-display-lg text-black font-medium">Category List</div>
          <button
            type="button"
            className="text-white bg-primary-500 rounded-lg px-5 py-2.5 font-semibold text-md leading-md hidden md:block"
            onClick={() => navigate('create')}
          >
            Add Category
          </button>
          <button
            type="button"
            aria-label="Add Category"
            className="text-white bg-primary-500 rounded-lg p-2 font-semibold text-md leading-md md:hidden"
            onClick={() => navigate('create')}
          >
            <PlusIcon className="w-5 h-5 text-white" />
          </button>
        </div>
        <Suspense fallback={<Loading />}>
          <Table<Category> csv={{ data: categories, fileName: 'categoryList' }} data={categories} columns={columns} />
        </Suspense>
      </div>
    </>
  );
}

export default CategoryList;
