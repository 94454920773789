import React, { ReactNode } from 'react';

interface InputProps {
  value: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  error?: string | ReactNode;
  type?: string;
  testId?: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  min?: string;
  step?: string;
  checked?: boolean;
  name?: string;
}

function Input({ min, name, checked, step, type = 'text', value, placeholder, startAdornment, endAdornment, onChange, disabled, error, testId }: InputProps) {
  return (
    <div className="relative w-full min-w-[200px] h-10  flex justify-end items-center">
      {startAdornment && <div className="absolute py-3 mr-2 w-5 left-3">{startAdornment}</div>}
      {endAdornment && <div className="absolute py-3 mr-2 w-5 right-12">{endAdornment}</div>}

      <input
        disabled={disabled}
        type={type}
        step={step}
        checked={checked}
        min={min}
        id={name}
        value={value || ''}
        onChange={(event) => (onChange ? onChange(event.target.value) : null)}
        className={`bg-white ${
          startAdornment ? 'px-8' : 'px-4'
        }  py-4 disabled:bg-primary-100 disabled:text-slate-500 rounded-lg text-md leading-md text-gray-700 placeholder:text-gray-500 border border-gray-300 shadow-xs w-full`}
        placeholder={placeholder ?? ''}
        data-cy={testId}
      />
      {error && (
        <p className="mt-2 text-sm text-error-600 dark:text-green-500">
          <span className="font-medium">{error}</span>
        </p>
      )}
    </div>
  );
}

export { Input };
