import { RouteObject } from 'react-router-dom';
import EventDetail from 'pages/Event/EventDetail';
import LocationList from 'pages/Location/LocationList';
import LocationCreateForm from 'pages/Location/LocationCreate';
import LocationUpdateForm from 'pages/Location/LocationUpdate';
import EventAttendeeList from 'pages/EventAttendee/EventAttendeeList';
import EventAttendeeUpdateForm from 'pages/EventAttendee/EventAttendeeUpdate';
import EventAttendeeCreateForm from 'pages/EventAttendee/EventAttendeeCreate';
import { BookingPastCreateForm } from 'pages/Booking/BookingPastCreate';
import CustomerCreateForm from 'pages/Customer/CustomerCreate';
import Dashboard from '../pages/Dashboard';
import ProtectedRoute from './ProtectedRoute';
import PartnerList from '../pages/Partner/PartnerList';
import PartnerCreate from '../pages/Partner/PartnerCreate';
import PartnerUpdateForm from '../pages/Partner/PartnerUpdate';
import PartnerReviewList from '../pages/PartnerReview/PartnerReviewList';
import PartnerReviewCreateForm from '../pages/PartnerReview/PartnerReviewCreate';
import PartnerReviewUpdateForm from '../pages/PartnerReview/PartnerReviewUpdate';
import CategoryList from '../pages/Category/CategoryList';
import CategoryUpdateForm from '../pages/Category/CategoryUpdate';
import CategoryCreateForm from '../pages/Category/CategoryCreate';
import ProductList from '../pages/Product/ProductList';
import ProductUpdateForm from '../pages/Product/ProductUpdate';
import ProductCreateForm from '../pages/Product/ProductCreate';
import EventList from '../pages/Event/EventList';
import PartnerPriceList from '../pages/PartnerPrice/PartnerPriceList';
import PartnerPriceCreateForm from '../pages/PartnerPrice/PartnerPriceCreate';
import PartnerPriceUpdateForm from '../pages/PartnerPrice/PartnerPriceUpdate';
import BookingList from '../pages/Booking/BookingList';
// import BookingStepper from '../pages/Booking/BookingCreate';
import CustomerList from '../pages/Customer/CustomerList';
import CustomerUpdateForm from '../pages/Customer/CustomerUpdate';
import UserList from '../pages/User/UserList';
import UserUpdateForm from '../pages/User/UserUpdate';
import UserCreateForm from '../pages/User/UserCreate';

export const adminRoutes: RouteObject[] = [
  { path: '/', element: <Dashboard /> },

  {
    path: '/partners',

    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <PartnerList />
          </ProtectedRoute>
        ),
      },
      {
        path: ':id',
        children: [
          {
            path: 'update',
            element: <PartnerUpdateForm />,
          },
          {
            path: 'reviews',
            children: [
              {
                index: true,
                element: (
                  <ProtectedRoute>
                    <PartnerReviewList />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'create',
                element: (
                  <ProtectedRoute>
                    <PartnerReviewCreateForm />
                  </ProtectedRoute>
                ),
              },
              {
                path: ':reviewId',
                children: [
                  {
                    path: 'update',
                    element: <PartnerReviewUpdateForm />,
                  },
                ],
              },
            ],
          },
          {
            path: 'prices',
            children: [
              {
                index: true,
                element: (
                  <ProtectedRoute>
                    <PartnerPriceList />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'create',
                element: (
                  <ProtectedRoute>
                    <PartnerPriceCreateForm />
                  </ProtectedRoute>
                ),
              },
              {
                path: ':priceId',
                children: [
                  {
                    path: 'update',
                    element: <PartnerPriceUpdateForm />,
                  },
                ],
              },
            ],
          },
          {
            path: 'events',
            children: [
              {
                path: ':eventId',
                element: <EventDetail />,
              },
            ],
          },
        ],
      },
      {
        path: 'create',
        element: (
          <ProtectedRoute>
            <PartnerCreate />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: '/bookings',

    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <BookingList />
          </ProtectedRoute>
        ),
      },
      // {
      //   path: 'create',
      //   element: (
      //     <ProtectedRoute>
      //       <BookingStepper />
      //     </ProtectedRoute>
      //   ),
      // },
      {
        path: 'create',
        element: (
          <ProtectedRoute>
            <BookingPastCreateForm />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: '/reviews',
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <PartnerReviewList />
          </ProtectedRoute>
        ),
      },
      {
        path: ':reviewId',
        children: [
          {
            path: 'update',
            element: <PartnerReviewUpdateForm />,
          },
        ],
      },
      {
        path: 'create',
        element: (
          <ProtectedRoute>
            <PartnerReviewCreateForm />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: '/categories',
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <CategoryList />
          </ProtectedRoute>
        ),
      },
      {
        path: ':categoryId',
        children: [
          {
            path: 'update',
            element: <CategoryUpdateForm />,
          },
          {
            path: 'products',
            children: [
              {
                index: true,
                element: (
                  <ProtectedRoute>
                    <ProductList />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'create',
                element: (
                  <ProtectedRoute>
                    <ProductCreateForm />
                  </ProtectedRoute>
                ),
              },
              {
                path: ':productId',
                children: [
                  {
                    path: 'update',
                    element: <ProductUpdateForm />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'create',
        element: (
          <ProtectedRoute>
            <CategoryCreateForm />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: '/products',
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <ProductList />
          </ProtectedRoute>
        ),
      },
      {
        path: ':productId',
        children: [
          {
            path: 'update',
            element: <ProductUpdateForm />,
          },
        ],
      },
      {
        path: 'create',
        element: (
          <ProtectedRoute>
            <ProductCreateForm />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: '/prices',
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <PartnerPriceList />
          </ProtectedRoute>
        ),
      },
      {
        path: ':priceId',
        children: [
          {
            path: 'update',
            element: <PartnerPriceUpdateForm />,
          },
        ],
      },
      {
        path: 'create',
        element: (
          <ProtectedRoute>
            <PartnerPriceCreateForm />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: '/customers',
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <CustomerList />
          </ProtectedRoute>
        ),
      },
      {
        path: ':customerId',
        children: [
          {
            path: 'update',
            element: <CustomerUpdateForm />,
          },
        ],
      },
      {
        path: 'create',
        element: (
          <ProtectedRoute>
            <CustomerCreateForm />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: '/users',
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <UserList />
          </ProtectedRoute>
        ),
      },
      {
        path: 'create',
        element: (
          <ProtectedRoute>
            <UserCreateForm />
          </ProtectedRoute>
        ),
      },
      {
        path: ':userId',
        children: [
          {
            path: 'update',
            element: <UserUpdateForm />,
          },
        ],
      },
    ],
  },
  {
    path: '/events',

    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <EventList />
          </ProtectedRoute>
        ),
      },
      {
        path: ':eventId',
        element: (
          <ProtectedRoute>
            <EventDetail />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: '/locations',
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <LocationList />
          </ProtectedRoute>
        ),
      },
      {
        path: ':locationId',
        children: [
          {
            path: 'update',
            element: <LocationUpdateForm />,
          },
        ],
      },
      {
        path: 'create',
        element: (
          <ProtectedRoute>
            <LocationCreateForm />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: '/event-attendees',
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <EventAttendeeList />
          </ProtectedRoute>
        ),
      },
      {
        path: ':eventAttendeeId',
        children: [
          {
            path: 'update',
            element: <EventAttendeeUpdateForm />,
          },
        ],
      },
      {
        path: 'create',
        element: (
          <ProtectedRoute>
            <EventAttendeeCreateForm />
          </ProtectedRoute>
        ),
      },
    ],
  },
];
