/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, Suspense, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import HandshakeIcon from '@mui/icons-material/Handshake';
import { PencilIcon, PlusIcon, CurrencyPoundIcon, ChatBubbleLeftEllipsisIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { ColumnDef, createColumnHelper, CellContext } from '@tanstack/react-table';
import { DeleteModal, Loading, LocationBar, Table } from 'components';
import { useGetPartners, useDeletePartner } from 'api/hooks/usePartner';
import { Partner } from '__generated__/graphql';
import { useToastNofication } from 'context/toastContext';

interface RowActionProps extends CellContext<Partner, unknown> {
  onDeleteModalOpen: (id: string) => void;
}

function DBSCell({ row }: { row: any }) {
  if (row?.original.dbsChecked && row?.original.dbsExpiryDate > new Date().getTime()) {
    return <div className="bg-success-50 border border-success-700 rounded-2xl text-success-700 text-sm leading-sm font-medium px-2.5 py-0.5 w-fit">VALID</div>;
  } else {
    return <div className="bg-error-50 border border-error-700 rounded-2xl text-error-700 text-sm leading-sm font-medium px-2.5 py-0.5 w-fit">EXPIRED</div>;
  }
}
function TableCheckBox({ checked }: { checked: boolean }) {
  return (
    <div className="flex items-center">
      <input
        type="checkbox"
        checked={checked || false}
        disabled={true}
        className="w-4 h-4 text-primary-300 bg-gray-100 border-gray-300 rounded cursor-pointer"
      />
    </div>
  );
}

function RowActions(props: RowActionProps) {
  const navigate = useNavigate();

  return (
    <div className="flex items-center gap-3">
      <button type="button" onClick={() => navigate(`${props.row.original.id}/prices`)} aria-label="Edit">
        <CurrencyPoundIcon className="w-6 h-6 text-gray-500 hover:text-primary-600" />
      </button>
      <button type="button" onClick={() => navigate(`${props.row.original.id}/reviews`)} aria-label="Review">
        <ChatBubbleLeftEllipsisIcon className="w-6 h-6 text-gray-500 hover:text-primary-600" />
      </button>
      <button type="button" onClick={() => navigate(`${props.row.original.id}/update`)} aria-label="Edit">
        <PencilIcon className="w-6 h-6 text-gray-500 hover:text-primary-600" />
      </button>
      <button type="button" onClick={() => props.onDeleteModalOpen(props.row.original.id)} aria-label="Delete">
        <TrashIcon className="w-6 h-6 text-gray-500 hover:text-primary-600" />
      </button>
    </div>
  );
}
function PartnerList() {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const { deletePartner, deletePartnerLoading } = useDeletePartner();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { addToast } = useToastNofication();
  const [selectedPartnerId, setSelectedPartnerId] = useState<string | undefined>();
  const { partners, partnersLoading, refetch } = useGetPartners();

  const onDeleteModalOpen = (id: string) => {
    setDeleteModalOpen(true);
    setSelectedPartnerId(id);
  };

  const onDelete = async (id: string) => {
    const response = await deletePartner({
      variables: {
        input: { id },
      },
    });
    addToast(response, 'DeletePartnerReview');
    refetch();
    onDeleteClose();
  };
  const onConfirm = () => {
    onDelete(selectedPartnerId || '');
    onDeleteClose();
  };

  const onDeleteClose = () => {
    setDeleteModalOpen(false);
  };
  const columnHelper = createColumnHelper<Partner>();

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('firstName', {
        header: () => 'First Name',
        cell: (info) => {
          const imageHash = Date.now();
          return (
            <div className="flex items-center font-medium text-gray-900 md:whitespace-nowrap dark:text-white">
              <img
                className="w-12 h-12 rounded-full"
                src={info.row.original.image?.url ? `${info.row.original.image?.url}?${imageHash}` : '/defaultProfile.jpg'}
                alt={`Partner ${info.row.original.businessName}`}
              />
              <div className="ps-3">
                <div className="text-md leading-md text-gray-900 font-semibold">
                  {info.renderValue()} {info.row.original.surname}
                </div>
                <div data-cy="list-email" className="font-normal text-gray-500 hidden md:block">
                  {info.row.original.email}
                </div>
              </div>
            </div>
          );
        },
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.accessor('businessName', {
        header: () => 'Business Name',
        cell: (info: { renderValue: () => any }) => info.renderValue(),
        footer: (info: { column: { id: any } }) => info.column.id,
      }),

      columnHelper.accessor('isActive', {
        header: () => 'Is Active',
        cell: (props) => <TableCheckBox checked={props.row.original.isActive || false} />,
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.accessor('isTest', {
        header: () => 'Is Test',
        cell: (props) => <TableCheckBox checked={props.row.original.isTest || false} />,
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.accessor('hubspotId', {
        header: () => 'Hubspot Connected',
        cell: (props) => <TableCheckBox checked={!!props.row.original.hubspotId || false} />,
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.accessor('dbsExpiryDate', {
        header: () => 'DBS status',
        cell: DBSCell,
        footer: (info: { column: { id: any } }) => info.column.id,
      }),

      columnHelper.display({
        id: 'actions',
        cell: (props) => <RowActions {...props} onDeleteModalOpen={onDeleteModalOpen} />,
      }),
    ] as Array<ColumnDef<Partner, unknown>>;
  }, [columnHelper]);

  const filterByName = useCallback(
    (partner: Partner) => {
      if (partner && partner.firstName?.toLowerCase().trim().includes(search.trim().toLowerCase())) return true;
      if (partner && partner.surname?.toLowerCase().trim().includes(search.trim().toLowerCase())) return true;
      if (partner && `${partner.firstName} ${partner.surname}`?.toLowerCase().includes(search.trim().toLowerCase())) return true;

      return false;
    },
    [search],
  );

  const filteredPartners = useMemo(() => {
    return partners.filter((sw) => filterByName(sw));
  }, [partners, filterByName]);

  const csv = useMemo(() => {
    return filteredPartners?.map((b) => {
      return {
        ...b,
        image: JSON.stringify(b.image).replace(/"/g, "'"),
      };
    });
  }, [filteredPartners]);

  if (partnersLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Partner List</title>
      </Helmet>
      <LocationBar section="Partner" page="Partner List" Icon={HandshakeIcon} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-display-lg leading-display-lg tracking-display-lg text-black font-medium">Partner List</div>
          <button
            type="button"
            className="text-white bg-primary-500 rounded-lg px-5 py-2.5 font-semibold text-md leading-md hidden md:block"
            onClick={() => navigate('create')}
          >
            Add Partner
          </button>
          <button
            type="button"
            aria-label="Add partner"
            className="text-white bg-primary-500 rounded-lg p-2 font-semibold text-md leading-md md:hidden"
            onClick={() => navigate('create')}
          >
            <PlusIcon className="w-5 h-5 text-white" />
          </button>
        </div>
        <Suspense fallback={<Loading />}>
          <Table<Partner> search={search} setSearch={setSearch} csv={{ data: csv, fileName: 'partnerList' }} data={filteredPartners} columns={columns} />
        </Suspense>
      </div>
      <DeleteModal onClose={onDeleteClose} open={deleteModalOpen} onConfirm={onConfirm} isDisabled={deletePartnerLoading} />
    </>
  );
}

export default PartnerList;
