import { ISbStoryData, getStoryblokApi } from '@storyblok/react';
import { useState, useEffect } from 'react';

interface BlokQuestion {
  answers: string[];
  areMultiAnswersAccepted: boolean;
  component: string;
  question: string;
  _uid: string;
  _editable: string;
}

const useGetCategorySlugs = () => {
  const storyblokApi = getStoryblokApi();
  const [categorySlugs, setCategorySlugs] = useState<{ value: string; label: string }[]>();
  const [categorySlugsLoading, setIsLoading] = useState(false);
  const [categorySlugsError, setCategorySlugsError] = useState<string>('');

  useEffect(() => {
    async function getCategorySlugs() {
      setIsLoading(true);
      try {
        const { data: questionData } = await storyblokApi.get('cdn/stories/', {
          version: 'draft',
          filter_query: {
            component: {
              in: 'categoryQuestions',
            },
          },
        });

        const storyblokQuestions: { value: string; label: string }[] = questionData?.stories?.map((tt: ISbStoryData) => {
          return { value: tt.slug, label: tt.slug };
        });

        setCategorySlugs(storyblokQuestions);
        setIsLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setCategorySlugsError(error.message);
        setCategorySlugs([]);
        setIsLoading(false);
      }
    }

    if (!categorySlugs) {
      getCategorySlugs();
    }
  }, [categorySlugs, storyblokApi]);

  return { categorySlugs, categorySlugsLoading, categorySlugsError };
};

const useGetQuestions = (storyblokSlug: string) => {
  const storyblokApi = getStoryblokApi();
  const [questions, setQuestions] = useState<BlokQuestion[]>();
  const [questionsLoading, setIsLoading] = useState(false);
  const [questionsError, setQuestionsError] = useState<string>('');

  useEffect(() => {
    async function getQuestions() {
      setIsLoading(true);
      try {
        const { data: questionData } = await storyblokApi.get(`cdn/stories/questions/${storyblokSlug}`, {
          version: 'draft',
        });
        setQuestions(questionData.story.content.preQualifyingQuestions || []);
        setIsLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setQuestionsError(error.message);
        setQuestions([]);
        setIsLoading(false);
      }
    }

    if (!questions) {
      getQuestions();
    }
  }, [questions, storyblokSlug, storyblokApi]);

  return { questions, questionsLoading, questionsError };
};

export { useGetCategorySlugs, useGetQuestions };
