import { createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom';
import { NotFound } from 'components';
import { Layout } from 'components/Layout2';
import { adminRoutes } from './adminRoutes';
import { publicRoutes } from './publicRoutes';
import { devRoutes } from './devRoutes';

const routes: RouteObject[] = [
  {
    element: <Layout />,
    children: [...publicRoutes, ...adminRoutes, ...devRoutes, { path: '*', element: <NotFound /> }],
  },
];

const router = createBrowserRouter(routes);

function Routes() {
  return <RouterProvider router={router} />;
}

export default Routes;
