import { RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { ChangeEvent, SetStateAction, Dispatch } from 'react';
import { Modal } from 'components';

interface DeleteRecurranceModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  setDeleteValue: Dispatch<SetStateAction<string>>;
  deleteValue: string;
  isDisabled: boolean;
}

export function DeleteRecurrenceModal({ open, onClose, onConfirm, setDeleteValue, deleteValue, isDisabled }: DeleteRecurranceModalProps) {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDeleteValue((event.target as HTMLInputElement).value);
  };
  return (
    <Modal onConfirm={onConfirm} open={open} onClose={onClose} title="Delete" isDisabled={isDisabled} confirmLabel="Yes">
      Delete recurring event
      <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={deleteValue} name="radio-buttons-group" onChange={handleChange}>
        <FormControlLabel value="event" control={<Radio />} label="This Event" />
        <FormControlLabel value="recurring" control={<Radio />} label="All Events" />
      </RadioGroup>
    </Modal>
  );
}
