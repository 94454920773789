/* eslint-disable @typescript-eslint/no-explicit-any */
import { useParams, useNavigate } from 'react-router-dom';
import { PencilIcon, PlusIcon } from '@heroicons/react/24/outline';
import { Helmet } from 'react-helmet-async';
import { CellContext, ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { CurrencyPound } from '@mui/icons-material';
import { Partner, PartnerPrice } from '__generated__/graphql';
import { Loading, LocationBar, Table } from 'components';
import { useGetPartnerPrices } from 'api/hooks/usePartnerPrice';
import { useGetPartners } from 'api/hooks/usePartner';

export type SelectType = {
  selectedValue: { value: string; label: string };
  name: string;
  values: { value: string; label: string }[];
  isHidden?: boolean;
  isDisabled?: boolean;
};

function RowActions(props: CellContext<PartnerPrice, unknown>) {
  const navigate = useNavigate();

  return (
    <div className="flex items-center gap-3">
      <button type="button" onClick={() => navigate(`${props.row.original.id}/update`)} aria-label="Edit">
        <PencilIcon className="w-6 h-6 text-gray-500 hover:text-primary-600" />
      </button>
    </div>
  );
}

function PartnerPriceList() {
  const { id: partnerId } = useParams();
  const navigate = useNavigate();
  const { partners, partnersLoading } = useGetPartners();

  const partnerOptions = partners.map((partner: Partner) => {
    return { label: partner.businessName, value: partner?.id };
  });
  const [filters, setFilters] = useState<SelectType[]>([
    { name: 'partner', selectedValue: { value: 'all', label: 'All' }, values: [{ value: 'all', label: 'All' }], isDisabled: !!partnerId },
  ]);
  useEffect(() => {
    filters[0].values = [{ value: 'all', label: 'All' }, ...partnerOptions];

    setFilters(filters);
  }, [filters, partnerOptions]);

  const columnHelper = createColumnHelper<PartnerPrice>();

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('partnerBusinessName', {
        header: () => 'Partner',
        cell: (info: { renderValue: () => any }) => info.renderValue(),
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.accessor('productName', {
        header: () => 'Product Name',
        cell: (info: { renderValue: () => any }) => info.renderValue(),
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.accessor('bookingPrice', {
        header: () => ' Booking Price / £',
        cell: (info: { renderValue: () => any }) => info.renderValue(),
        footer: (info: { column: { id: any } }) => info.column.id,
      }),

      columnHelper.accessor('basePrice', {
        header: () => 'Base Price / £',
        cell: (info: { renderValue: () => any }) => info.renderValue(),
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.accessor('markup', {
        header: () => 'Markup / £',
        cell: (info: { renderValue: () => any }) => info.renderValue(),
        footer: (info: { column: { id: any } }) => info.column.id,
      }),

      columnHelper.accessor('duration', {
        header: () => 'Duration / mins',
        cell: (info: { renderValue: () => any }) => info.renderValue(),
        footer: (info: { column: { id: any } }) => info.column.id,
      }),

      columnHelper.display({
        id: 'actions',
        cell: RowActions,
      }),
    ] as Array<ColumnDef<PartnerPrice, unknown>>;
  }, [columnHelper]);

  const { partnerPrices, partnerPricesLoading } = useGetPartnerPrices(partnerId || '');
  const filterPriceByPartner = (price: PartnerPrice) => {
    if (filters[0].selectedValue.value === 'all') {
      return true;
    } else if (price.partnerId === filters[0].selectedValue.value) {
      return true;
    }
    return false;
  };

  const filteredPrice = partnerPrices.filter((price: PartnerPrice) => filterPriceByPartner(price));

  if (partnerPricesLoading || partnersLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Partner Price List</title>
      </Helmet>
      <LocationBar section="Partner" page="Price List" Icon={CurrencyPound} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-display-lg leading-display-lg tracking-display-lg text-black font-medium">Price List</div>
          <button
            type="button"
            className="text-white bg-primary-500 rounded-lg px-5 py-2.5 font-semibold text-md leading-md hidden md:block"
            onClick={() => navigate('create')}
          >
            Add Price
          </button>
          <button
            type="button"
            aria-label="Add price"
            className="text-white bg-primary-500 rounded-lg p-2 font-semibold text-md leading-md md:hidden"
            onClick={() => navigate('create')}
          >
            <PlusIcon className="w-5 h-5 text-white" />
          </button>
        </div>
        <Suspense fallback={<Loading />}>
          <Table<PartnerPrice>
            setFilters={setFilters}
            filters={filters}
            csv={{ data: filteredPrice, fileName: 'partnerPriceList' }}
            data={filteredPrice}
            columns={columns}
          />
        </Suspense>
      </div>
    </>
  );
}

export default PartnerPriceList;
