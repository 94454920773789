import { Controller, useFormContext } from 'react-hook-form';
import ReactMultiSelect from 'components/MultiSelect';
import { SelectOption } from '../../types';
import { FormLabel } from '../FormLabel';

interface MultiSelectFieldProps {
  label: string;
  name: string;
  options: SelectOption[];
  disabled?: boolean;
  loading?: boolean;
  searchable?: boolean;
  testId?: string;
  isRequired?: boolean;
}

function MultiSelectField({ label, name, options, disabled = false, loading = false, searchable, testId, isRequired }: MultiSelectFieldProps) {
  const { control } = useFormContext();

  return (
    <FormLabel label={label}>
      <div className="w-full lg:w-1/2">
        <Controller
          name={name}
          control={control}
          rules={{
            required: isRequired ? 'This is required' : false,
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            const values = value?.map((v) => v.id);
            const selectedValues = options?.filter((option) => values?.includes(option.value));
            return (
              <>
                <ReactMultiSelect
                  testId={testId}
                  isLoading={loading}
                  disabled={disabled}
                  options={options}
                  onChange={(e) => {
                    const events = e.map((i) => {
                      return { id: i.value };
                    });
                    onChange(events);
                  }}
                  selectedValue={selectedValues}
                  searchable={searchable}
                />
                <p className="mt-2 text-sm text-error-600 dark:text-green-500">
                  <span className="font-medium">{error?.message}</span>
                </p>
              </>
            );
          }}
        />
      </div>
    </FormLabel>
  );
}

export { MultiSelectField };
