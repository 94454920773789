import { useState, SetStateAction, Dispatch, useRef } from 'react';
import { ColumnDef, SortingState, VisibilityState, getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { useReactToPrint } from 'react-to-print';
import { SelectOption } from '../../types';
import FilterModal, { SelectType } from './FilterModal';
import { TablePagination } from './TablePagination';
import { TableFilterPanel } from './TableFilterPanel';
import { TableContent } from './TableContent';

interface TableProps<TableType> {
  columns: ColumnDef<TableType>[];
  data: TableType[];
  columnVisibility?: VisibilityState;
  search?: string;
  setSearch?: Dispatch<SetStateAction<string>>;
  filters?: SelectType[];
  hidePrint?: boolean;
  setFilters?: Dispatch<SetStateAction<{ name: string; selectedValue: SelectOption; values: SelectOption[]; isDisabled?: boolean }[]>>;
  csv?: { data: string | object[] | (() => string | object[]); fileName: string };
  toggleButtonKey?: string;
  dateFilter?: Date | undefined | null;
  setDateFilter?: (date: Date | undefined | null) => void;
}

function Table<TableType>({
  columns,
  data = [] as TableType[],
  columnVisibility,
  search,
  setSearch,
  filters,
  setFilters,
  hidePrint = false,
  csv,
  toggleButtonKey,
  dateFilter,
  setDateFilter,
}: TableProps<TableType>) {
  const onSaveFilters = () => {
    setShowFiltersModal(false);
  };

  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);

  const onFilterChange = (name: string, value: SelectOption) => {
    const newFilters = filters ? [...filters] : [];
    const filterIndex = newFilters.findIndex((f) => f.name === name);
    newFilters[filterIndex].selectedValue = value;
    if (setFilters) setFilters(newFilters);
  };
  const filterWithoutToggle = filters?.filter((f) => f.name !== toggleButtonKey);

  const tableRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    initialState: {
      columnVisibility: columnVisibility ?? {},
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      <TableFilterPanel
        search={search}
        setSearch={setSearch}
        filters={filters}
        hidePrint={hidePrint}
        filterWithoutToggle={filterWithoutToggle || []}
        csv={csv}
        toggleButtonKey={toggleButtonKey}
        setShowFiltersModal={setShowFiltersModal}
        onFilterChange={onFilterChange}
        handlePrint={handlePrint}
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
      />
      <div className="mt-3 md:mt-12 mb-10 overflow-x-auto overflow-y-hidden">
        <div ref={tableRef} className="mt-3 md:mt-12 mb-10">
          <div className="mt-2 flex flex-col">
            <div className="-my-2">
              <div className="py-2 align-middle inline-block min-w-full">
                <TableContent<TableType> table={table} noResults={data?.length === 0} />
                <TablePagination
                  isNextDisabled={!table.getCanNextPage()}
                  isPreviousDisabled={!table.getCanPreviousPage()}
                  onClickPrevious={table.previousPage}
                  onClickNext={table.nextPage}
                  pageCount={table.getPageCount()}
                  pageIndex={table.getState().pagination.pageIndex}
                  setIndex={table.setPageIndex}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {showFiltersModal && (
        <FilterModal
          filters={filterWithoutToggle || []}
          onFilterChange={onFilterChange}
          onCloseModal={() => setShowFiltersModal(false)}
          onSaveFilters={onSaveFilters}
        />
      )}
    </>
  );
}

export { Table };
