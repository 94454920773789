import { Button, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Loading, adminSection } from 'components';
import { useIsPartner } from 'hooks/authHooks';
import PartnerProfile from '../PartnerProfile';

function AdminDashboard() {
  const navigate = useNavigate();
  return (
    <div className="content">
      <main>
        <Grid container>
          <Grid container direction="row" spacing={1} padding={3} justifyContent="center">
            <img width="50" src="/logo-t.png" alt="Bellevie Care" data-testid="logo" />
            <br />
            <Typography variant="h4" color="primary">
              Thrive
            </Typography>
            <Typography variant="h4"> with Bellevie</Typography>
          </Grid>
          <Grid container direction="row" justifyContent="center" padding={3} spacing={1}>
            {adminSection.map(({ text, icon, link, testId }) => {
              return (
                <Grid item xs={8} md={3} key={text}>
                  <Button
                    style={{ color: '#1976d2', width: '150px', padding: '5px' }}
                    startIcon={icon}
                    variant="outlined"
                    data-testid={testId}
                    onClick={() => {
                      navigate(link);
                    }}
                  >
                    {text}
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </main>
    </div>
  );
}

function Dashboard() {
  const { isAuthorised, isLoading } = useIsPartner();
  if (isLoading) return <Loading />;
  return isAuthorised ? <PartnerProfile /> : <AdminDashboard />;
}
export default Dashboard;
