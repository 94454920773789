import React from 'react';
import { CalendarDaysIcon } from '@heroicons/react/24/outline';
import {
  BaseSingleInputFieldProps,
  DateValidationError,
  FieldSection,
  LocalizationProvider,
  DatePicker as MUIDatePicker,
  DatePickerProps,
  UseDateFieldProps,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';

interface TWDatePickerProps {
  value: Date | undefined | null;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  onChange?: (date: Date | null) => void;
  error?: string;
}

interface ButtonFieldProps extends UseDateFieldProps<Date>, BaseSingleInputFieldProps<Date | null, Date, FieldSection, DateValidationError> {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

function ButtonField(props: ButtonFieldProps) {
  const { setOpen, value, id, disabled, InputProps: { ref } = {}, inputProps: { 'aria-label': ariaLabel } = {} } = props;

  return (
    <button
      data-cy="date-picker"
      type="button"
      className={`rounded-lg border border-gray-300 shadow-xs flex place-items-center px-3.5 py-2.5 w-full ${disabled ? 'text-gray-300' : 'text-gray-500'}`}
      onClick={() => {
        if (setOpen) {
          setOpen((prev) => !prev);
        }
      }}
      id={id}
      disabled={disabled}
      ref={ref}
      aria-label={ariaLabel}
    >
      <CalendarDaysIcon
        className="w-5 h-5 mr-2"
        onClick={() => {
          if (setOpen) {
            setOpen((prev) => !prev);
          }
        }}
      />
      {value ? format(value ?? 0, 'EEEE dd MMM yyyy') : 'Pick a date'}
    </button>
  );
}

function ButtonDatePicker(props: Omit<DatePickerProps<Date>, 'open' | 'onOpen' | 'onClose'>) {
  const [open, setOpen] = React.useState(false);

  return (
    <MUIDatePicker
      slots={{ field: ButtonField, ...props.slots }}
      slotProps={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        field: { setOpen, clearable: true } as any,
        actionBar: {
          actions: ['clear', 'accept'],
        },
      }}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  );
}

function DatePicker({ value, minDate, maxDate, disabled = false, onChange, error }: TWDatePickerProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ButtonDatePicker value={value} onChange={onChange} minDate={minDate} maxDate={maxDate} disabled={disabled} />
      {error && (
        <p className="mt-2 text-sm text-error-600 dark:text-green-500">
          <span className="font-medium">{error}</span>
        </p>
      )}
    </LocalizationProvider>
  );
}

export { DatePicker };
