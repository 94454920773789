import { ReactNode } from 'react';

interface TooltipProps {
  tooltip: string;
  children: ReactNode;
  right?: boolean;
}

function Tooltip({ tooltip, children, right }: TooltipProps) {
  return (
    <div className="group relative w-max flex justify-center">
      {children}
      <span
        className={`pointer-events-none absolute -top-8 ${
          right ? 'right-0' : 'left-0'
        } w-max opacity-0 transition-opacity group-hover:opacity-100 bg-gray-900 text-white rounded-lg px-2 py-0.5`}
      >
        {tooltip}
      </span>
    </div>
  );
}

export { Tooltip };
