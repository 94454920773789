/* eslint-disable @typescript-eslint/no-explicit-any */
import { Controller, useFormContext } from 'react-hook-form';
import { FormLabel } from '../FormLabel';
import { TimePicker } from '../TimePicker';

interface TimeFieldProps {
  label: string;
  name: string;
  triggerName: string;
  disabled?: boolean;
  testId?: string;
  isRequired?: boolean;
  validate?: (value: Date) => boolean | string;
}

function TimeField({ label, name, triggerName, disabled = false, isRequired = false, testId, validate }: TimeFieldProps) {
  const { control, trigger } = useFormContext(); // retrieve all hook methods

  return (
    <FormLabel label={label}>
      <div className="w-full lg:w-1/2">
        <Controller
          name={name}
          control={control}
          rules={{
            required: isRequired ? 'This is required' : false,
            validate,
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <TimePicker
                testId={testId}
                date={value ? new Date(value) : undefined}
                onChange={(e) => {
                  onChange(e?.getTime());
                  trigger(triggerName);
                }}
                disabled={disabled}
                error={error?.message}
              />
            );
          }}
        />
      </div>
    </FormLabel>
  );
}
export { TimeField };
