import { gql, useMutation, useQuery } from '@apollo/client';
import { EventAttendee } from '__generated__/graphql';

export const CREATE_EVENT_ATTENDEE = gql`
  mutation CreateEventAttendee($input: CreateEventAttendeeInput) {
    CreateEventAttendee(input: $input) {
      id
      date
      message
    }
  }
`;

export const EDIT_EVENT_ATTENDEE = gql`
  mutation EditEventAttendee($input: EditEventAttendeeInput) {
    EditEventAttendee(input: $input) {
      date
      message
    }
  }
`;

export const GET_EVENT_ATTENDEE = gql`
  query GetEventAttendee($args: EventAttendeeSearch) {
    GetEventAttendee(args: $args) {
      id
      firstName
      surname
      customerId
      locationIds
      isTest
      phoneNumber
      email
      hubspotId
    }
  }
`;

export const GET_EVENT_ATTENDEES = gql`
  query GetEventAttendees {
    GetEventAttendees {
      id
      firstName
      surname
      customerId
      locationIds
      phoneNumber
      isTest
      email
      hubspotId
    }
  }
`;

interface EventAttendeesResponse {
  GetEventAttendees: EventAttendee[];
}

interface EventAttendeeResponse {
  GetEventAttendee: EventAttendee;
}

export const useGetEventAttendees = () => {
  const {
    loading: eventAttendeesLoading,
    data: { GetEventAttendees: eventAttendees = [] } = {},
    error: eventAttendeesError,
    refetch,
  } = useQuery<EventAttendeesResponse>(GET_EVENT_ATTENDEES);

  return { eventAttendees, eventAttendeesLoading, eventAttendeesError, refetch };
};

export const useGetEventAttendee = (id: string) => {
  const {
    loading: eventAttendeeLoading,
    data,
    error: eventAttendeeError,
    refetch,
  } = useQuery<EventAttendeeResponse>(GET_EVENT_ATTENDEE, {
    fetchPolicy: 'network-only',
    skip: !id,
    variables: {
      args: {
        id,
      },
    },
  });
  return { eventAttendee: data?.GetEventAttendee || undefined, eventAttendeeLoading, eventAttendeeError, refetch };
};

export const useCreateEventAttendee = () => {
  const [createEventAttendee, { loading: createEventAttendeeLoading, error: createEventAttendeeError }] = useMutation(CREATE_EVENT_ATTENDEE, {
    refetchQueries: [
      {
        query: GET_EVENT_ATTENDEES,
      },
    ],
  });

  return {
    createEventAttendee,
    createEventAttendeeLoading,
    createEventAttendeeError,
  };
};

export const useEditEventAttendee = () => {
  const [editEventAttendee, { loading: editEventAttendeeLoading, error: editEventAttendeeError }] = useMutation(EDIT_EVENT_ATTENDEE, {
    refetchQueries: [
      {
        query: GET_EVENT_ATTENDEES,
      },
    ],
  });

  return {
    editEventAttendee,
    editEventAttendeeLoading,
    editEventAttendeeError,
  };
};
