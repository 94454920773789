export enum SizeType {
  Half = 'half',
  Full = 'full',
  Fit = 'fit',
}

export enum ImageSizeType {
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
  XL = 'XL',
}

export type SelectOption = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: string | any;
  label: string;
  id?: string;
};
