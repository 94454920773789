import { useParams, useNavigate } from 'react-router-dom';
import { Event } from 'react-big-calendar';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import HandshakeIcon from '@mui/icons-material/Handshake';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useEditPartner, useGetPartner } from 'api/hooks/usePartner';
import { Loading, ImageField, LocationBar, TabSwitcher, FormLabel, Input } from 'components';
import { EditPartnerInput } from '__generated__/graphql';
import { useToastNofication } from 'context/toastContext';
import DocumentManagement from 'components/Documents';
import { useRemoveDocumentForPartner } from 'api/hooks/useDocuments';
import { PartnerActivateButton, PartnerDisableModal } from './PartnerActivateButton';
import { PartnerForm } from './PartnerForm';
import PartnerLocationMap from './PartnerLocationMap';
import AvailabilityCalendar from '../AvailabilityCalendar';
import { PartnerEventList } from './PartnerEventList';

export interface EventType extends Event {
  end: Date;
  id?: string;
  start: Date;
  title: string;
  isRecurring?: boolean;
  recurringId?: string;
  frequency?: string;
}

function PartnerUpdateForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const { removeDocument } = useRemoveDocumentForPartner({ id: id || '' });

  const togglePartnerEnable = () => {
    setModalOpen(true);
  };

  const onClose = () => {
    setModalOpen(false);
  };
  const { addToast } = useToastNofication();
  const { editPartner, mutationEditPartner } = useEditPartner(id);
  const onSubmit = async (input: EditPartnerInput) => {
    const response = await editPartner({
      variables: {
        input,
      },
    });
    addToast(response, 'EditPartner');
  };
  const onBack = () => {
    navigate('/partners');
  };
  const onDeleteDocument = async (documentId: string, ownerId: string) => {
    const response = await removeDocument({
      variables: {
        input: {
          id: documentId,
          ownerId,
        },
      },
    });
    addToast(response, 'RemoveDocument');
  };

  const onImageUpload = async (image: { url: string; id: string }) => {
    const response = await editPartner({
      variables: {
        input: {
          id: partner?.id,
          image: { url: image?.url, id: image?.id },
        },
      },
    });

    addToast(response, 'EditPartner');
  };
  const { partner, partnerLoading } = useGetPartner(id);

  const tabs = [
    {
      label: 'Details',
      component: <PartnerForm partner={partner} onSubmit={onSubmit} formSubmitting={mutationEditPartner.loading} />,
    },
    {
      label: 'Availability',
      component: (
        <div className="mt-8 sm:mt-12">
          <AvailabilityCalendar partnerId={partner?.id || ''} />
        </div>
      ),
    },
    {
      label: 'Location',
      component: (
        <PartnerLocationMap partner={partner} onSubmit={onSubmit} formSubmitting={mutationEditPartner.loading} formSubmitError={mutationEditPartner.error} />
      ),
    },
    {
      label: 'Events',
      component: <PartnerEventList partnerId={id || ''} />,
    },
    {
      label: 'Documents',
      component: <DocumentManagement onDeleteDocument={onDeleteDocument} ownerId={partner?.id} ownerType="Partner" documents={partner?.documents} />,
    },
  ];
  if (partnerLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Partner Update</title>
      </Helmet>
      <LocationBar section="Partner" page="Partner Update Form" Icon={HandshakeIcon} />
      <div className="my-10 px-4 md:px-[5%]">
        <button data-cy="form-detail-back" type="button" className="text-gray-500 font-semibold text-md leading-md flex items-center" onClick={onBack}>
          <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
        </button>
        <div className="mt-10 sm:mt-16">
          <div className=" items-start justify-between mt-16 flex flex-col	 lg:flex-row xl:flex-row">
            <div className="flex items-center  py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
              <div className="ps-3">
                <ImageField onUpload={onImageUpload} image={partner?.image} />
                <FormLabel label={partner?.isActive ? 'Partner is active' : 'Partner is inactive'}>
                  {partner?.id && <PartnerActivateButton togglePartnerEnable={togglePartnerEnable} partnerEnabled={partner.isActive} />}
                </FormLabel>
                {!partner?.isActive && (
                  <FormLabel label="Deactivation Reason">
                    <Input value={partner?.deactivationReason || ''} disabled={true} placeholder="Deactivation Reason" />
                  </FormLabel>
                )}
              </div>
            </div>
          </div>
          <TabSwitcher tabs={tabs} defaultTab={2} />
        </div>
      </div>
      <PartnerDisableModal
        id={partner?.id || ''}
        isActive={partner?.isActive}
        open={modalOpen}
        onClose={onClose}
        deactivationReason={partner?.deactivationReason}
      />
    </>
  );
}

export default PartnerUpdateForm;
