/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import { format, isAfter } from 'date-fns';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import TableViewIcon from '@mui/icons-material/TableView';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { EventStatus } from 'pages/Event/types';
import { useGetEvents } from 'api/hooks/useEvent';
import { Event } from '__generated__/graphql';
import { useGetPartners } from 'api/hooks/usePartner';
import { useGetLocations } from 'api/hooks/useLocation';
import { Loading, LocationBar, Table } from '../../components';
import Status from './components/Status';

interface EventTable extends Event {
  status: EventStatus;
  time: string;
}

function RowActions({ row }: { row: EventTable }) {
  const navigate = useNavigate();

  const onButtonClick = (event: EventTable) => {
    navigate(`/events/${event.id}`);
  };

  return (
    <button
      type="button"
      data-cy={`event-view-${row.id}-button`}
      onClick={() => onButtonClick(row)}
      aria-label="View"
      className="text-white bg-primary-500 font-medium rounded-lg text-sm p-2 lg:px-5 lg:py-2.5 text-center inline-flex items-center"
    >
      View event
    </button>
  );
}

function MobileEvent({ row }: { row: EventTable }) {
  return (
    <div className="flex items-center">
      <Status status={row.status} small />
      <div className="ml-2">
        <div className="text-gray-900 text-sm leading-sm font-semibold">{format(row?.date ?? 0, 'eeee dd MMMM yyyy')}</div>
        <div className="text-gray-700 text-sm leading-sm">{row.time}</div>
      </div>
    </div>
  );
}

function EventList() {
  const { events, eventsLoading } = useGetEvents();
  const { partners, partnersLoading } = useGetPartners();
  const { locations, locationsLoading } = useGetLocations();

  const [filters, setFilters] = useState([
    {
      name: 'status',
      selectedValue: { value: 'all', label: 'All' },
      values: [
        { value: 'all', label: 'All' },
        { value: 'scheduled', label: 'Scheduled' },
        { value: 'complete', label: 'Complete' },
      ],
    },
  ]);
  const columnVisibility = {
    date: true,
    time: window.innerWidth > 768,
    attendees: window.innerWidth > 768,
    status: window.innerWidth > 768,
  };

  if (eventsLoading || partnersLoading || locationsLoading) return <Loading />;

  const filterByStatus = (event: EventTable) => {
    const filterStatus = filters.find((f) => f.name === 'status')?.selectedValue?.value;

    if (filterStatus === 'all') return true;
    if (filterStatus === 'scheduled' && isAfter(new Date(event.date) ?? 0, new Date())) return true;
    if (filterStatus === 'complete' && isAfter(new Date(), new Date(event.date) ?? 0)) return true;

    return false;
  };

  const rows = events
    ?.map((e) => {
      return {
        ...e,
        time: format(e.date ?? 0, 'kk:mm a'),
        status: isAfter(new Date(), e.date ?? 0) ? EventStatus.Complete : EventStatus.Scheduled,
      };
    })
    .filter((sw) => filterByStatus(sw));

  const columnHelper = createColumnHelper<EventTable>();

  const columns1 = [
    columnHelper.accessor('name', {
      header: () => 'Name',
      cell: (info) => <span data-cy="list-name"> {info.renderValue()}</span>,
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.accessor('date', {
      header: () => (window.innerWidth > 768 ? 'Date' : 'Event'),
      cell: (info) =>
        window.innerWidth > 768 ? (
          <span data-cy="list-date"> {format(info.row.original?.date ?? 0, 'eeee dd MMMM yyyy')} </span>
        ) : (
          <MobileEvent row={info.row.original} />
        ),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('partnerId', {
      header: () => 'Partner',
      cell: (info) => {
        const foundTeam = partners.find((p) => p.id === info.row.original?.partnerId);
        return (
          <span data-cy="list-partner" className="text-md leading-md text-gray-900 font-semibold">
            {foundTeam?.businessName}
          </span>
        );
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('time', {
      header: () => 'Time',
      cell: (info) => <span data-cy="list-time"> {info.renderValue()}</span>,
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.accessor('locationId', {
      header: () => 'Location',
      cell: (info) => {
        const foundLocation = locations.find((p) => p.id === info.row.original?.locationId);
        return (
          <span data-cy="list-location" className="text-md leading-md text-gray-900 font-semibold">
            {foundLocation?.name}
          </span>
        );
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('bookings', {
      header: () => 'Attendees',
      cell: (info) => <span data-cy="list-candidates">{info.row.original.bookings?.length}</span>,
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.accessor('bookings', {
      header: () => 'Not paid',
      cell: (info) => {
        const sumOfPaid = info.row.original.bookings?.reduce((previousValue, currentValue) => {
          if (!currentValue.paid) {
            return previousValue + 1;
          } else {
            return previousValue;
          }
        }, 0);
        return (
          <span data-cy="list-not-paid" className="text-md leading-md text-gray-900 font-semibold">
            {sumOfPaid}
          </span>
        );
      },
      footer: (info) => info.column.id,
    }),

    columnHelper.accessor('bookings', {
      header: () => 'Collected',
      cell: (info) => {
        const sumOfPaid = info.row.original.bookings?.reduce((previousValue, currentValue) => {
          if (currentValue.paid) {
            return previousValue + info.row.original.price;
          } else {
            return previousValue;
          }
        }, 0);
        return (
          <span data-cy="list-sum-paid" className="text-md leading-md text-gray-900 font-semibold">
            £{sumOfPaid}
          </span>
        );
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('bookings', {
      header: () => 'Total Value',
      cell: (info) => {
        const sumOfPaid = info.row.original.bookings?.reduce((previousValue) => {
          return previousValue + info.row.original.price;
        }, 0);
        return (
          <span data-cy="list-sum-paid" className="text-md leading-md text-gray-900 font-semibold">
            £{sumOfPaid}
          </span>
        );
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('status', {
      header: () => 'Status',
      cell: (info) => <Status status={info.renderValue()} />,
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (props) => <RowActions row={props.row.original} />,
    }),
  ] as Array<ColumnDef<EventTable, unknown>>;

  const csv =
    rows?.map((c) => {
      const partner = partners?.find((p) => p.id === c?.partnerId);

      return {
        partnerName: partner?.businessName,
        ...c,
        date: format(c.date ?? 0, 'dd/MM/yy'),
        bookings: JSON.stringify(c.bookings).replace(/"/g, "'"),
      };
    }) || [];

  return (
    <>
      <Helmet>
        <title>Event List</title>
      </Helmet>
      <LocationBar section="Event" page="Event List" Icon={TableViewIcon} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-display-lg leading-display-lg tracking-display-lg text-black font-medium">Event List</div>
        </div>
        <div className="mt-3 md:mt-8 mb-10">
          <Table<EventTable>
            filters={filters}
            csv={{ data: csv, fileName: 'eventList' }}
            toggleButtonKey="status"
            setFilters={setFilters}
            data={rows}
            columns={columns1}
            columnVisibility={columnVisibility}
          />
        </div>
      </div>
    </>
  );
}

export default EventList;
