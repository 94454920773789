import { useForm, FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import { RatingField, InputField, CheckboxField, TextAreaField, PartnerSelectField } from 'components';
import { CreatePartnerReviewInput, EditPartnerReviewInput, PartnerReview } from '__generated__/graphql';

interface PartnerReviewFormProps {
  onSubmit: (data: CreatePartnerReviewInput | EditPartnerReviewInput) => void;
  partnerReview?: PartnerReview;
  formSubmitting: boolean;
  formSubmitError: ApolloError | undefined;
}

function PartnerReviewForm({ partnerReview, onSubmit, formSubmitting, formSubmitError }: PartnerReviewFormProps) {
  const { id } = useParams();

  const methods = useForm<CreatePartnerReviewInput | EditPartnerReviewInput>({
    mode: 'onChange',
    defaultValues: {
      partnerId: id,
      ...partnerReview,
      isVisable: partnerReview?.isVisable ? partnerReview?.isVisable : false,
    },
  });
  const { handleSubmit } = methods;
  const partnerFormSubmit = (data: CreatePartnerReviewInput | EditPartnerReviewInput) => {
    onSubmit({ ...data });
  };

  return (
    <div className="mt-8 sm:mt-12">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(partnerFormSubmit)}>
          <CheckboxField name="isVisable" label="Is shown on client" />
          <InputField name="customerLocation" isRequired label="Customer Location" placeholder="customerLocation" />

          <InputField name="customerName" isRequired label="Customer Name" placeholder="customerName" />
          <TextAreaField name="description" isRequired label="Description" placeholder="description" />
          <RatingField name="rating" isRequired label="Rating" />
          <PartnerSelectField name="partnerId" isRequired label="Partner" />
          {formSubmitError && <div className="text-error-500"> {formSubmitError.message}</div>}

          <button type="submit" disabled={formSubmitting} className="text-white bg-primary-500 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
            Save
          </button>
        </form>
      </FormProvider>
    </div>
  );
}

export { PartnerReviewForm };
