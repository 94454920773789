import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import BadgeIcon from '@mui/icons-material/Badge';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { CreateUserInput, EditUserInput } from '__generated__/graphql';
import { Loading, LocationBar } from 'components';
import { useGetUser, useEditUser } from 'api/hooks/useUser';
import { useToastNofication } from 'context/toastContext';
import { UserForm } from './UserForm';
import { UserActivateButton } from './UserActivateButton';

function CustomerUpdateForm() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToastNofication();

  const { editUser, editUserLoading, editUserError } = useEditUser();
  const onSubmit = async (input: CreateUserInput | EditUserInput) => {
    const response = await editUser({
      variables: {
        input,
      },
    });
    addToast(response, 'EditUser');

    if (!editUserLoading && !editUserError) {
      navigate('/users');
    }
  };

  const { user, userLoading } = useGetUser(userId || '');

  if (userLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>User Update</title>
      </Helmet>
      <LocationBar section="User" page="User Update Form" Icon={BadgeIcon} />
      <div className="my-10 px-4 md:px-[5%]">
        <button
          data-cy="form-detail-back"
          type="button"
          className="text-gray-500 font-semibold text-md leading-md flex items-center"
          onClick={() => {
            navigate('/users');
          }}
        >
          <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
        </button>
        <div className="mt-10 sm:mt-16">
          <div className=" items-start justify-between mt-8 flex flex-col	 lg:flex-row xl:flex-row">
            {user?.id && <UserActivateButton id={user?.id} isActive={user.active} />}
          </div>
          <UserForm user={user} onSubmit={onSubmit} formSubmitting={editUserLoading} />
        </div>
      </div>
    </>
  );
}

export default CustomerUpdateForm;
