import { useState, useEffect, useCallback } from 'react';
import { Auth } from 'aws-amplify';

const useHasGroup = (groups: string[]) => {
  const [isAuthorised, setIsAuthorised] = useState<boolean | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean | undefined>(false);

  const checkUserToken = useCallback(async () => {
    let hasGroups;
    setIsLoading(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      const cognitoGroups = user.signInUserSession.accessToken.payload['cognito:groups'];
      hasGroups = groups.every((group: string) => cognitoGroups.includes(group));
    } catch (error) {
      console.log(error);
    }
    setIsAuthorised(hasGroups);
    setIsLoading(false);
  }, [groups]);

  useEffect(() => {
    if (isAuthorised === undefined) checkUserToken();
  }, [isAuthorised, checkUserToken]);

  return { isAuthorised, isLoading };
};

const useIsUserAdmin = () => {
  return useHasGroup(['admin']);
};

const useIsPartner = () => {
  return useHasGroup(['partners']);
};
export { useIsUserAdmin, useIsPartner };
