import { gql, useMutation, useQuery } from '@apollo/client';
import { Image } from '__generated__/graphql';

export const ADD_IMAGE = gql`
  mutation AddImage($input: ImageInput) {
    AddImage(input: $input) {
      id
      url
    }
  }
`;

export const GET_IMAGE = gql`
  query GetImage($args: ImageSearch) {
    GetImage(args: $args) {
      id
      url
    }
  }
`;

interface ImageResponse {
  GetImage: Image;
}

const useAddImage = () => {
  const [addImage, { loading: addImageLoading, error: addImageError, data: { AddImage: newImage = {} } = {} }] = useMutation(ADD_IMAGE);
  return {
    addImage,
    addImageLoading,
    addImageError,
    newImage,
  };
};

const useGetImage = (id?: string) => {
  const {
    loading: imageLoading,
    data,
    error: imageError,
    refetch: refetchImage,
  } = useQuery<ImageResponse>(GET_IMAGE, {
    fetchPolicy: 'network-only',
    variables: {
      args: {
        id,
      },
    },
  });

  return {
    image: data?.GetImage || undefined,
    refetchImage,
    imageLoading,
    imageError,
  };
};

export { useAddImage, useGetImage };
