/* eslint-disable react/no-unstable-nested-components */
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { CheckCircleIcon as CheckCircleIconSolid, XCircleIcon as XCircleIconSolid } from '@heroicons/react/24/solid';
import { CheckCircleIcon, TrashIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { DeleteModal, Loading, ReactSelect, Table } from 'components';
import { Event, EventBooking, Maybe } from '__generated__/graphql';
import { useGetCustomers } from 'api/hooks/useCustomer';
import { useGetEventAttendees } from 'api/hooks/useEventAttendee';

interface EventAttendeeListProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventSubmit: any;
}

export type EventBookingTable = {
  id: string;
  customerId?: Maybe<string> | undefined;
  attendeeId?: Maybe<string> | undefined;
  paid: boolean;
  paymentType?: Maybe<string> | undefined;
  attended: boolean;
  firstName: string;
  surname: string;
  phone: string;
  email: string;
  alert: boolean;
};

function TableCheckBox({
  bookings,
  id,
  eventSubmit,
}: {
  bookings: Maybe<EventBooking[]> | undefined;
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventSubmit: any;
}) {
  const index = bookings?.findIndex((field) => field.id === id);
  const { control, handleSubmit } = useFormContext(); // retrieve all hook methods
  const onFieldChanage = () => {
    handleSubmit(eventSubmit)();
  };
  return (
    <Controller
      name={`bookings.${index}.paid`}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <>
            <input
              onChange={(event) => {
                onChange(event.target.checked);
                onFieldChanage();
              }}
              checked={value || false}
              id="checked-checkbox"
              type="checkbox"
              // eslint-disable-next-line max-len
              className="w-4 h-4 text-primary-300 bg-gray-100 border-gray-300 rounded "
            />

            <p className="mt-2 text-sm text-error-600 dark:text-green-500">
              <span className="font-medium">{error?.message}</span>
            </p>
          </>
        );
      }}
    />
  );
}

function DisplayBoolean({
  bookings,
  id,
  eventSubmit,
}: {
  bookings: Maybe<EventBooking[]> | undefined;
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventSubmit: any;
}) {
  const index = bookings?.findIndex((field) => field.id === id);
  const { control, handleSubmit } = useFormContext(); // retrieve all hook methods
  const onFieldChanage = () => {
    handleSubmit(eventSubmit)();
  };
  return (
    <Controller
      name={`bookings.${index}.attended`}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <>
            <div className="shadow-xs border border-gray-300 rounded-lg items-center w-fit hidden md:flex">
              <button
                type="button"
                disabled={value === true}
                onClick={() => {
                  onChange(true);
                  onFieldChanage();
                }}
                className={`${value ? 'bg-success-500 text-white' : 'bg-white text-gray-500'} px-3 py-2.5 border-r border-gray-300 rounded-l-lg`}
              >
                <CheckCircleIcon className="w-5 h-5" />
              </button>
              <button
                type="button"
                disabled={value === false}
                onClick={() => {
                  onChange(false);
                  onFieldChanage();
                }}
                className={`${value === false ? 'bg-error-500 text-white' : 'bg-white text-gray-500'} px-3 py-2.5 rounded-r-lg`}
              >
                <XCircleIcon className="w-5 h-5" />
              </button>
            </div>

            <div className="md:hidden">
              {value ? (
                <button
                  type="button"
                  disabled={value === false}
                  onClick={() => {
                    onChange(false);
                    onFieldChanage();
                  }}
                >
                  <CheckCircleIconSolid className="w-6 h-6 text-success-500" />{' '}
                </button>
              ) : (
                <button
                  type="button"
                  disabled={value === true}
                  onClick={() => {
                    onChange(true);
                    onFieldChanage();
                  }}
                >
                  <XCircleIconSolid className="w-6 h-6 text-error-500" />
                </button>
              )}
            </div>
          </>
        );
      }}
    />
  );
}

function PaymentTypeSelect({
  paid,
  attendeed,
  bookings,
  id,
  eventSubmit,
}: {
  paid: boolean;
  attendeed: boolean;
  bookings: Maybe<EventBooking[]> | undefined;
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventSubmit: any;
}) {
  const index = bookings?.findIndex((field) => field.id === id);
  const { control, handleSubmit } = useFormContext(); // retrieve all hook methods
  const fieldChange = () => {
    handleSubmit(eventSubmit)();
  };

  const options = [
    { value: 'Cash', label: 'Cash' },
    { value: 'Invoice', label: 'Invoice' },
  ];
  if (attendeed && !paid) {
    return (
      <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
        Please update payment for this attendee!
      </div>
    );
  } else if (paid) {
    return (
      <Controller
        name={`bookings.${index}.paymentType`}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          const selectedValue = options.find((option) => option.value === value);
          return (
            <>
              <ReactSelect
                options={[
                  { value: 'Cash', label: 'Cash' },
                  { value: 'Invoice', label: 'Invoice' },
                ]}
                onChange={(e) => {
                  onChange(e.value);
                  fieldChange();
                }}
                selectedValue={selectedValue}
              />
              <p className="mt-2 text-sm text-error-600 dark:text-green-500">
                <span className="font-medium">{error?.message}</span>
              </p>
            </>
          );
        }}
      />
    );
  } else {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }
}
function RowActions({
  id,
  bookings,
  onDeleteModalOpen,
}: {
  id: string;
  bookings: Maybe<EventBooking[]> | undefined;
  onDeleteModalOpen: (index: number | undefined) => void;
}) {
  const index = bookings?.findIndex((field) => field.id === id);

  return (
    <div className="flex items-center gap-3">
      <button
        type="button"
        onClick={() => {
          onDeleteModalOpen(index);
        }}
        aria-label="Delete"
      >
        <TrashIcon className="w-6 h-6 text-gray-500 hover:text-primary-600" />
      </button>
    </div>
  );
}

export default function EventBookingList({ eventSubmit }: EventAttendeeListProps) {
  const columnVisibility = {
    name: true,
    attended: true,
    paidCash: true,
    paid: true,
    email: window.innerWidth > 1280,
    phone: window.innerWidth > 1280,
  };
  const [selectedBookingId, setSelectedBookingId] = useState<number | undefined>();
  const { control, handleSubmit, getValues } = useFormContext<Event>();
  const bookings = getValues('bookings');

  const { remove } = useFieldArray<Event>({
    name: 'bookings', // unique name for your Field Array
    control,
  });

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const onDeleteModalOpen = (index: number | undefined) => {
    setSelectedBookingId(index);
    setDeleteModalOpen(true);
  };

  const onDeleteClose = () => {
    setDeleteModalOpen(false);
  };

  const columnHelper = createColumnHelper<EventBookingTable>();
  const { customers, customersLoading } = useGetCustomers();
  const { eventAttendees, eventAttendeesLoading } = useGetEventAttendees();
  const columns1 = [
    columnHelper.accessor('firstName', {
      header: () => 'Attendee',
      cell: (info) => {
        return (
          <div>
            <div className="text-sm leading-sm md:text-md md:leading-md text-gray-900 font-semibold" data-cy="list-workshop-candidate-name">
              {info.row.original.firstName} {info.row.original.surname}
            </div>
          </div>
        );
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('email', {
      header: () => 'Email',
      cell: (info) => {
        return (
          <div>
            <div className="text-sm leading-sm md:text-md md:leading-md text-gray-900 font-semibold" data-cy="list-workshop-candidate-name">
              {info.row.original.email}
            </div>
          </div>
        );
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('phone', {
      header: () => 'Phone',
      cell: (info) => {
        return (
          <div>
            <div className="text-sm leading-sm md:text-md md:leading-md text-gray-900 font-semibold" data-cy="list-workshop-candidate-name">
              {info.row.original.phone}
            </div>
          </div>
        );
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('attended', {
      header: () => 'Attended',
      cell: (info) => <DisplayBoolean eventSubmit={eventSubmit} id={info.row.original.id} bookings={bookings} />,
      footer: (info) => info.column.id,
    }),

    columnHelper.accessor('paid', {
      header: () => 'Paid',
      cell: (info) => <TableCheckBox eventSubmit={eventSubmit} id={info.row.original.id} bookings={bookings} />,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('paymentType', {
      header: () => 'Payment Type',
      cell: (info) => (
        <PaymentTypeSelect
          eventSubmit={eventSubmit}
          attendeed={info.row.original.attended}
          paid={info.row.original.paid}
          id={info.row.original.id}
          bookings={bookings}
        />
      ),
      footer: (info) => info.column.id,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (props) => <RowActions id={props.row.original.id} bookings={bookings} onDeleteModalOpen={onDeleteModalOpen} />,
    }),
  ] as Array<ColumnDef<EventBookingTable, unknown>>;

  const rows = bookings?.map((c: EventBooking) => {
    let found;
    if (c.attendeeId) {
      found = eventAttendees.find((p) => p.id === c?.attendeeId);
    } else {
      found = customers.find((p) => p.id === c?.customerId);
    }

    return {
      ...c,
      firstName: found?.firstName,
      alert: c.attended && !c.paid,
      surname: found?.surname,
      email: found?.email,
      phone: found?.phoneNumber,
    };
  });

  if (eventAttendeesLoading || customersLoading) {
    return <Loading />;
  }

  const onConfirm = () => {
    remove(selectedBookingId);
    handleSubmit(eventSubmit)();
    setDeleteModalOpen(false);
  };

  return (
    <div>
      {rows && rows.length > 0 && <Table<EventBookingTable> hidePrint={true} data={rows} columns={columns1} columnVisibility={columnVisibility} />}
      <DeleteModal onClose={onDeleteClose} open={deleteModalOpen} onConfirm={onConfirm} isDisabled={false} />
    </div>
  );
}
