import { ChangeEvent } from 'react';

type FileInputProps = {
  handleUpload: (file: File) => void;
  placeholder?: string;
  accept: string;
};

function FileInput({ handleUpload, placeholder, accept }: FileInputProps) {
  const handeFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event?.target as HTMLInputElement;

    if (!input.files?.length) {
      return;
    }

    const file = input.files[0];
    handleUpload(file);
  };

  return (
    <label htmlFor="upload-file" className="block">
      <span className="sr-only">{placeholder}</span>
      <input
        type="file"
        accept={accept}
        onChange={handeFileChange}
        className="block w-full text-sm text-primary-500
        file:mr-4 file:py-2 file:px-4
        file:rounded-full file:border-0
        file:text-sm file:font-semibold
        whitespace-normal
        file:bg-primary-50 file:text-primary-700
        hover:file:bg-primary-100
      "
      />
    </label>
  );
}
export { FileInput };
