import { RouteObject } from 'react-router-dom';
import MyBooking from 'pages/MyBooking';
import { MyBookingCreateForm } from 'pages/MyBooking/MyBookingCreate';
import MyPriceList from 'pages/MyPrices/MyPriceList';
import MyPriceCreateForm from 'pages/MyPrices/MyPriceCreate';
import MyPriceUpdateForm from 'pages/MyPrices/MyPriceUpdate';

export const publicRoutes: RouteObject[] = [
  {
    path: '/my-bookings',

    children: [
      {
        index: true,
        element: <MyBooking />,
      },
      {
        path: 'create',
        element: <MyBookingCreateForm />,
      },
    ],
  },
  {
    path: '/my-prices',

    children: [
      {
        index: true,
        element: <MyPriceList />,
      },
      {
        path: 'create',
        element: <MyPriceCreateForm />,
      },
      {
        path: ':priceId',
        children: [
          {
            path: 'update',
            element: <MyPriceUpdateForm />,
          },
        ],
      },
    ],
  },
];
