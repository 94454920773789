import Dialog from '@mui/material/Dialog';
import { ReactNode } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { TrashIcon } from '@heroicons/react/24/solid';

interface ModalProps {
  onConfirm?: () => void;
  open: boolean;
  loading?: boolean;
  onClose: () => void;
  onDelete?: () => void;
  title?: string;
  confirmLabel?: string;
  isDisabled: boolean;
  children: ReactNode;
}

function Modal({ onConfirm, open, onClose, loading = false, title, onDelete, isDisabled, confirmLabel = 'Save', children }: ModalProps) {
  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={() => onClose()}
      className=" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
    >
      <div className={`flex items-center  p-4 md:p-5 ${title ? 'border-b rounded-t dark:border-gray-600' : ''}`}>
        {title && <h3 className="text-lg font-semibold text-gray-900 dark:text-white">{title}</h3>}
        <div>
          {onDelete && (
            <button className="m-2 absolute top-3 end-12" type="button" onClick={() => onDelete()} aria-label="Delete">
              <TrashIcon className="w-6 h-6 text-error-500 hover:text-gray-500" />
            </button>
          )}
          <button className="m-2 absolute top-3 end-3.5" type="button" onClick={() => onClose()} aria-label="close">
            <XMarkIcon className="w-6 h-6 text-gray-500" />
          </button>
        </div>
      </div>
      <div className="relative p-4 w-full max-w-2xl max-h-full overflow-x-hidden">{children}</div>
      {onConfirm && (
        <div className={`flex items-center justify-evenly p-4 md:p-5 ${title ? 'border-t border-gray-200 rounded-b dark:border-gray-600' : ''}`}>
          <button
            type="submit"
            data-cy="modal-save-button"
            onClick={onConfirm}
            disabled={isDisabled || loading}
            // eslint-disable-next-line max-len
            className="text-white w-30 bg-primary-500 disabled:shadow-none disabled:border-primary-200 disabled:bg-primary-200 disabled:text-white   rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
          >
            {confirmLabel}
          </button>

          <button
            type="button"
            data-testid="modal-close-button"
            onClick={onClose}
            className=" bg-error-600 text-white w-30 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
          >
            Cancel
          </button>
        </div>
      )}
    </Dialog>
  );
}

export { Modal };
