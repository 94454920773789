import { useMemo } from 'react';
import { useIsPartner } from '../../hooks/authHooks';

function HeaderTitle() {
  const { isAuthorised } = useIsPartner();

  const envText = useMemo(() => {
    const { host } = window.location;
    let text = '';

    const env: string = host.split('.')[0]?.split('-')[0];

    if (env === 'test') {
      text = ': Test';
    }
    if (env === 'dev') {
      text = 'Dev';
    }

    return text;
  }, []);

  return (
    <div data-testid="welcome-thrive" data-cy="welcome-thrive" className="text-lg leading-lg text-white font-medium align-text-center">
      {isAuthorised ? `Thrive Partner Portal ${envText}` : `Thrive Admin Portal ${envText}`}
    </div>
  );
}

export { HeaderTitle };
