import { ReactNode, useState } from 'react';

interface TabSwitcherProps {
  tabs: { label: string; component: ReactNode }[];
  defaultTab?: number;
}
function TabSwitcher({ tabs, defaultTab }: TabSwitcherProps) {
  const [selectedTab, setSelectedTab] = useState<undefined | number>(defaultTab);

  const handleTabChange = (newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <div className="w-full border-b-2 border-gray-100 flex items-center gap-10 -mb-px overflow-auto flex-wrap">
        {tabs.map((tab, index) => (
          <button
            key={tab.label}
            type="button"
            data-cy={`tab-${index}`}
            className={`${
              selectedTab === index ? 'text-primary-900 border-primary-700 border-b-2' : 'text-gray-500'
            } text-md leading-md font-semibold pb-4 shrink-0 `}
            onClick={() => handleTabChange(index)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div id="default-styled-tab-content">
        {tabs.map((tab, index) => (
          <div key={tab.label} className={` w-full ${index === selectedTab ? '' : 'hidden'}`} id="styled-profile" role="tabpanel" aria-labelledby="profile-tab">
            <p className="text-sm text-gray-500 dark:text-gray-400">{tab.component}</p>
          </div>
        ))}
      </div>
    </>
  );
}

export { TabSwitcher };
