import { FormProvider, useForm } from 'react-hook-form';
import { isValidEmail, isValidPhone, AddressField, DateField, InputField, TextAreaField, CheckboxField } from '../../components';
import { EditPartnerInput, CreatePartnerInput, Partner } from '../../__generated__/graphql';

interface PartnerFormProps {
  onSubmit: (data: CreatePartnerInput | EditPartnerInput) => void;
  partner?: Partner;
  formSubmitting: boolean;
}

function PartnerForm({ partner, onSubmit, formSubmitting }: PartnerFormProps) {
  const methods = useForm<CreatePartnerInput | EditPartnerInput>({
    mode: 'onChange',
    defaultValues: {
      isActive: partner?.isActive ? partner?.isActive : false,
      isTest: partner?.isTest ? partner?.isTest : false,
      id: partner?.id,
      businessName: partner?.businessName,
      firstName: partner?.firstName,
      surname: partner?.surname,
      description: partner?.description,
      phoneNumber: partner?.phoneNumber,
      dbsChecked: partner?.dbsChecked ? partner?.dbsChecked : false,
      dbsExpiryDate: partner?.dbsExpiryDate,
      vetted: partner?.vetted ? partner?.vetted : false,
      referencesReceived: partner?.referencesReceived ? partner?.referencesReceived : false,
      qualifications: partner?.qualifications,
      yearsQualified: partner?.yearsQualified,
      qualificationsChecked: partner?.qualificationsChecked ? partner?.qualificationsChecked : false,
      address: partner?.address,
      email: partner?.email,
    },
  });
  const { handleSubmit, setValue, watch } = methods;

  const getPostcodeLookup = async (postcode: string) => {
    const res = await fetch(`https://api.postcodes.io/postcodes/${postcode.replace(' ', '')}`);
    return res.json();
  };

  const partnerFormSubmit = async (data: CreatePartnerInput | EditPartnerInput) => {
    const { result } = await getPostcodeLookup(data.address?.postcode || '');
    const lat = result?.latitude || 0;
    const long = result?.longitude || 0;

    const inputData = data.address ? { ...data, address: { ...data.address, lat, long } } : data;
    onSubmit({ ...inputData });
  };

  const showDbsExpiryDate = watch('dbsChecked');

  return (
    <div className="mt-8 sm:mt-12">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(partnerFormSubmit)}>
          <CheckboxField name="isTest" label="Is Test account" />

          <InputField name="businessName" isRequired label="Business name" placeholder="Business name" />

          <InputField name="firstName" isRequired label="Supplier first name" placeholder="Supplier first name" />
          <InputField name="surname" isRequired label="Supplier surname" placeholder="Supplier surname" />
          <TextAreaField name="description" isRequired label="Description of service" placeholder="Description of service" />

          <InputField name="email" validate={isValidEmail} isRequired label="Partner email" placeholder="Partner email" />
          <InputField name="phoneNumber" validate={isValidPhone} isRequired type="tel" label="Partner number" placeholder="Partner number" />
          <CheckboxField name="dbsChecked" label="DBS Checked" />
          {showDbsExpiryDate && <DateField name="dbsExpiryDate" label="DBS Expiry Date" />}

          <CheckboxField name="vetted" label="Face to face vetted" />
          <CheckboxField name="referencesReceived" label="References received" />
          <InputField name="qualifications" isRequired label="Qualifications" placeholder="Qualifications" />
          <InputField name="yearsQualified" type="number" min="0" isRequired label="Years of experience" placeholder="Years of experience" />

          <CheckboxField name="qualificationsChecked" label="Qualifications checked" />
          <AddressField setValue={setValue} />

          <button type="submit" disabled={formSubmitting} className="text-white bg-primary-500 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
            Save
          </button>
        </form>
      </FormProvider>
    </div>
  );
}

export { PartnerForm };
