import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { EditPartnerReviewInput, CreatePartnerReviewInput } from '__generated__/graphql';
import { Loading, LocationBar } from 'components';
import { useGetPartnerReview, useEditPartnerReview } from 'api/hooks/usePartnerReview';
import { useToastNofication } from 'context/toastContext';
import { PartnerReviewForm } from './PartnerReviewForm';

function PartnerReviewUpdateForm() {
  const { id, reviewId } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToastNofication();

  const { editPartnerReview, editPartnerReviewLoading, editPartnerReviewError } = useEditPartnerReview(id || '');
  const onSubmit = async (input: CreatePartnerReviewInput | EditPartnerReviewInput) => {
    const response = await editPartnerReview({
      variables: {
        input,
      },
    });
    addToast(response, 'EditPartnerReview');

    if (!editPartnerReviewLoading && !editPartnerReviewError) {
      navigate(id ? `/partners/${id}/reviews` : '/reviews');
    }
  };

  const { partnerReview, partnerReviewLoading } = useGetPartnerReview(reviewId);

  if (partnerReviewLoading) {
    return <Loading />;
  }
  const onBack = () => {
    navigate(id ? `/partners/${id}/reviews` : '/reviews');
  };
  return (
    <>
      <Helmet>
        <title>Review Create</title>
      </Helmet>
      <LocationBar section="Partner" page="Review Create Form" Icon={RateReviewIcon} />
      <div className="my-10 px-4 md:px-[5%]">
        <button data-cy="form-detail-back" type="button" className="text-gray-500 font-semibold text-md leading-md flex items-center" onClick={onBack}>
          <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
        </button>
        <PartnerReviewForm
          partnerReview={partnerReview}
          onSubmit={onSubmit}
          formSubmitting={editPartnerReviewLoading}
          formSubmitError={editPartnerReviewError}
        />
      </div>
    </>
  );
}

export default PartnerReviewUpdateForm;
