/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import BadgeIcon from '@mui/icons-material/Badge';
import { PencilIcon, PlusIcon } from '@heroicons/react/24/outline';
import { Suspense, useMemo } from 'react';
import { CellContext, ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { User } from '__generated__/graphql';
import { Loading, LocationBar, Table } from 'components';
import { useGetUsers } from 'api/hooks/useUser';

function TableCheckBox({ row }: { row: any }) {
  return (
    <div className="flex items-center">
      <input
        type="checkbox"
        checked={row.original.active}
        disabled={true}
        className="w-4 h-4 text-primary-300 bg-gray-100 border-gray-300 rounded cursor-pointer"
      />
    </div>
  );
}

function RowActions(props: CellContext<User, unknown>) {
  const navigate = useNavigate();

  return (
    <div className="flex items-center gap-3">
      <button type="button" onClick={() => navigate(`${props.row.original.id}/update`)} aria-label="Edit">
        <PencilIcon className="w-6 h-6 text-gray-500 hover:text-primary-600" />
      </button>
    </div>
  );
}

function UserList() {
  const navigate = useNavigate();

  const columnHelper = createColumnHelper<User>();

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('id', {
        header: () => 'Id',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('name', {
        header: () => 'Name',
        cell: (info: { renderValue: () => any }) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('email', {
        header: () => 'Email',
        cell: (info: { renderValue }) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('active', {
        header: () => 'Active',
        cell: TableCheckBox,
        footer: (info) => info.column.id,
      }),

      columnHelper.display({
        id: 'actions',
        cell: RowActions,
      }),
    ] as Array<ColumnDef<User, unknown>>;
  }, [columnHelper]);

  const { users, usersLoading } = useGetUsers();

  if (usersLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>User List</title>
      </Helmet>
      <LocationBar section="User" page="User List" Icon={BadgeIcon} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-display-lg leading-display-lg tracking-display-lg text-black font-medium">User List</div>
          <button
            type="button"
            className="text-white bg-primary-500 rounded-lg px-5 py-2.5 font-semibold text-md leading-md hidden md:block"
            onClick={() => navigate('create')}
          >
            Add User
          </button>
          <button
            type="button"
            aria-label="Add user"
            className="text-white bg-primary-500 rounded-lg p-2 font-semibold text-md leading-md md:hidden"
            onClick={() => navigate('create')}
          >
            <PlusIcon className="w-5 h-5 text-white" />
          </button>
        </div>
        <Suspense fallback={<Loading />}>
          <Table<User> csv={{ data: users, fileName: 'userList' }} data={users} columns={columns} />
        </Suspense>
      </div>
    </>
  );
}

export default UserList;
