import { SetStateAction, Dispatch } from 'react';
import { useFormContext } from 'react-hook-form';
import { AttendeeField } from './AttendeeSelect';
import { SelectOption } from '../../../../types';

interface AddExistingAttendeeFormProps {
  attendeeType: SelectOption | undefined;
  setAttendeeType: Dispatch<SetStateAction<SelectOption | undefined>>;
  attendeeIds: string[];
  onClickAttendee: (id: string) => void;
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventSubmit: any;
}

function AddExistingAttendeeForm({ attendeeType, setAttendeeType, attendeeIds, onClickAttendee, onClose, eventSubmit }: AddExistingAttendeeFormProps) {
  const { handleSubmit } = useFormContext<Event>();

  const onSave = () => {
    handleSubmit(eventSubmit)();
    onClose();
  };
  return (
    <div>
      <AttendeeField attendeeIds={attendeeIds} onClickAttendee={onClickAttendee} setAttendeeType={setAttendeeType} attendeeType={attendeeType} />
      <div className="flex items-center justify-evenly p-4 md:p-5 ">
        <button
          type="button"
          onClick={onSave}
          data-cy="add-existing-attendee-save-button"
          // eslint-disable-next-line max-len
          className="text-white w-30 bg-primary-500 disabled:shadow-none disabled:border-primary-200 disabled:bg-primary-200 disabled:text-white   rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
        >
          Save
        </button>

        <button
          type="button"
          data-testid="modal-close-button"
          onClick={onClose}
          className=" bg-error-600 text-white w-30 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export { AddExistingAttendeeForm };
