import { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormLabel } from '../FormLabel';
import { Input } from '../Input';
import { isValueRequired } from './validators';

interface InputFieldProps {
  label: string;
  placeholder: string;
  name: string;
  disabled?: boolean;
  type?: string;
  isRequired?: boolean;
  validate?: (value: string) => boolean | string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  min?: string;
  step?: string;
}
interface InputNoLabelFieldProps {
  placeholder: string;
  name: string;
  disabled?: boolean;
  type?: string;
  isRequired?: boolean;
  validate?: (value: string) => boolean | string;
}

function InputNoLabelField({ placeholder, isRequired = false, name, type, disabled = false, validate }: InputNoLabelFieldProps) {
  const { control } = useFormContext(); // retrieve all hook methods
  let validation;
  if (validate && isRequired) {
    validation = { validate, isValueRequired };
  } else if (isRequired && !validate) {
    validation = { isValueRequired };
  } else if (validate && !isRequired) {
    validation = { validate };
  }
  return (
    <div className=" pb-3">
      <Controller
        name={name}
        control={control}
        rules={{ validate: validation }}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <>
              <Input testId={name} type={type} placeholder={placeholder} onChange={onChange} value={value} disabled={disabled} />
              <p className="mt-2 text-sm text-error-600 dark:text-green-500">
                <span className="font-medium">{error?.message}</span>
              </p>
            </>
          );
        }}
      />
    </div>
  );
}

function InputField({
  min,
  label,
  startAdornment,
  endAdornment,
  placeholder,
  isRequired = false,
  name,
  type,
  step,
  disabled = false,
  validate,
}: InputFieldProps) {
  const { control } = useFormContext(); // retrieve all hook methods
  let validation;
  if (validate && isRequired) {
    validation = { isValueRequired, validate };
  } else if (isRequired && !validate) {
    validation = { isValueRequired };
  } else if (validate && !isRequired) {
    validation = { validate };
  }
  return (
    <FormLabel label={label}>
      <div className="w-full lg:w-1/2">
        <Controller
          name={name}
          control={control}
          rules={{ validate: validation }}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <>
                <Input
                  step={step}
                  min={min}
                  startAdornment={startAdornment}
                  endAdornment={endAdornment}
                  testId={name}
                  type={type}
                  name={name}
                  placeholder={placeholder}
                  onChange={onChange}
                  value={value}
                  disabled={disabled}
                />
                <p className="mt-2 text-sm text-error-600 dark:text-green-500">
                  <span className="font-medium">{error?.message}</span>
                </p>
              </>
            );
          }}
        />
      </div>
    </FormLabel>
  );
}

export { InputField, InputNoLabelField };
