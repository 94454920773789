import { useForm, FormProvider } from 'react-hook-form';
import { useEffect } from 'react';
import { InputAdornment } from '@mui/material';
import { ApolloError } from '@apollo/client';
import { InputField, ProductSelectField } from 'components';
import { CreatePartnerPriceInput, EditPartnerPriceInput, PartnerPrice } from '__generated__/graphql';
import { useUser } from 'context/userContext';

interface PartnerPriceFormProps {
  onSubmit: (data: CreatePartnerPriceInput | EditPartnerPriceInput) => void;
  partnerPrice?: PartnerPrice;
  formSubmitting: boolean;
  formSubmitError: ApolloError | undefined;
}

function PartnerPriceForm({ partnerPrice, onSubmit, formSubmitting, formSubmitError }: PartnerPriceFormProps) {
  const { partner } = useUser();
  const methods = useForm<CreatePartnerPriceInput | EditPartnerPriceInput>({
    mode: 'onChange',
    defaultValues: {
      partnerId: partner?.id || '',
      ...partnerPrice,
    },
  });

  const { handleSubmit, watch, setValue } = methods;

  const partnerFormSubmit = (data: CreatePartnerPriceInput | EditPartnerPriceInput) => {
    onSubmit({ ...data });
  };

  const basePrice = watch('basePrice');
  const markup = watch('markup');

  useEffect(() => {
    if (basePrice && markup) {
      const balance = Number(basePrice) + Number(markup);
      setValue('bookingPrice', balance);
    }
  }, [basePrice, markup, setValue]);

  const priceAdorment = <InputAdornment position="start">£</InputAdornment>;
  const minsAdorment = <InputAdornment position="end">mins</InputAdornment>;

  return (
    <div className="mt-8 sm:mt-12">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(partnerFormSubmit)}>
          <ProductSelectField name="productId" isRequired label="Product" />

          <InputField
            name="basePrice"
            startAdornment={priceAdorment}
            type="number"
            min="0"
            step=".01"
            isRequired
            label="Base Price (without margin)"
            placeholder="Base Price "
          />

          <InputField name="markup" startAdornment={priceAdorment} step=".01" type="number" min="0" isRequired label="Markup" placeholder="Markup" />
          <InputField
            name="bookingPrice"
            startAdornment={priceAdorment}
            type="number"
            min="0"
            step=".01"
            isRequired
            label="Booking Price (with margin)"
            placeholder="Booking Price"
          />
          <InputField name="duration" label="Duration" placeholder="Duration" endAdornment={minsAdorment} type="number" min="30" step="15" isRequired />

          {formSubmitError && <div className="text-error-500"> {formSubmitError.message}</div>}

          <button type="submit" disabled={formSubmitting} className="text-white bg-primary-500 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
            Save
          </button>
        </form>
      </FormProvider>
    </div>
  );
}

export { PartnerPriceForm };
