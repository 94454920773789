/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { format, isAfter } from 'date-fns';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { PencilIcon, PlusIcon } from '@heroicons/react/24/outline';
import { EventStatus } from 'pages/Event/types';
import ToggleButtons from 'components/ToggleButtons';
import { SizeType } from 'types';
import { useGetEvents } from 'api/hooks/useEvent';
import { Event } from '__generated__/graphql';
import { useGetLocations } from 'api/hooks/useLocation';
import { Loading, Table } from '../../components';
import Status from '../Event/components/Status';
import CreateEvent from '../Event/components/CreateEvent';

interface EventTable extends Event {
  status: EventStatus;
  time: string;
}

function RowActions({ row }: { row: EventTable }) {
  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();

  const onButtonClick = (event: EventTable) => {
    navigate(id ? `/partners/${id}/events/${event.id}` : `/events/${event.id}`);
  };

  return (
    <button type="button" data-cy={`event-edit-${row.id}-button`} onClick={() => onButtonClick(row)} aria-label="Edit" className="m-0 p-0">
      <PencilIcon className="w-6 h-6 text-gray-500 hover:text-primary600" />
    </button>
  );
}

function MobileEvent({ row }: { row: EventTable }) {
  return (
    <div className="flex items-center">
      <Status status={row.status} small />
      <div className="ml-2">
        <div className="text-gray-900 text-sm leading-sm font-semibold">{format(row?.date ?? 0, 'eeee dd MMMM yyyy')}</div>
        <div className="text-gray-700 text-sm leading-sm">{row.time}</div>
      </div>
    </div>
  );
}

function PartnerEventList({ partnerId }: { partnerId: string }) {
  const { events, eventsLoading } = useGetEvents(partnerId);
  const { locations, locationsLoading } = useGetLocations();

  const [filterStatus, setFilterStatus] = useState('All');
  const [openCreateEventModal, setOpenCreateEventModal] = useState(false);
  const columnVisibility = {
    date: true,
    time: window.innerWidth > 768,
    bookings: window.innerWidth > 768,
    status: window.innerWidth > 768,
  };

  if (eventsLoading || locationsLoading) return <Loading />;

  const changeFilter = (f: string) => {
    setFilterStatus(f);
  };

  const filterByStatus = (event: EventTable) => {
    if (filterStatus === 'All') return true;
    if (filterStatus === 'Scheduled' && isAfter(new Date(event.date) ?? 0, new Date())) return true;
    if (filterStatus === 'Complete' && isAfter(new Date(), new Date(event.date) ?? 0)) return true;

    return false;
  };

  const rows =
    events
      ?.map((e) => {
        return {
          ...e,
          time: format(e.date ?? 0, 'kk:mm a'),
          status: isAfter(new Date(), e.date ?? 0) ? EventStatus.Complete : EventStatus.Scheduled,
        };
      })
      .filter((sw) => filterByStatus(sw)) || [];

  const columnHelper = createColumnHelper<EventTable>();

  const columns1 = [
    columnHelper.accessor('name', {
      header: () => 'Name',
      cell: (info) => <span data-cy="list-name"> {info.renderValue()}</span>,
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.accessor('date', {
      header: () => (window.innerWidth > 768 ? 'Date' : 'Event'),
      cell: (info) =>
        window.innerWidth > 768 ? (
          <span data-cy="list-date"> {format(info.row.original?.date ?? 0, 'eeee dd MMMM yyyy')} </span>
        ) : (
          <MobileEvent row={info.row.original} />
        ),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('time', {
      header: () => 'Time',
      cell: (info) => <span data-cy="list-time"> {info.renderValue()}</span>,
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.accessor('locationId', {
      header: () => 'Location',
      cell: (info) => {
        const foundLocation = locations?.find((p) => p.id === info.row.original?.locationId);
        return (
          <span data-cy="list-location" className="text-md leading-md text-gray-900 font-semibold">
            {foundLocation?.name}
          </span>
        );
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('bookings', {
      header: () => 'Bookings',
      cell: (info) => <span data-cy="list-bookings">{info.row.original.bookings?.length}</span>,
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.accessor('status', {
      header: () => 'Status',
      cell: (info) => <Status status={info.renderValue()} />,
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (props) => <RowActions row={props.row.original} />,
    }),
  ] as Array<ColumnDef<EventTable, unknown>>;

  return (
    <>
      <div className="flex justify-between place-items-center mb-8">
        <button
          type="button"
          data-cy="create-event-button"
          className="text-white bg-primary-500 rounded-lg px-5 py-2.5 font-semibold text-md leading-md hidden md:block"
          onClick={() => setOpenCreateEventModal(true)}
        >
          Create event
        </button>
        <button
          type="button"
          aria-label="Create event"
          className="text-white bg-primary-500 rounded-lg p-2 font-semibold text-md leading-md md:hidden"
          onClick={() => setOpenCreateEventModal(true)}
        >
          <PlusIcon className="w-5 h-5 text-white" />
        </button>
      </div>
      <ToggleButtons
        buttons={['All', 'Scheduled', 'Complete']}
        selectedButtons={[filterStatus]}
        onSelectButton={changeFilter}
        size={window.innerWidth > 768 ? SizeType.Fit : SizeType.Half}
      />
      <div className="mt-3 md:mt-8 mb-10">
        <Table<EventTable> hidePrint={true} data={rows} columns={columns1} columnVisibility={columnVisibility} />
      </div>
      {openCreateEventModal && <CreateEvent onClose={() => setOpenCreateEventModal(false)} partnerId={partnerId} />}
    </>
  );
}

export { PartnerEventList };
