import { gql, useMutation, useQuery } from '@apollo/client';
import { Location } from '__generated__/graphql';

export const CREATE_LOCATION = gql`
  mutation CreateLocation($input: CreateLocationInput) {
    CreateLocation(input: $input) {
      date
      message
    }
  }
`;

export const EDIT_LOCATION = gql`
  mutation EditLocation($input: EditLocationInput) {
    EditLocation(input: $input) {
      date
      message
    }
  }
`;

export const GET_LOCATION = gql`
  query GetLocation($args: LocationSearch) {
    GetLocation(args: $args) {
      id
      name
    }
  }
`;

export const GET_LOCATIONS = gql`
  query GetLocations {
    GetLocations {
      id
      name
    }
  }
`;

interface LocationsResponse {
  GetLocations: Location[];
}

interface LocationResponse {
  GetLocation: Location;
}

export const useGetLocations = () => {
  const { loading: locationsLoading, data, error: locationsError, refetch } = useQuery<LocationsResponse>(GET_LOCATIONS);

  return { locations: data?.GetLocations || [], locationsLoading, locationsError, refetch };
};

export const useGetLocation = (id?: string) => {
  const {
    loading: locationLoading,
    data,
    error: locationError,
    refetch,
  } = useQuery<LocationResponse>(GET_LOCATION, {
    fetchPolicy: 'network-only',
    skip: !id,
    variables: {
      args: {
        id,
      },
    },
  });
  return { location: data?.GetLocation || undefined, locationLoading, locationError, refetch };
};

export const useCreateLocation = () => {
  const [createLocation, { loading: createLocationLoading, error: createLocationError }] = useMutation(CREATE_LOCATION, {
    refetchQueries: [
      {
        query: GET_LOCATIONS,
      },
    ],
  });

  return {
    createLocation,
    createLocationLoading,
    createLocationError,
  };
};

export const useEditLocation = () => {
  const [editLocation, { loading: editLocationLoading, error: editLocationError }] = useMutation(EDIT_LOCATION, {
    refetchQueries: [
      {
        query: GET_LOCATIONS,
      },
    ],
  });

  return {
    editLocation,
    editLocationLoading,
    editLocationError,
  };
};
