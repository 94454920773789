/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Helmet } from 'react-helmet-async';
import { PencilIcon, PlusIcon } from '@heroicons/react/24/outline';
import { Suspense, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Reviews } from '@mui/icons-material';
import { CellContext, createColumnHelper, ColumnDef } from '@tanstack/react-table';
import { Loading, LocationBar, Table } from 'components';
import { useGetEventAttendees } from 'api/hooks/useEventAttendee';
import { EventAttendee } from '__generated__/graphql';
import { useGetLocations } from 'api/hooks/useLocation';

function TableCheckBox({ checked }: { checked: boolean }) {
  return (
    <div className="flex items-center">
      <input
        type="checkbox"
        checked={checked || false}
        disabled={true}
        className="w-4 h-4 text-primary-300 bg-gray-100 border-gray-300 rounded cursor-pointer"
      />
    </div>
  );
}

function RowActions(props: CellContext<EventAttendee, unknown>) {
  const navigate = useNavigate();

  return (
    <div className="flex items-center gap-3">
      <button type="button" onClick={() => navigate(`${props.row.original.id}/update`)} aria-label="Edit">
        <PencilIcon className="w-6 h-6 text-gray-500 hover:text-primary-600" />
      </button>
    </div>
  );
}

function EventAttendeeList() {
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<EventAttendee>();
  const { locations, locationsLoading } = useGetLocations();
  const { eventAttendees, eventAttendeesLoading } = useGetEventAttendees();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor('firstName', {
        header: () => 'First Name',
        cell: (info: { renderValue: () => any }) => info.renderValue(),
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.accessor('surname', {
        header: () => 'Surname',
        cell: (info: { renderValue: () => any }) => info.renderValue(),
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.accessor('phoneNumber', {
        header: () => 'Phone',
        cell: (info: { renderValue: () => any }) => info.renderValue(),
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.accessor('email', {
        header: () => 'Email',
        cell: (info: { renderValue: () => any }) => info.renderValue(),
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.accessor('locationIds', {
        header: () => 'Locations',
        cell: (info) => {
          const foundLocations = locations.filter((p) => info.row.original?.locationIds?.includes(p.id));
          return (
            <span data-cy="list-location" className="text-md leading-md text-gray-900 font-semibold">
              {foundLocations?.map((l, index) => {
                return (
                  <div>
                    {l.name}
                    {foundLocations.length > 1 && index !== foundLocations.length - 1 && ','}
                  </div>
                );
              })}
            </span>
          );
        },

        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.accessor('isTest', {
        header: () => 'Is Test',
        cell: (props) => <TableCheckBox checked={props.row.original.isTest || false} />,
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.accessor('hubspotId', {
        header: () => 'Hubspot Connected',
        cell: (props) => <TableCheckBox checked={!!props.row.original.hubspotId || false} />,
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.display({
        id: 'actions',
        cell: RowActions,
      }),
    ] as Array<ColumnDef<EventAttendee, unknown>>;
  }, [columnHelper, locations]);

  if (eventAttendeesLoading || locationsLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Event Attendee List</title>
      </Helmet>
      <LocationBar section="Event Attendee" page="Event Attendee List" Icon={Reviews} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-display-lg leading-display-lg tracking-display-lg text-black font-medium">Event Attendee List</div>
          <button
            type="button"
            className="text-white bg-primary-500 rounded-lg px-5 py-2.5 font-semibold text-md leading-md hidden md:block"
            onClick={() => navigate('create')}
          >
            Add Event Attendee
          </button>
          <button
            type="button"
            aria-label="Add Event Attendee"
            className="text-white bg-primary-500 rounded-lg p-2 font-semibold text-md leading-md md:hidden"
            onClick={() => navigate('create')}
          >
            <PlusIcon className="w-5 h-5 text-white" />
          </button>
        </div>
        <Suspense fallback={<Loading />}>
          <Table<EventAttendee> csv={{ data: eventAttendees, fileName: 'eventAttendeeList' }} data={eventAttendees} columns={columns} />
        </Suspense>
      </div>
    </>
  );
}

export default EventAttendeeList;
