import { ReactNode } from 'react';

interface FormLabelProps {
  label: string;
  children: ReactNode;
}

function FormLabel({ label, children }: FormLabelProps) {
  return (
    <>
      <div className="flex flex-col md:flex-row items-start gap-3 md:gap-7">
        <div className="text-wrap	 md:w-40 lg:w-80 text-gray-700 text-sm leading-sm font-medium">{label}</div>
        {children}
      </div>
      <div className="h-px w-full bg-gray-100 my-6" />
    </>
  );
}

export { FormLabel };
