import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Modal } from 'components/modals';
import { FormLabel } from 'components/FormLabel';
import { FileInput } from 'components/FileInput';
import { useSaveDocumentForPartner } from 'api/hooks/useDocuments';
import { getFilename } from 'services/file';

interface UploadDocumentProps {
  onClose: () => void;
  ownerId: string | undefined;
  ownerType: string;
  open: boolean;
}

function UploadDocument({ onClose, ownerId, ownerType, open }: UploadDocumentProps) {
  const [fileType, setFileType] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);
  const { saveDocument, mutationSaveDocument } = useSaveDocumentForPartner({ id: ownerId || '' });

  const uploadToS3 = async (f: File) => {
    if (!f) return;
    setFileType(f.type);
    setTitle(f.name);
    setFile(f);
  };

  const onSaveDocument = async () => {
    if (file && file?.name) {
      const id = uuidv4();
      const filename = getFilename(id, 'documents/partner/', file);
      const convertToBase64 = (): Promise<string | ArrayBuffer | null> => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            resolve(reader.result);
          };
        });
      };
      const convertedFile: string | ArrayBuffer | null = await convertToBase64();

      if (filename) {
        await saveDocument({
          variables: {
            input: {
              ownerId,
              ownerType,
              file: convertedFile,
              title,
              filename,
              type: fileType,
            },
          },
        });
      }
    }

    setFileType('');
    setTitle('');
    setFile(null);
    onClose();
  };

  return (
    <Modal
      title="Upload Document"
      onClose={onClose}
      open={open}
      isDisabled={mutationSaveDocument.loading}
      onConfirm={file ? onSaveDocument : undefined}
      loading={mutationSaveDocument.loading}
    >
      <div className="mt-2 mb-2">
        <FileInput accept="*" handleUpload={uploadToS3} placeholder="Upload Document" />
      </div>
      {file && (
        <div className="mt-8 ml-2">
          <FormLabel label="Title">
            <input
              className="bg-white px-4 py-2 rounded-lg text-md leading-md text-gray-700 placeholder:text-gray-500 border border-gray-300 shadow-xs w-full"
              placeholder="Title"
              type="text"
              onChange={(event) => setTitle(event.target.value)}
              value={title || ''}
              data-cy="document-title"
            />
          </FormLabel>
          <FormLabel label="Type">
            <input
              className="bg-white px-4 py-2 rounded-lg text-md leading-md text-gray-700 placeholder:text-gray-500 border border-gray-300 shadow-xs w-full"
              placeholder="File Type"
              type="text"
              disabled
              onChange={(event) => setFileType(event.target.value)}
              value={fileType || ''}
            />
          </FormLabel>
        </div>
      )}
    </Modal>
  );
}

export default UploadDocument;
