import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ReviewsIcon from '@mui/icons-material/Reviews';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { Loading, LocationBar } from 'components';
import { CreateLocationInput, EditLocationInput } from '__generated__/graphql';
import { useEditLocation, useGetLocation } from 'api/hooks/useLocation';
import { useToastNofication } from 'context/toastContext';
import { LocationForm } from './LocationForm';

function LocationUpdateForm() {
  const { locationId } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToastNofication();

  const { editLocation, editLocationLoading, editLocationError } = useEditLocation();
  const onSubmit = async (input: CreateLocationInput | EditLocationInput) => {
    const response = await editLocation({
      variables: {
        input,
      },
    });
    addToast(response, 'EditLocation');
    if (!editLocationLoading && !editLocationError) {
      navigate('/locations');
    }
  };

  const { location, locationLoading } = useGetLocation(locationId);

  if (locationLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Location Update</title>
      </Helmet>
      <LocationBar section="Location" page="Location Update Form" Icon={ReviewsIcon} />
      <div className="my-10 px-4 md:px-[5%]">
        <button
          data-cy="location-detail-back"
          type="button"
          className="text-gray-500 font-semibold text-md leading-md flex items-center"
          onClick={() => navigate('/locations')}
        >
          <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
        </button>
        <LocationForm location={location} onSubmit={onSubmit} formSubmitting={false} formSubmitError={undefined} />
      </div>
    </>
  );
}

export default LocationUpdateForm;
