/* eslint-disable @typescript-eslint/no-explicit-any */
import { Helmet } from 'react-helmet-async';
import { PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { CellContext, createColumnHelper, ColumnDef } from '@tanstack/react-table';
import { Suspense, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Group } from '@mui/icons-material';
import { DeleteModal, Loading, LocationBar, Table } from 'components';
import { useDeleteCustomer, useGetCustomers } from 'api/hooks/useCustomer';
import { Customer } from '__generated__/graphql';
import { useToastNofication } from 'context/toastContext';

interface RowActionProps extends CellContext<Customer, unknown> {
  onDeleteModalOpen: (id: string) => void;
}

function TableCheckBox({ checked }: { checked: boolean }) {
  return (
    <div className="flex items-center">
      <input
        type="checkbox"
        checked={checked || false}
        disabled={true}
        className="w-4 h-4 text-primary-300 bg-gray-100 border-gray-300 rounded cursor-pointer"
      />
    </div>
  );
}

function RowActions(props: RowActionProps) {
  const navigate = useNavigate();

  return (
    <div className="flex items-center gap-3">
      <button type="button" onClick={() => navigate(`${props.row.original.id}/update`)} aria-label="Edit">
        <PencilIcon className="w-6 h-6 text-gray-500 hover:text-primary-600" />
      </button>
      <button type="button" onClick={() => props.onDeleteModalOpen(props.row.original.id)} aria-label="Delete">
        <TrashIcon className="w-6 h-6 text-gray-500 hover:text-primary-600" />
      </button>
    </div>
  );
}

function CustomerList() {
  const columnHelper = createColumnHelper<Customer>();
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const { customers, customersLoading, refetch } = useGetCustomers();

  const { deleteCustomer, deleteCustomerLoading } = useDeleteCustomer();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { addToast } = useToastNofication();
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | undefined>();

  const onDeleteModalOpen = (id: string) => {
    setDeleteModalOpen(true);
    setSelectedCustomerId(id);
  };

  const onDelete = async (id: string) => {
    const response = await deleteCustomer({
      variables: {
        input: { id },
      },
    });
    addToast(response, 'DeleteCustomer');
    refetch();
    onDeleteClose();
  };
  const onConfirm = () => {
    onDelete(selectedCustomerId || '');
    onDeleteClose();
  };

  const onDeleteClose = () => {
    setDeleteModalOpen(false);
  };

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('id', {
        header: () => 'Id',
        cell: (info: { renderValue: () => any }) => info.renderValue(),
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.accessor('firstName', {
        header: () => 'First Name',
        cell: (info) => (
          <div data-cy="list-name" className="">
            <div className="text-md leading-md text-gray-900 font-semibold">
              {info.renderValue()} {info.row.original.surname}
            </div>
            <div className="text-sm leading-sm text-gray-700 hidden sm:block">{info.row.original.email}</div>
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('phoneNumber', {
        header: () => 'Phone Number',
        cell: (info: { renderValue: () => any }) => info.renderValue(),
        footer: (info: { column: { id: any } }) => info.column.id,
      }),

      columnHelper.accessor('isActive', {
        header: () => 'Is Active',
        cell: (props) => <TableCheckBox checked={props.row.original.isActive || false} />,
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.accessor('isTest', {
        header: () => 'Is Test',
        cell: (props) => <TableCheckBox checked={props.row.original.isTest || false} />,
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.accessor('hubspotId', {
        header: () => 'Hubspot Connected',
        cell: (props) => <TableCheckBox checked={!!props.row.original.hubspotId || false} />,
        footer: (info: { column: { id: any } }) => info.column.id,
      }),
      columnHelper.display({
        id: 'actions',
        cell: (props) => <RowActions {...props} onDeleteModalOpen={onDeleteModalOpen} />,
      }),
    ] as Array<ColumnDef<Customer, unknown>>;
  }, [columnHelper]);

  const filterByName = useCallback(
    (customer: Customer) => {
      const trimmedSearch = search.trim().toLowerCase();
      if (customer && customer.firstName?.toLowerCase().trim().includes(trimmedSearch)) return true;
      if (customer && customer.surname?.toLowerCase().trim().includes(trimmedSearch)) return true;
      if (customer && `${customer.firstName.trim()} ${customer.surname.trim()}`?.toLowerCase().includes(trimmedSearch)) return true;
      return false;
    },
    [search],
  );

  const filteredCustomers = useMemo(() => {
    return customers.filter((sw) => filterByName(sw));
  }, [customers, filterByName]);

  const csv = useMemo(() => {
    return filteredCustomers?.map((b) => {
      return {
        ...b,
        address: JSON.stringify(b.address).replace(/"/g, "'"),
      };
    });
  }, [filteredCustomers]);

  if (customersLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Customer List</title>
      </Helmet>
      <LocationBar section="Customer" page="Customer List" Icon={Group} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-display-lg leading-display-lg tracking-display-lg text-black font-medium">Customer List</div>
          <button
            type="button"
            className="text-white bg-primary-500 rounded-lg px-5 py-2.5 font-semibold text-md leading-md hidden md:block"
            onClick={() => navigate('create')}
          >
            Add Customer
          </button>
          <button
            type="button"
            aria-label="Add partner"
            className="text-white bg-primary-500 rounded-lg p-2 font-semibold text-md leading-md md:hidden"
            onClick={() => navigate('create')}
          >
            <PlusIcon className="w-5 h-5 text-white" />
          </button>
        </div>
        <Suspense fallback={<Loading />}>
          <Table<Customer> search={search} setSearch={setSearch} csv={{ data: csv, fileName: 'customerList' }} data={filteredCustomers} columns={columns} />
        </Suspense>
      </div>
      <DeleteModal onClose={onDeleteClose} open={deleteModalOpen} onConfirm={onConfirm} isDisabled={deleteCustomerLoading} />
    </>
  );
}

export default CustomerList;
