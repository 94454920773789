import { Controller, useFormContext } from 'react-hook-form';
import { Rating } from '@mui/material';
import { FormLabel } from '../FormLabel';

interface RatingFieldProps {
  name: string;
  isRequired: boolean;
  label: string;
}

function RatingField({ name, isRequired = false, label }: RatingFieldProps) {
  const { control } = useFormContext(); // retrieve all hook methods

  return (
    <FormLabel label={label}>
      <div className="w-full lg:w-1/2">
        <Controller
          name={name}
          control={control}
          rules={{
            required: isRequired ? 'This is required' : false,
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <>
                <Rating name={name} value={Number(value)} onChange={onChange} data-testid={name} />
                <p className="mt-2 text-sm text-error-600 dark:text-green-500">
                  <span className="font-medium">{error?.message}</span>
                </p>
              </>
            );
          }}
        />
      </div>
    </FormLabel>
  );
}

export { RatingField };
