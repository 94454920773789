import { gql, useMutation, useQuery } from '@apollo/client';
import { Availability, AvailabilitySlot } from '__generated__/graphql';

export const GET_AVAILABILITIES = gql`
  query GetAvailabilities($args: AvailabilityListSearch) {
    GetAvailabilities(args: $args) {
      frequency
      endDateTime
      id
      partnerId
      recurringId
      startDateTime
    }
  }
`;

export const GET_AVAILABILITY_SLOTS = gql`
  query GetAvailabilitySlots($args: AvailablitySlotListSearch) {
    GetAvailabilitySlots(args: $args) {
      startDateTime
      endDateTime
      isBooked
    }
  }
`;

export const CREATE_AVAILABILITY = gql`
  mutation CreateAvailability($input: CreateAvailabilityInput) {
    CreateAvailability(input: $input) {
      date
      message
    }
  }
`;

export const DELETE_AVAILABILITY = gql`
  mutation DeleteAvailability($input: AvailabilityDelete) {
    DeleteAvailability(input: $input) {
      message
      date
    }
  }
`;

export const EDIT_AVAILABILITY = gql`
  mutation EditAvailability($input: EditAvailabilityInput) {
    EditAvailability(input: $input) {
      date
      message
    }
  }
`;

export const DELETE_AVAILABILITY_RECCURANCE = gql`
  mutation DeleteAvailabilityRecurrence($input: AvailabilityRecurrenceDelete) {
    DeleteAvailabilityRecurrence(input: $input) {
      message
      date
    }
  }
`;

export const EDIT_AVAILABILITY_RECURRENCE = gql`
  mutation EditAvailabilityRecurrence($input: EditAvailabilityRecurrenceInput) {
    EditAvailabilityRecurrence(input: $input) {
      date
      message
    }
  }
`;

interface AvailabiliesResponse {
  GetAvailabilities: Availability[];
}

interface AvailabilitySlotsResponse {
  GetAvailabilitySlots: AvailabilitySlot[];
}

const useGetAvailabilities = (partnerId?: string) => {
  const {
    loading: availabilitiesLoading,
    data,
    error: availabilitiesError,
    refetch,
  } = useQuery<AvailabiliesResponse>(GET_AVAILABILITIES, {
    variables: {
      args: {
        partnerId,
      },
    },
  });

  return {
    availabilities: data?.GetAvailabilities || [],
    refetch,
    availabilitiesLoading,
    availabilitiesError,
  };
};

const useGetAvailabilitySlots = (productId?: string, partnerId?: string, date?: number) => {
  const {
    loading: availabilitySlotsLoading,
    data,
    error: availabilitySlotsError,
    refetch,
  } = useQuery<AvailabilitySlotsResponse>(GET_AVAILABILITY_SLOTS, {
    fetchPolicy: 'network-only',
    skip: !productId || !partnerId || !date,

    variables: {
      args: {
        productId,
        partnerId,
        date,
      },
    },
  });

  return {
    availabilitySlots: data?.GetAvailabilitySlots || [],
    refetch,
    availabilitySlotsLoading,
    availabilitySlotsError,
  };
};

const useCreateAvailability = (partnerId: string) => {
  const [createAvailability, mutationCreateAvailability] = useMutation(CREATE_AVAILABILITY, {
    refetchQueries: [
      {
        query: GET_AVAILABILITIES,
        variables: {
          args: { partnerId },
        },
      },
    ],
  });

  return {
    createAvailability,
    mutationCreateAvailability,
  };
};

const useEditAvailability = (partnerId: string) => {
  const [editAvailability, mutationEditAvailability] = useMutation(EDIT_AVAILABILITY, {
    refetchQueries: [
      {
        query: GET_AVAILABILITIES,
        variables: {
          args: { partnerId },
        },
      },
    ],
  });

  return {
    editAvailability,
    mutationEditAvailability,
  };
};

const useDeleteAvailability = (partnerId: string) => {
  const [deleteAvailability, mutationDeleteAvailability] = useMutation(DELETE_AVAILABILITY, {
    refetchQueries: [
      {
        query: GET_AVAILABILITIES,
        variables: {
          args: { partnerId },
        },
      },
    ],
  });

  return {
    deleteAvailability,
    mutationDeleteAvailability,
  };
};

const useEditAvailabilityRecurrence = (partnerId: string) => {
  const [editAvailabilityRecurrence, mutationEditAvailabilityRecurrance] = useMutation(EDIT_AVAILABILITY_RECURRENCE, {
    refetchQueries: [
      {
        query: GET_AVAILABILITIES,
        variables: {
          args: { partnerId },
        },
      },
    ],
  });

  return {
    editAvailabilityRecurrence,
    mutationEditAvailabilityRecurrance,
  };
};

const useDeleteAvailabilityRecurrence = (partnerId: string) => {
  const [deleteAvailabilityRecurrence, mutationDeleteAvailabilityRecurrence] = useMutation(DELETE_AVAILABILITY_RECCURANCE, {
    refetchQueries: [
      {
        query: GET_AVAILABILITIES,
        variables: {
          args: { partnerId },
        },
      },
    ],
  });

  return {
    deleteAvailabilityRecurrence,
    mutationDeleteAvailabilityRecurrence,
  };
};

export {
  useGetAvailabilities,
  useGetAvailabilitySlots,
  useCreateAvailability,
  useEditAvailability,
  useDeleteAvailability,
  useEditAvailabilityRecurrence,
  useDeleteAvailabilityRecurrence,
};
