import { useCreateAvailability } from 'api/hooks/useAvailability';
import { useToastNofication } from 'context/toastContext';
import { AvailabilityForm } from '../../AvailabilityForm';
import { EventType } from '../../types';

interface AddModalProps {
  open: boolean;
  onClose: () => void;
  partnerId: string;
  event?: EventType;
}

export function AddModal({ open, onClose, partnerId, event }: AddModalProps) {
  const { createAvailability, mutationCreateAvailability } = useCreateAvailability(partnerId);
  const { addToast } = useToastNofication();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (input: any) => {
    const response = await createAvailability({
      variables: {
        input: {
          endDateTime: input?.endDateTime,
          frequency: input?.frequency,
          partnerId: input?.partnerId,
          startDateTime: input?.startDateTime,
        },
      },
    });

    addToast(response, 'CreateAvailability');
    onClose();
  };
  return (
    <AvailabilityForm
      partnerId={partnerId}
      open={open}
      onClose={onClose}
      formSubmitError={mutationCreateAvailability.error}
      onSubmit={onSubmit}
      availability={event}
      formSubmitting={mutationCreateAvailability.loading}
      title="Add availability"
    />
  );
}
