import { Dispatch, SetStateAction } from 'react';
import { useGetCustomers } from 'api/hooks/useCustomer';
import { ReactSelect } from 'components';
import { Loading } from 'components/Loading';
import { useGetEventAttendees } from 'api/hooks/useEventAttendee';
import { SelectOption } from '../../../../types';

interface AttendeeSelectOption {
  id: string;
  fullName: string;
}

interface AttendeeSelectProps {
  onClick: (id: string) => void;
  selectedSupportWorker?: string;
  selectedSupportWorkers?: string[];
  isSelectMultiple?: boolean;
  options: AttendeeSelectOption[];
  loading: boolean;
}

interface AttendeeFieldProps {
  setAttendeeType: Dispatch<SetStateAction<SelectOption | undefined>>;
  attendeeType: SelectOption | undefined;
  attendeeIds: string[];
  onClickAttendee: (id: string) => void;
}

export default function AttendeeSelect({
  selectedSupportWorker,
  selectedSupportWorkers,
  options,
  isSelectMultiple = false,
  onClick,
  loading,
}: AttendeeSelectProps) {
  return (
    <div className="pt-4 pb-4">
      {loading && <Loading />}
      {!loading && (
        <div className="grid xl:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-2">
          {options.map((option: AttendeeSelectOption) => {
            const isSelected = (isSelectMultiple && selectedSupportWorkers?.includes(option.id)) || (!isSelectMultiple && selectedSupportWorker === option.id);
            return (
              <div
                data-cy={`attendee-${option.id}`}
                className={`${isSelected ? ' text-white bg-primary-500' : 'text-gray-800'} cursor-pointer p-2.5 rounded-lg`}
                onClick={() => onClick(option.id)}
                key={option.id}
              >
                <div className="text-md leading-md font-medium">{option.fullName}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

function AttendeeField({ setAttendeeType, attendeeType, onClickAttendee, attendeeIds }: AttendeeFieldProps) {
  const options = [
    { label: 'Customer', value: 'CUSTOMER' },
    { label: 'Booking Attendee', value: 'ATTENDEE' },
  ];

  const { customers, customersLoading } = useGetCustomers();
  const { eventAttendees, eventAttendeesLoading } = useGetEventAttendees();

  const customerOptions = customers.map((customer) => {
    return { fullName: `${customer.firstName} ${customer.surname || ''}`, id: customer.id };
  });
  const attendeeOptions = eventAttendees.map((customer) => {
    return { fullName: `${customer.firstName} ${customer.surname || ''}`, id: customer.id };
  });
  return (
    <>
      <ReactSelect options={options} onChange={(e) => setAttendeeType(e)} selectedValue={attendeeType} />
      <AttendeeSelect
        loading={customersLoading || eventAttendeesLoading}
        options={attendeeType?.value === 'CUSTOMER' ? customerOptions : attendeeOptions}
        onClick={onClickAttendee}
        selectedSupportWorkers={attendeeIds as string[]}
        isSelectMultiple={true}
      />
    </>
  );
}

export { AttendeeField };
