import { Event } from 'react-big-calendar';
import { Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import EmojiPeople from '@mui/icons-material/EmojiPeople';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { useEditPartnerProfile } from 'api/hooks/usePartner';
import { EditPartnerInput } from '__generated__/graphql';
import { useUser } from 'context/userContext';
import { LocationBar, ImageField, TabSwitcher } from 'components';
import { useToastNofication } from 'context/toastContext';
import DocumentManagement from 'components/Documents';
import { useRemoveDocumentForPartner } from 'api/hooks/useDocuments';
import { PartnerEventList } from '../Partner/PartnerEventList';
import PartnerLocationMap from '../Partner/PartnerLocationMap';
import AvailabilityCalendar from '../AvailabilityCalendar';
import { PartnerProfileForm } from './PartnerProfileForm';

export interface EventType extends Event {
  end: Date;
  id?: string;
  start: Date;
  title: string;
  isRecurring?: boolean;
  recurringId?: string;
  frequency?: string;
}

function PartnerUpdateForm() {
  const { partner } = useUser();
  const { addToast } = useToastNofication();
  const { removeDocument } = useRemoveDocumentForPartner({ id: partner?.id || '' });

  const { editPartnerProfile, mutationEditPartnerProfile } = useEditPartnerProfile();
  const onSubmit = async (input: EditPartnerInput) => {
    const response = await editPartnerProfile({
      variables: {
        input,
      },
    });
    addToast(response, 'EditPartner');
  };
  const onDeleteDocument = async (documentId: string, ownerId: string) => {
    const response = await removeDocument({
      variables: {
        input: {
          id: documentId,
          ownerId,
        },
      },
    });
    addToast(response, 'RemoveDocument');
  };

  const onImageUpload = async (image: { url: string; id: string }) => {
    const response = await editPartnerProfile({
      variables: {
        input: {
          id: partner?.id,
          image: { url: image?.url, id: image?.id },
        },
      },
    });

    addToast(response, 'EditPartner');
  };

  if (!partner) {
    return (
      <Typography variant="h5" textAlign={{ xs: 'center', md: 'left' }}>
        No Partner
      </Typography>
    );
  }

  const tabs = [
    {
      label: 'Details',
      component: (
        <PartnerProfileForm
          partner={partner}
          onSubmit={onSubmit}
          formSubmitting={mutationEditPartnerProfile.loading}
          formSubmitError={mutationEditPartnerProfile.error}
        />
      ),
    },
    {
      label: 'Availability',
      component: (
        <div className="mt-8 sm:mt-12">
          <AvailabilityCalendar partnerId={partner?.id || ''} />
        </div>
      ),
    },
    {
      label: 'Location',
      component: (
        <PartnerLocationMap
          partner={partner}
          onSubmit={onSubmit}
          formSubmitting={mutationEditPartnerProfile.loading}
          formSubmitError={mutationEditPartnerProfile.error}
        />
      ),
    },
    {
      label: 'Events',
      component: <PartnerEventList partnerId={partner?.id || ''} />,
    },
    {
      label: 'Documents',
      component: <DocumentManagement onDeleteDocument={onDeleteDocument} ownerId={partner?.id} ownerType="Partner" documents={partner?.documents} />,
    },
  ];

  return (
    <>
      <Helmet>
        <title>My Profile</title>
      </Helmet>
      <LocationBar section="Profile" page=" Update your profile below" Icon={EmojiPeople} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex items-start justify-between mt-16">
          <div>
            <div className="flex items-center  py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
              <div className="ps-3">
                <ImageField onUpload={onImageUpload} image={partner?.image} />
                <div className="text-black text-xl sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1">{`${partner?.firstName} ${partner?.surname}`}</div>
                <div className="text-gray-600 text-md leading-md mb-2 flex items-center">
                  <EnvelopeIcon className="w-5 h-5 mr-2 text-gray-600" />
                  <span className="pt-0.5">{partner?.email}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <TabSwitcher tabs={tabs} defaultTab={2} />
      </div>
    </>
  );
}

export default PartnerUpdateForm;
