import { gql, useMutation, useQuery } from '@apollo/client';
import { Customer } from '__generated__/graphql';

export const DEACTIVATE_CUSTOMER = gql`
  mutation DisableCustomer($input: ManageCustomerInput) {
    DisableCustomer(input: $input) {
      date
      message
    }
  }
`;

export const ENABLE_CUSTOMER = gql`
  mutation EnableCustomer($input: ManageCustomerInput) {
    EnableCustomer(input: $input) {
      date
      message
    }
  }
`;

export const EDIT_CUSTOMER = gql`
  mutation EditCustomer($input: EditCustomerInput) {
    EditCustomer(input: $input) {
      date
      message
    }
  }
`;

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer($input: RegisteredCustomerInput) {
    CreateCustomer(input: $input) {
      date
      message
    }
  }
`;
export const DELETE_CUSTOMER = gql`
  mutation DeleteCustomer($input: CustomerSearch) {
    DeleteCustomer(input: $input) {
      message
      date
    }
  }
`;

export const GET_CUSTOMER = gql`
  query GetCustomer($args: CustomerSearch) {
    GetCustomer(args: $args) {
      id
      firstName
      surname
      email
      isTest
      isActive
      phoneNumber
      isActive
      address {
        addressLine1
        addressLine2
        city
        region
        postcode
        addressNotes
      }
      otherInformations
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query GetCustomers($args: CustomerListSearch) {
    GetCustomers(args: $args) {
      id
      firstName
      surname
      email
      isActive
      isTest
      phoneNumber
      address {
        addressLine1
        addressLine2
        city
        region
        postcode
        addressNotes
      }
      hubspotId
    }
  }
`;

interface CustomersResponse {
  GetCustomers: Customer[];
}

interface CustomerResponse {
  GetCustomer: Customer;
}

const useDisableCustomer = (id?: string) => {
  const [disableCustomer, { loading: disableCustomerLoading, error: disableCustomerError }] = useMutation(DEACTIVATE_CUSTOMER, {
    refetchQueries: [
      {
        query: GET_CUSTOMER,
        variables: {
          args: {
            id,
          },
        },
      },
    ],
  });

  return {
    disableCustomer,
    disableCustomerLoading,
    disableCustomerError,
  };
};

const useEditCustomer = () => {
  const [editCustomer, { loading: editCustomerLoading, error: editCustomerError }] = useMutation(EDIT_CUSTOMER, {
    refetchQueries: [
      {
        query: GET_CUSTOMERS,
      },
    ],
  });

  return {
    editCustomer,
    editCustomerLoading,
    editCustomerError,
  };
};

const useDeleteCustomer = () => {
  const [deleteCustomer, { loading: deleteCustomerLoading, error: deleteCustomerError }] = useMutation(DELETE_CUSTOMER, {
    refetchQueries: [
      {
        query: GET_CUSTOMERS,
      },
    ],
  });

  return {
    deleteCustomer,
    deleteCustomerLoading,
    deleteCustomerError,
  };
};

export const useCreateCustomer = () => {
  const [createCustomer, { loading: createCustomerLoading, error: createCustomerError }] = useMutation(CREATE_CUSTOMER, {
    refetchQueries: [
      {
        query: GET_CUSTOMERS,
      },
    ],
  });

  return {
    createCustomer,
    createCustomerLoading,
    createCustomerError,
  };
};

const useGetCustomer = (id?: string) => {
  const {
    loading: customerLoading,
    data,
    error: customerError,
    refetch,
  } = useQuery<CustomerResponse>(GET_CUSTOMER, {
    fetchPolicy: 'network-only',
    variables: {
      args: {
        id,
      },
    },
  });

  return {
    customer: data?.GetCustomer || undefined,
    refetch,
    customerLoading,
    customerError,
  };
};

const useGetCustomers = (isActive: boolean | undefined = undefined) => {
  const {
    loading: customersLoading,
    data,
    error: customersError,
    refetch,
  } = useQuery<CustomersResponse>(GET_CUSTOMERS, {
    variables: {
      args: {
        isActive,
      },
    },
  });

  return {
    customers: data?.GetCustomers || [],
    refetch,
    customersLoading,
    customersError,
  };
};

const useEnableCustomer = (id?: string) => {
  const [enableCustomer, { loading: enableCustomerLoading, error: enableCustomerError }] = useMutation(ENABLE_CUSTOMER, {
    refetchQueries: [
      {
        query: GET_CUSTOMER,
        variables: {
          args: {
            id,
          },
        },
      },
    ],
  });

  return {
    enableCustomer,
    enableCustomerLoading,
    enableCustomerError,
  };
};

export { useDisableCustomer, useDeleteCustomer, useEnableCustomer, useEditCustomer, useGetCustomer, useGetCustomers };
