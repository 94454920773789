import { FormProvider, useForm } from 'react-hook-form';
import { LocationMultiSelectField } from 'components/fields/LocationMultiSelect';
import { InputField, isValidEmail, isValidPhone } from 'components';
import { useCreateEventAttendee } from 'api/hooks/useEventAttendee';
import { useToastNofication } from 'context/toastContext';

interface AddNewAttendeeFormProps {
  addAttendee: (id: string) => void;
  onClose: () => void;
}

export type EventAttendeeFormInput = {
  firstName: string;
  surname?: string;
  locationIds?: { id: string }[] | undefined;
  phoneNumber?: string;
  email?: string;
};

function AddNewAttendeeForm({ addAttendee, onClose }: AddNewAttendeeFormProps) {
  const methods = useForm<EventAttendeeFormInput>({ mode: 'onChange' });
  const { handleSubmit } = methods;
  const { createEventAttendee } = useCreateEventAttendee();
  const { addToast } = useToastNofication();

  const eventAttendeeFormSubmit = async (data: EventAttendeeFormInput) => {
    const input = {
      ...data,
      locationIds: data ? data?.locationIds?.map((att) => att?.id) : [],
    };

    const response = await createEventAttendee({
      variables: { input },
    });
    addToast(response, 'CreateEventAttendee');
    addAttendee(response?.data?.CreateEventAttendee?.id);
    onClose();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(eventAttendeeFormSubmit)}>
        <div className="mt-8 sm:mt-12">
          <InputField name="firstName" isRequired label="First Name" placeholder="First Name" />
          <InputField name="surname" label="Surname" placeholder="Surname" />
          <InputField name="phoneNumber" validate={isValidPhone} label="Phone Number" placeholder="Phone Number" />
          <InputField name="email" validate={isValidEmail} label="Email" placeholder="Email" />
          <LocationMultiSelectField name="locationIds" label="Locations" />
        </div>
        <div className="flex items-center justify-evenly p-4 md:p-5">
          <button
            type="submit"
            data-cy="modal-save-button"
            // eslint-disable-next-line max-len
            className="text-white w-30 bg-primary-500 disabled:shadow-none disabled:border-primary-200 disabled:bg-primary-200 disabled:text-white   rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
          >
            Save
          </button>

          <button
            type="button"
            data-testid="modal-close-button"
            onClick={onClose}
            className=" bg-error-600 text-white w-30 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
          >
            Cancel
          </button>
        </div>
      </form>
    </FormProvider>
  );
}

export { AddNewAttendeeForm };
