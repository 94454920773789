import { useGetPartners } from 'api/hooks/usePartner';
import { Partner } from '__generated__/graphql';
import { SelectField } from './SelectField';
import { Loading } from '../Loading';

interface PartnerSelectFieldProps {
  name: string;
  isRequired: boolean;
  label: string;
}

function PartnerSelectField({ name, isRequired, label }: PartnerSelectFieldProps) {
  const { partners, partnersLoading } = useGetPartners();
  if (partnersLoading) {
    return <Loading />;
  }
  const partnerOptions = partners?.map((partner: Partner) => {
    return { label: partner?.businessName, value: partner?.id };
  });
  return <SelectField name={name} loading={partnersLoading} isRequired={isRequired} label={label} options={partnerOptions} />;
}

export { PartnerSelectField };
