import { postcodeValidator } from 'postcode-validator';

const isValueRequired = (value: string) => {
  if (value?.toString()?.trim().length > 0) {
    return true;
  } else {
    return 'This is required';
  }
};
const isPostcodeValid = (value: string) => {
  if (postcodeValidator(value, 'GB')) {
    return true;
  } else {
    return 'Please enter valid UK postcode';
  }
};

function isValidEmail(value: string) {
  const re = /\S+@\S+\.\S+/;
  if (!value) {
    return true;
  } else if (re.test(value)) {
    return true;
  } else {
    return 'Please enter valid email';
  }
}

const isValidPhone = (value: string): string | boolean => {
  const regex = /^(\(?(0|\+44)[1-9]{1}\d{1,4}?\)?\s?\d{3,4}\s?\d{3,4})$/;
  if (!value) {
    return true;
  } else if (!regex.test(value)) {
    return 'Phone number is invalid';
  } else {
    return true;
  }
};
export { isValidEmail, isValueRequired, isValidPhone, isPostcodeValid };
