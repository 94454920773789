import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { EditProductInput, CreateProductInput } from '__generated__/graphql';
import { Loading, LocationBar } from 'components';
import { useGetProduct, useEditProduct } from 'api/hooks/useProduct';
import { useToastNofication } from 'context/toastContext';
import { ProductForm } from './ProductForm';

function ProductUpdateForm() {
  const { id, productId } = useParams();
  const navigate = useNavigate();

  const { editProduct, editProductLoading, editProductError } = useEditProduct(id || '');
  const { addToast } = useToastNofication();

  const onSubmit = async (input: CreateProductInput | EditProductInput) => {
    const response = await editProduct({
      variables: {
        input,
      },
    });
    addToast(response, 'EditProduct');

    if (!editProductLoading && !editProductError) {
      navigate(id ? `/categories/${id}/products` : '/products');
    }
  };

  const { product, productLoading } = useGetProduct(productId);

  if (productLoading) {
    return <Loading />;
  }
  return (
    <>
      <Helmet>
        <title>Product Update</title>
      </Helmet>
      <LocationBar section="Product" page="Product Update Form" Icon={ShoppingCartIcon} />
      <div className="my-10 px-4 md:px-[5%]">
        <button
          data-cy="form-detail-back"
          type="button"
          className="text-gray-500 font-semibold text-md leading-md flex items-center"
          onClick={() => navigate(id ? `/categories/${id}/products` : '/products')}
        >
          <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
        </button>
        <ProductForm product={product} onSubmit={onSubmit} formSubmitting={editProductLoading} formSubmitError={editProductError} />
      </div>
    </>
  );
}

export default ProductUpdateForm;
