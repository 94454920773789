/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, useRef, SetStateAction } from 'react';
import { MapContainer, TileLayer, FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import '../map.css';
import L, { Layer } from 'leaflet';
import { getCenterOfBounds } from 'geolib';
import { InputMaybe, PartnerWorkingAreaInput } from '../__generated__/graphql';

interface PolygonMapProps {
  setArea: Dispatch<
    SetStateAction<
      | {
          lat: number;
          lng: number;
        }[]
      | null
    >
  >;
  workingArea: InputMaybe<PartnerWorkingAreaInput[]> | undefined;
}

function PolygonMap({ setArea, workingArea }: PolygonMapProps) {
  const featureGroupRef = useRef<any>();

  const drawnItems: Layer[] | undefined = featureGroupRef?.current?.getLayers();
  const center: { latitude: number; longitude: number } | undefined = workingArea ? getCenterOfBounds(workingArea) : undefined;

  const onFeatureGroupReady = () => {
    if (featureGroupRef && featureGroupRef.current) {
      const polygon = new L.Polygon(workingArea || []);

      if (workingArea) {
        if (drawnItems) featureGroupRef.current.removeLayer(drawnItems[0]);

        featureGroupRef.current.addLayer(polygon);
      }
    }
  };
  const onCreated = () => {
    const editLayers: any[] = featureGroupRef?.current?.getLayers() || [];
    let area = [];
    if (editLayers && editLayers.length === 3) {
      const extraLayer1 = editLayers[0];
      const extraLayer2 = editLayers[1];

      area = editLayers[2]?._latlngs[0];
      featureGroupRef?.current?.removeLayer(extraLayer1);
      featureGroupRef?.current?.removeLayer(extraLayer2);
    } else if (editLayers && editLayers.length === 2) {
      area = editLayers[1]?._latlngs[0];
      const extraLayer = editLayers[0];

      featureGroupRef?.current?.removeLayer(extraLayer);
    } else if (editLayers && editLayers.length === 1) {
      const extraLayer = editLayers[0];

      if (area.length > 0) {
        featureGroupRef?.current?.removeLayer(extraLayer);
      }
      area = editLayers[0]?._latlngs[0];
    }

    setArea([...area]);
  };

  const onEdited = () => {
    const editLayers: any[] = featureGroupRef?.current?.getLayers() || [];

    if (editLayers && editLayers.length > 1) {
      const extraLayer = editLayers[0];

      featureGroupRef?.current?.removeLayer(extraLayer);
    }
    const area = editLayers[0]._latlngs[0];

    setArea([...area]);
  };

  const onDeleted = (e: any) => {
    const { layerType } = e;
    if (layerType === 'polygon') {
      setArea([]);
    }
  };

  return (
    <MapContainer
      center={center ? [center.latitude, center.longitude] : [54.42, -2.169]}
      zoom={8}
      scrollWheelZoom={false}
      style={{ height: 'calc(80vh - 5rem)' }}
    >
      <TileLayer attribution="Google Maps" url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}" />
      <FeatureGroup ref={featureGroupRef}>
        <EditControl
          position="topright"
          onCreated={onCreated}
          onEdited={onEdited}
          onDeleted={onDeleted}
          onMounted={onFeatureGroupReady}
          draw={{
            polyline: false,
            polygon: true,
            rectangle: false,
            circle: false,
            circlemarker: false,
            marker: false,
          }}
        />
      </FeatureGroup>
    </MapContainer>
  );
}

export { PolygonMap };
