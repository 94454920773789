/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { ToastsContainer } from 'components/Toast';

type ToastNoficationProviderProps = {
  children: React.ReactNode;
};

export interface ToastNoficationContextProps {
  addToast: (response: any, key: string) => void;
  removeToast: (id: string) => void;
}

const defaultProps = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addToast: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  removeToast: () => {},
};

const ToastNoficationContext = createContext<ToastNoficationContextProps>(defaultProps);

function ToastNoficationProvider({ children }: ToastNoficationProviderProps) {
  const [toasts, setToasts] = useState<{ id: string; message: string; type: string }[]>([]);

  const addToast = useCallback(
    (response: any, key: string) => {
      let message;
      let type;
      if (response?.data && response?.data[key]?.message) {
        message = response?.data[key]?.message;
        type = 'success';
      }
      if (response?.errors) {
        message = response?.errors[0].message || 'Sorry something went wrong';
        type = 'error';
      }
      const id = Math.floor(Math.random() * 10000000).toString();
      if (type && message) {
        setToasts([...toasts, { id, message, type }]);
      }
    },
    [toasts],
  );

  const removeToast = useCallback(
    (id: string) => {
      const filteredToasts = toasts.filter((t) => t.id !== id);
      setToasts(filteredToasts);
    },
    [toasts],
  );

  const value = useMemo(() => {
    return { addToast, removeToast };
  }, [addToast, removeToast]);
  return (
    <ToastNoficationContext.Provider value={value}>
      <ToastsContainer toasts={toasts} removeToast={removeToast} />
      {children}
    </ToastNoficationContext.Provider>
  );
}

const useToastNofication = (): ToastNoficationContextProps => {
  const context = useContext(ToastNoficationContext);

  return context;
};

export { ToastNoficationProvider, useToastNofication };
