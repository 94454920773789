import { useMutation, gql } from '@apollo/client';
import { PartnerSearch } from '__generated__/graphql';
import { refetchPartner } from './usePartner';

export const SAVE_DOCUMENT = gql`
  mutation SaveDocument($input: SaveDocumentInput) {
    SaveDocument(input: $input) {
      id
      message
      date
    }
  }
`;

export const REMOVE_DOCUMENT = gql`
  mutation RemoveDocument($input: RemoveDocumentInput) {
    RemoveDocument(input: $input) {
      id
      message
      date
    }
  }
`;

export const useSaveDocumentForPartner = (query: PartnerSearch) => {
  const [saveDocument, mutationSaveDocument] = useMutation(SAVE_DOCUMENT, refetchPartner(query));
  return { saveDocument, mutationSaveDocument };
};

export const useRemoveDocumentForPartner = (query: PartnerSearch) => {
  const [removeDocument, mutationRemoveDocument] = useMutation(REMOVE_DOCUMENT, refetchPartner(query));
  return { removeDocument, mutationRemoveDocument };
};
