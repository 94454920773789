import { Controller, useFormContext } from 'react-hook-form';
import { FormLabel } from '../FormLabel';
import { isValueRequired } from './validators';

interface TextAreaFieldProps {
  label: string;
  placeholder: string;
  name: string;
  disabled?: boolean;
  isRequired?: boolean;
  validate?: (value: string) => boolean | string;
}
function TextAreaField({ label, placeholder, isRequired = false, name, disabled = false, validate }: TextAreaFieldProps) {
  const { control } = useFormContext(); // retrieve all hook methods
  let validation;
  if (validate && isRequired) {
    validation = { validate, isValueRequired };
  } else if (isRequired && !validate) {
    validation = { isValueRequired };
  } else if (validate && !isRequired) {
    validation = { validate };
  }
  return (
    <FormLabel label={label}>
      <div className="w-full lg:w-1/2">
        <Controller
          name={name}
          control={control}
          rules={{ validate: validation }}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <>
                <textarea
                  rows={12}
                  disabled={disabled}
                  value={value}
                  onChange={(event) => (onChange ? onChange(event.target.value) : null)}
                  // eslint-disable-next-line max-len
                  className="bg-white px-5 py-2  disabled:bg-slate-50 disabled:text-slate-500 rounded-lg text-md leading-md text-gray-700 placeholder:text-gray-500 border border-gray-300 shadow-xs w-full"
                  placeholder={placeholder ?? ''}
                  data-cy={name}
                />

                <p className="mt-2 text-sm text-error-600 dark:text-green-500">
                  <span className="font-medium">{error?.message}</span>
                </p>
              </>
            );
          }}
        />
      </div>
    </FormLabel>
  );
}

export { TextAreaField };
